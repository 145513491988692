import React, { FC } from "react";

import IconProps from "./IconProps";

const ClockWithCogIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_14975_51540)">
      <path
        d="M21 12.5C21 10.7201 20.4722 8.98008 19.4834 7.50008C18.4945 6.02008 17.089 4.86653 15.4446 4.18528C13.8002 3.50403 11.9907 3.32569 10.2449 3.67279C8.49915 4.0199 6.8955 4.87686 5.63675 6.13534C4.378 7.39381 3.52067 8.99727 3.17318 10.743C2.82569 12.4887 3.00363 14.2982 3.68451 15.9428C4.3654 17.5873 5.51864 18.9931 6.99842 19.9823C8.4782 20.9714 10.2181 21.4996 11.998 21.5"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.002 19.5C17.002 20.0304 17.2127 20.5391 17.5877 20.9142C17.9628 21.2893 18.4715 21.5 19.002 21.5C19.5324 21.5 20.0411 21.2893 20.4162 20.9142C20.7912 20.5391 21.002 20.0304 21.002 19.5C21.002 18.9696 20.7912 18.4609 20.4162 18.0858C20.0411 17.7107 19.5324 17.5 19.002 17.5C18.4715 17.5 17.9628 17.7107 17.5877 18.0858C17.2127 18.4609 17.002 18.9696 17.002 19.5Z"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.002 16V17.5"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.002 21.5V23"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.0314 17.75L20.7324 18.5"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.2707 20.5L15.9707 21.25"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.9707 17.75L17.2707 18.5"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.7324 20.5L22.0324 21.25"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 7.5V12.5L14 14.5"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14975_51540">
        <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default ClockWithCogIcon;

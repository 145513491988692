import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { generatePath, useNavigate } from "react-router";
import { CloseRoundIcon } from "assets/svg";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";
import { useInterval } from "usehooks-ts";

import { AppEvents } from "services/events";
import { SalsaWorkerCategoryEnum, UserStatus } from "types/BETypes";
import { PaymentReadinessStatus } from "types/user";
import { getName, getPhoneNumberOrNull, showErrorModal } from "helpers";
import { SHOW_EMAIL_URL_PARAM } from "pages/People/admin/AdminEditWorker/urlParams";

import { Emphasized } from "uikit";
import { EButtonsFlex, EModalTypes } from "uikit/Modal";

import {
  mutationEmployeesControllerCreateEmployee,
  mutationEmployeesControllerDeleteEmployee,
  mutationEmployeesControllerInviteEmployees,
  mutationEmployeesControllerUpdateEmployee,
  UserLiteSearchResponseDto,
  UserResponseDto,
} from "utils/swagger_react_query";

import AddWorkerForm from "../components/forms/AddWorkerForm";
import { FormType as AddWorkerFormType } from "../components/forms/AddWorkerForm/validationSchema";
import { ImportEmployeesModal } from "../components/ImportEmployeesModal";
import { IProps, IResendDisableRecord } from "./types";
import useContextMenu, { IMenuAction } from "./useContextMenu";
import { useImportEmployees } from "./useImportEmployees";
import { AddWorkerCloseButton, AddWorkerPane, WorkerAddedModalInner } from "./styles";

const WorkerActions = (props: IProps) => {
  const translationPrefix = `people_page.index`;
  const navigate = useNavigate();
  const currentUser = useAppSelector(userMetadataSelector);

  const { onInviteWorker, onDeleteWorker, onAddWorker, onEditWorker } = props;

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isAddWorkerFormOpen, setAddWorkerFormOpen] = useState<boolean>(false);

  const [editedUser, setEditedUser] = useState<Partial<UserResponseDto> | undefined>(undefined);

  //NOTE:::Records with disabled resend
  const resendDisableDuration = 30 * 1000;
  const resendDisableCheckIntervalTimeNominal = 1000;
  const [disabledResends, setDisabledResends] = useState<IResendDisableRecord[]>([]);
  const [resendDisableCheckInterval, setResendDisableCheckInterval] = useState<number | null>(null);

  const {
    currentModalStep,
    isUploadingCsvFile,
    openCsvImportModal,
    uploadError,
    setUploadError,
    setOpenCsvImportModal,
    onDownloadCsvTemplate,
    onSubmitUploadedFile,
    onCloseCsvImportModal,
    importEmployeeResult,
    onDownloadErrorsFile,
    templateWasDownloaded,
  } = useImportEmployees({
    setLoading: setLoading,
    currentUser: currentUser,
    refetchEmployeesList: onAddWorker,
  });

  const clearResendDisableInterval = () => {
    setResendDisableCheckInterval(null);
  };

  const removeExpiredResendDisables = () => {
    const newItems = disabledResends.filter(
      (item) => item.datetime + resendDisableDuration > Date.now(),
    );

    setDisabledResends(newItems);
    if (!newItems.length) {
      clearResendDisableInterval();
    }
  };

  const addToResendDisables = (id: string) => {
    const shouldStartInterval = !disabledResends.length;
    const newItems = [
      ...disabledResends,
      {
        id,
        datetime: Date.now(),
      },
    ];
    setDisabledResends(newItems);

    if (shouldStartInterval) {
      setResendDisableCheckInterval(resendDisableCheckIntervalTimeNominal);
    }
  };

  useInterval(removeExpiredResendDisables, resendDisableCheckInterval);

  const showUserInviteSuccessModal = (data: Partial<UserResponseDto>) => {
    AppEvents.emit("SetGlobalModal", {
      isOpen: true,
      title: <Trans i18nKey={`${translationPrefix}.modals.invite_sent.title`} />,
      type: EModalTypes.SUCCESS,
      message: (
        <Trans
          i18nKey={`${translationPrefix}.modals.invite_sent.message`}
          values={{ fullName: getName(data) }}
          components={{ 1: <Emphasized /> }}
        />
      ),
      mainButton: {
        text: <Trans i18nKey={`common.modal.okay`} />,
      },
    });
  };

  const showWorkerAddedModal = (data: Partial<UserResponseDto>) => {
    AppEvents.emit("SetGlobalModal", {
      isOpen: true,
      title: <Trans i18nKey={`${translationPrefix}.modals.worker_added.title`} />,
      type: EModalTypes.SUCCESS,
      message: <Trans i18nKey={`${translationPrefix}.modals.worker_added.message`} />,
      mainButton: {
        text: <Trans i18nKey={`${translationPrefix}.modals.worker_added.primary_btn`} />,
        onClick: () => redirectToEditWorker(data),
      },
      secondaryButton: {
        text: <Trans i18nKey={`buttons.do_it_later`} />,
      },
      buttonsFlex: EButtonsFlex.ROW_REVERSE,
      contentAndButtonsWrapper: WorkerAddedModalInner,
    });
  };

  const showWorkerEditedModal = () => {
    AppEvents.emit("SetGlobalModal", {
      isOpen: true,
      title: <Trans i18nKey={`${translationPrefix}.modals.worker_edited.title`} />,
      type: EModalTypes.SUCCESS,
      message: <Trans i18nKey={`${translationPrefix}.modals.worker_edited.message`} />,
      mainButton: {
        text: <Trans i18nKey={`common.modal.okay`} />,
      },
    });
  };

  const showDeleteWorkerConfirmationModal = (
    data: Partial<UserResponseDto>,
    callback?: () => void,
  ) => {
    AppEvents.emit("SetGlobalModal", {
      isOpen: true,
      title: <Trans i18nKey={`${translationPrefix}.modals.delete_worker.title`} />,
      type: EModalTypes.WARNING,
      message: <Trans i18nKey={`${translationPrefix}.modals.delete_worker.message`} />,
      mainButton: {
        text: <Trans i18nKey={`common.modal.yes`} />,
        onClick: callback,
      },
      secondaryButton: {
        text: <Trans i18nKey={`common.modal.cancel`} />,
      },
    });
  };

  const redirectToEditWorker = (data: Partial<UserResponseDto>, showEmail?: boolean) => {
    if (!data) return;
    const params = showEmail ? `?${SHOW_EMAIL_URL_PARAM}=true` : "";
    const path = `${generatePath(routes.PEOPLE_EDIT_WORKER, {
      id: data.userId || "",
    })}`;
    navigate(`${path}${params}`);
  };

  const handleInviteWorker: IMenuAction = async (data): Promise<void> => {
    setLoading(true);
    try {
      await mutationEmployeesControllerInviteEmployees()({
        userIds: [data.userId ?? ""],
      });
      onInviteWorker?.();
      showUserInviteSuccessModal(data || "");
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReinviteWorker: IMenuAction = async (data): Promise<void> => {
    if (!data.userId) return;
    await handleInviteWorker(data);
    addToResendDisables(data.userId);
  };

  const handleFinishWorkerSetup: IMenuAction = async (data) => {
    redirectToEditWorker(data);
    /*NOTE:::    Uncomment when tab duplication bug is fixed
    window.open(
      generatePath(routes.PEOPLE_EDIT_WORKER, {
        workerId: data.user.userId || "",
      }),
      "_blank",
    ); */
  };
  const handleEditWorker: IMenuAction = async (data) => {
    setEditedUser(data);
    setAddWorkerFormOpen(true);

    if ([UserStatus.ACTIVE, UserStatus.INVITED].includes(data.status as UserStatus)) {
      redirectToEditWorker(data, true);
    }
  };

  const handleDeleteWorker: IMenuAction = async (data) => {
    const _callback = async () => {
      setLoading(true);

      try {
        await mutationEmployeesControllerDeleteEmployee({
          employeeId: data?.userId || "",
        })();
        onDeleteWorker?.();
      } catch (error) {
        showErrorModal(error);
      } finally {
        setLoading(false);
      }
    };
    showDeleteWorkerConfirmationModal(data, _callback);
  };

  const { getContextMenuOptions, getWorkerProfileActions } = useContextMenu({
    onFinishSetup: handleFinishWorkerSetup,
    onInviteWorker: handleInviteWorker,
    onResendInvite: handleReinviteWorker,
    onEditWorker: handleEditWorker,
    onDeleteWorker: handleDeleteWorker,
  });

  const onPaymentReadinessStatusClick = (data: Partial<UserLiteSearchResponseDto>) => {
    if (data?.paymentReadiness === PaymentReadinessStatus.SETUP_PAY) {
      return () => redirectToEditWorker(data, false);
    }

    return undefined;
  };

  const handleOpenCsvImportModal = () => {
    setOpenCsvImportModal(true);
  };

  const openAddWorkerForm = () => {
    setAddWorkerFormOpen(true);
  };

  const closeAddWorkerForm = () => {
    setAddWorkerFormOpen(false);
    setEditedUser(undefined);
  };

  const handleCancelAddWorkerForm = async () => {
    closeAddWorkerForm();
  };

  const serializeAddWorkerValues = (values: AddWorkerFormType) => {
    return {
      ...values,
      phone: getPhoneNumberOrNull(values?.phone || ""),
      employmentType: values.employmentType as SalsaWorkerCategoryEnum,
    };
  };

  const handleAddWorker = async (values: AddWorkerFormType) => {
    setLoading(true);

    try {
      const data = serializeAddWorkerValues(values);
      const addedEmployeeDataRes = await mutationEmployeesControllerCreateEmployee()(data);
      setAddWorkerFormOpen(false);
      showWorkerAddedModal(addedEmployeeDataRes);
      onAddWorker?.();
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveEditedWorker = async (values: AddWorkerFormType) => {
    if (!editedUser?.userId) {
      return;
    }
    setLoading(true);

    try {
      const data = serializeAddWorkerValues(values);
      await mutationEmployeesControllerUpdateEmployee({
        employeeId: editedUser?.userId || "",
      })(data);
      setAddWorkerFormOpen(false);
      setEditedUser(undefined);
      showWorkerEditedModal();
      onEditWorker?.();
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  const renderWorkerActionsElements = () => {
    return (
      <>
        <ImportEmployeesModal
          currentModalStep={currentModalStep}
          isLoading={isLoading}
          isUploadingCsvFile={isUploadingCsvFile}
          isOpen={openCsvImportModal}
          onClose={onCloseCsvImportModal}
          onDownloadCsvTemplate={onDownloadCsvTemplate}
          onSubmit={onSubmitUploadedFile}
          importEmployeeResult={importEmployeeResult!}
          onDownloadErrorsFile={onDownloadErrorsFile}
          templateWasDownloaded={templateWasDownloaded}
          uploadError={uploadError}
          onFileChange={() => setUploadError(null)}
        />

        <AddWorkerPane
          className="add-worker-pane"
          overlayClassName="add-worker-pane-overlay"
          isOpen={isAddWorkerFormOpen}
          onRequestClose={closeAddWorkerForm}
        >
          <AddWorkerCloseButton
            onClick={closeAddWorkerForm}
            type="button"
            data-testid="close-add-worker-form"
          >
            <CloseRoundIcon />
          </AddWorkerCloseButton>
          <AddWorkerForm
            onAddSubmit={handleAddWorker}
            onEditSubmit={handleSaveEditedWorker}
            onCancel={handleCancelAddWorkerForm}
            formResetFlag={false}
            user={editedUser}
          />
        </AddWorkerPane>
      </>
    );
  };

  useEffect(() => {
    return () => {
      clearResendDisableInterval();
    };
  }, []);

  return {
    editedUser,
    isLoading,
    isAddWorkerFormOpen,
    disabledResends,
    getContextMenuOptions,
    getWorkerProfileActions,
    setLoading,
    handleOpenCsvImportModal,
    openAddWorkerForm,
    closeAddWorkerForm,
    handleAddWorker,
    handleSaveEditedWorker,
    handleCancelAddWorkerForm,
    renderWorkerActionsElements,
    onPaymentReadinessStatusClick,
  };
};

export default WorkerActions;

import { isEmpty } from "lodash";
import moment from "moment";

import { BusinessKeyManagerTitle, CompanyEntityType, KybStatus } from "types/BETypes";
import { getKeyManagerRole, getNoonDate } from "helpers";
import { getCompanyEntityTypeSelectOption } from "helpers/shared/companyType";
import { getStateSelectOption } from "helpers/shared/states";

import {
  BusinessOwnerPersonData,
  BusinessStructureBusinessOwnersResponseDto,
  BusinessStructureKeyManagerResponseDto,
  BusinessStructureOwnerResponseDto,
  CreateBusinessOwnerBusinessDto,
  CreateKeyManager,
  GetBusinessStructureResponseDto,
  GetCompanyByIdResponseDto,
  KYBErrorResponseDto,
  UserResponseDto,
} from "utils/swagger_react_query";

import { BusinessInformationFormType } from "./components/BusinessInformationForm/validationSchema";
import { BusinessKeyManagerFormType } from "./components/BusinessKeyManagerForm/validationSchema";
import { IndividualOwnerFormType } from "./components/IndividualOwnerForm/validationSchema";
import {
  BusinessStructureEntityDataType,
  BusinessStructurePersonEntityDataType,
  EKybResponseType,
} from "./types";

export const convertIndividualOwnerDataResToFormData = (
  userData?: BusinessStructureOwnerResponseDto,
): IndividualOwnerFormType | {} => {
  if (isEmpty(userData)) return {};

  return {
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
    phone: userData?.phoneNumber || "",
    socialSecurityNumber: userData?.ssn || "",
    birthDate: userData?.dateOfBirth ? moment(userData?.dateOfBirth).toDate() : null,
    address: userData?.address || "",
    suite: userData?.address2 || "",
    city: userData?.city || "",
    state: getStateSelectOption(userData?.state),
    zip: userData?.zip || "",
    email: userData?.email || "",
    ownershipPercentage: userData?.ownershipPercentage || 0,
  };
};

export const convertKeyManagerDataResToFormData = (
  userData?: BusinessStructureKeyManagerResponseDto,
): BusinessKeyManagerFormType | {} => {
  if (isEmpty(userData)) return {};

  return {
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
    phone: userData?.phoneNumber || "",
    socialSecurityNumber: userData?.ssn || "",
    birthDate: userData?.dateOfBirth ? moment(userData?.dateOfBirth).toDate() : null,
    address: userData?.address || "",
    suite: userData?.address2 || "",
    city: userData?.city || "",
    state: getStateSelectOption(userData?.state),
    zip: userData?.zip || "",
    email: userData?.email || "",
    role: getKeyManagerRole((userData?.title as BusinessKeyManagerTitle) || ""),
  };
};

export const convertBusinessDataResToFormData = (
  business?: BusinessStructureBusinessOwnersResponseDto,
): BusinessInformationFormType | {} => {
  if (isEmpty(business)) return {};

  const businessStructure = getCompanyEntityTypeSelectOption(
    business.businessStructure as CompanyEntityType,
  );

  return {
    ownershipPercentage: business?.ownershipPercentage || 0,
    ein: business?.ein || "",
    companyName: business?.companyName || "",
    businessPhone: business?.phoneNumber || "",
    address: business?.address || "",
    email: business?.email || "",
    city: business?.city || "",
    state: getStateSelectOption(business?.state),
    zip: business?.zip || "",
    operatingName: business.operatingName || "",
    businessFormationDate: business?.dateOfFoundation
      ? moment(business?.dateOfFoundation).toDate()
      : null,
    stateOfIncorporation: getStateSelectOption(
      business?.formationState as UserResponseDto["state"],
    ),
    businessStructure: businessStructure,
    suite: business?.address2 || "",
  };
};

export const convertUserResponseToIndividualOwnerDto = (
  user: UserResponseDto,
): BusinessStructureOwnerResponseDto => {
  return {
    businessStructurePersonId: "",
    ownershipPercentage: 0,
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    phoneNumber: user?.phone || "",
    ssn: user?.socialSecurityNumber || "",
    dateOfBirth: user?.birthDate ? moment(user?.birthDate).toString() : "",
    address: user?.address || "",
    address2: user?.suite || "",
    city: user?.city || "",
    state: user?.state as BusinessStructureOwnerResponseDto["state"],
    zip: user?.zip || "",
    email: user?.email || "",
    updatedAt: "",
  };
};

export const convertUserResponseToKeyManagerDto = (
  user: UserResponseDto,
): BusinessStructureKeyManagerResponseDto => {
  return {
    businessStructurePersonId: "",
    title: {} as any,
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    phoneNumber: user?.phone || "",
    ssn: user?.socialSecurityNumber || "",
    dateOfBirth: user?.birthDate ? moment(user?.birthDate).toString() : "",
    address: user?.address || "",
    address2: user?.suite || "",
    city: user?.city || "",
    state: user?.state as BusinessStructureKeyManagerResponseDto["state"],
    zip: user?.zip || "",
    email: user?.email || "",
    updatedAt: "",
  };
};

export const convertIndividualOwnerFormToReqPayload = (
  values: IndividualOwnerFormType,
): BusinessOwnerPersonData => {
  return {
    phoneNumber: values?.phone || "",
    ssn: values?.socialSecurityNumber || "",
    dateOfBirth: getNoonDate(values?.birthDate) || "",
    address: values?.address || "",
    address2: values?.suite || undefined,
    city: values?.city || "",
    state: values?.state.value || "",
    zip: values?.zip || "",
    firstName: values?.firstName || "",
    lastName: values?.lastName || "",
    email: values?.email || "",
    ownershipPercentage: +values?.ownershipPercentage || 0,
  };
};

export const convertKeyManagerFormToReqPayload = (
  values: BusinessKeyManagerFormType,
): CreateKeyManager => {
  return {
    phoneNumber: values?.phone || "",
    ssn: values?.socialSecurityNumber || "",
    dateOfBirth: getNoonDate(values?.birthDate) || "",
    address: values?.address || "",
    address2: values?.suite || undefined,
    city: values?.city || "",
    state: values?.state.value || "",
    zip: values?.zip || "",
    firstName: values?.firstName || "",
    lastName: values?.lastName || "",
    email: values?.email || "",
    title: values?.role.value || "",
  };
};

export const convertBusinessFormToReqPayload = (
  values: BusinessInformationFormType,
): CreateBusinessOwnerBusinessDto => {
  return {
    companyName: values.companyName || "",
    operatingName: values.operatingName || "",
    dateOfFoundation: getNoonDate(values.businessFormationDate) || "",
    formationState: values.stateOfIncorporation.value || "",
    businessStructure: values.businessStructure.value || "",
    phoneNumber: values.businessPhone || "",
    ein: values.ein || "",
    address: values.address || "",
    address2: values?.suite || undefined,
    city: values.city || "",
    state: values.state.value || "",
    zip: values.zip || "",
    ownershipPercentage: +values.ownershipPercentage || 0,
    email: values.email,
  };
};

export const getKybResponseType = (
  kybStatus?: KybStatus | undefined,
): EKybResponseType | undefined => {
  if (!kybStatus) return undefined;

  if (kybStatus === KybStatus.ACCEPTED) {
    return EKybResponseType.SUCCESS;
  }

  if (
    [
      KybStatus.PROVIDER_FAILURE,
      KybStatus.REJECTED,
      KybStatus.UNVERIFIED,
      KybStatus.VENDOR_ERROR,
    ].includes(kybStatus as KybStatus)
  ) {
    return EKybResponseType.ERROR;
  }

  if (
    [KybStatus.PENDING, KybStatus.PROVISIONAL, KybStatus.REVIEW].includes(kybStatus as KybStatus)
  ) {
    return EKybResponseType.VERIFICATION_IS_TAKING_TOO_LONG;
  }

  if (kybStatus === KybStatus.REJECTED) {
    return EKybResponseType.INCORRECT_INFO;
  }
};

export const convertBusinessFormDataToResponseDto = (
  values: BusinessInformationFormType,
): BusinessStructureBusinessOwnersResponseDto => {
  const result: BusinessStructureBusinessOwnersResponseDto = {
    companyName: values.companyName,
    operatingName: values.operatingName,
    dateOfFoundation: values.businessFormationDate?.toString(),
    formationState: values.stateOfIncorporation.value,
    businessStructure: values.businessStructure.value,
    phoneNumber: values.businessPhone,
    ein: values.ein,
    address: values.address,
    address2: values.suite,
    city: values.city,
    state: values.state.value,
    zip: values.zip,
    ownershipPercentage: values.ownershipPercentage,
    businessStructureBusinessId: "",
    relatedKeyManager: undefined,
    email: values.email,
    updatedAt: "",
  };

  return result;
};

export const getAllKeyManagers = (
  businessOwnersStructure: GetBusinessStructureResponseDto | undefined | null,
): BusinessStructureKeyManagerResponseDto[] => {
  const result = [...(businessOwnersStructure?.keyManagers || [])];

  businessOwnersStructure?.businessOwners?.forEach(
    (it) => it.relatedKeyManager && result.push(it.relatedKeyManager),
  );

  return result;
};

export const getBusinessStructureEntityErrorIds = (errors: KYBErrorResponseDto[]): string[] => {
  return (
    errors
      .map((error) => error?.businessStructurePersonId || error?.businessStructureBusinessId)
      .filter((it) => it !== null && it !== undefined) || []
  );
};

export const canRerunKyb = (
  currentCompany: GetCompanyByIdResponseDto | null,
  businessStructure: GetBusinessStructureResponseDto | null,
  errors: KYBErrorResponseDto[],
): boolean => {
  if (!currentCompany?.kybRunAt) {
    return false;
  }

  const kybRunAt = moment(currentCompany.kybRunAt).milliseconds(0);
  const isUpdatedDateAfterRunKyb = (updatedAt: string) => {
    const updatedAtDate = moment(updatedAt).milliseconds(0);
    const result = updatedAtDate.isAfter(kybRunAt);
    return result;
  };
  const entityErrorIds = getBusinessStructureEntityErrorIds(errors);

  const hasInvalidDate = (
    items: {
      updatedAt: string;
      businessStructurePersonId?: string;
      businessStructureBusinessId?: string;
    }[],
  ) =>
    items.some((item) => {
      const id = item.businessStructurePersonId || item.businessStructureBusinessId;
      const hasError = id && entityErrorIds.includes(id);
      const isDateAfterRunKyb = isUpdatedDateAfterRunKyb(item.updatedAt);
      return hasError && !isDateAfterRunKyb;
    });

  const businessInfoInvalid = businessStructure?.business
    ? hasInvalidDate([businessStructure?.business])
    : true;
  const keyManagersInvalid = hasInvalidDate(businessStructure?.keyManagers || []);
  const ownersInvalid = hasInvalidDate(businessStructure?.owners || []);
  const businessOwnersInvalid = hasInvalidDate(businessStructure?.businessOwners || []);

  return !(keyManagersInvalid || ownersInvalid || businessOwnersInvalid || businessInfoInvalid);
};

export const canBusinessOwnerAcceptDisclosure = (
  businessOwners: GetBusinessStructureResponseDto["businessOwners"] | null,
  errors: KYBErrorResponseDto[],
): boolean => {
  const businessOwnerAndKeyManagerIds = businessOwners
    ?.flatMap((it) => [
      it?.businessStructureBusinessId,
      it.relatedKeyManager?.businessStructurePersonId,
    ])
    .filter((it) => !!it);

  const businessOwnerErrors = errors.some((it) => {
    const _id = it.businessStructurePersonId || it.businessStructureBusinessId;
    return _id && businessOwnerAndKeyManagerIds?.includes(_id);
  });

  return businessOwnerErrors;
};

export const getFormattedCompanyNameWithKeyManagerFullName = (
  companyName: string,
  keyManagerFullName: string,
): string => {
  return `${companyName} • ${keyManagerFullName}`;
};

export const getAllBusinessStructureEntities = (
  businessOwnersStructure: GetBusinessStructureResponseDto | null,
): Array<BusinessStructureEntityDataType> => {
  const entities = [
    ...(businessOwnersStructure?.owners || []),
    ...(businessOwnersStructure?.businessOwners || []),
    ...(businessOwnersStructure?.keyManagers || []),
  ];

  return entities;
};

export const getBusinessStructureMembers = (
  businessOwnersStructure: GetBusinessStructureResponseDto | null,
  includeBusinessKeyManagers?: boolean,
): Array<BusinessStructurePersonEntityDataType> => {
  const members = [
    ...(businessOwnersStructure?.keyManagers || []),
    ...(businessOwnersStructure?.owners || []),
  ];
  if (includeBusinessKeyManagers && !!businessOwnersStructure?.businessOwners) {
    const relatedKeyManagers = businessOwnersStructure.businessOwners.map(
      (it) => it.relatedKeyManager!,
    );
    members.push(...relatedKeyManagers);
  }

  const uniqueMembers = Array.from(
    new Map(members.map((item) => [item.businessStructurePersonId, item])).values(),
  );

  return uniqueMembers;
};

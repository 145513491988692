import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, salsaSelector } from "store/selectors";

import { ErrorConst } from "types/BETypes";
import { showErrorModal } from "helpers";
import { getDeferredEmployeeData } from "helpers/employee/employeeRequests";
import { WorkerContextMenuActionType } from "components/WorkersWidgets/WorkerActions/types";
import useWorkerActions from "components/WorkersWidgets/WorkerActions/useWorkerActions";

import { GetEmployeeByIdResponseDto } from "utils/swagger_react_query";

const useAdminWorkerDetails = () => {
  const navigate = useNavigate();
  const { id: workerId } = useParams();
  const salsaData = useAppSelector(salsaSelector);
  const { salsa, authToken } = salsaData || {};
  const currentCompany = useAppSelector(companyMetadataSelector);
  const frameId = "onboarding-container";
  const frameSelector = `#${frameId}`;

  const [isLoading, setLoading] = useState<boolean>(true);
  const [employeeData, setEmployeeData] = useState<GetEmployeeByIdResponseDto>();

  const redirectToList = () => {
    navigate(routes.PEOPLE);
  };

  const fetchEmployeeInfo = async () => {
    try {
      setLoading(true);
      const data = await getDeferredEmployeeData(workerId || "");
      if (!data) throw new Error(ErrorConst.USER_NOT_FOUND);
      setEmployeeData(data);
    } catch (error) {
      showErrorModal(error, redirectToList);
    } finally {
      setLoading(false);
    }
  };

  const {
    isLoading: actionsLoading,
    disabledResends,
    renderWorkerActionsElements,
    getWorkerProfileActions,
    onPaymentReadinessStatusClick,
  } = useWorkerActions({
    onEditWorker: fetchEmployeeInfo,
    onDeleteWorker: redirectToList,
    onInviteWorker: fetchEmployeeInfo,
  });

  const redirectToPeopleList = () => {
    navigate(routes.PEOPLE);
  };

  const handleBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      redirectToPeopleList();
      return;
    }
  };

  const getControls = () => {
    if (!employeeData) return [];
    const menuItems = getWorkerProfileActions(employeeData);
    const resendItem = menuItems.find(
      (item) => item?.value === WorkerContextMenuActionType.RESEND_INVITE,
    );
    if (resendItem) {
      resendItem.disabled = !!disabledResends.find((item) => item.id === employeeData.userId);
    }
    return menuItems;
  };

  useEffect(() => {
    fetchEmployeeInfo();
  }, []);

  useEffect(() => {
    if (employeeData && salsa) {
      const salsaElement = salsa.elements.create("worker-payment-list", {
        userToken: authToken,
        employerId: currentCompany?.salsaEmployerId,
        workerId: employeeData.salsaWorkerId,
      });

      salsa.elements.mount(salsaElement, frameSelector);
      return () => {
        salsa.elements.destroy(salsaElement);
      };
    }
  }, [salsa, employeeData]);

  return {
    isLoading,
    employeeData,
    frameId,
    actionsLoading,
    handleBack,
    renderWorkerActionsElements,
    getControls,
    onPaymentReadinessStatusClick,
  };
};

export default useAdminWorkerDetails;

import { FC } from "react";
import { Trans } from "react-i18next";
import { Timesheet } from "@easyteam/web-ui";

import DashboardLayout from "layouts/DashboardLayout";
import EmptyList from "components/EmptyList";

import { Loader } from "uikit";
import { LoaderContainer } from "uikit/Loader/styles";

import { useAdminTimesheetsDetailsPage } from "./useAdminTimesheetsDetailsPage";
import { Container } from "./styles";

const AdminTimesheetsDetailsPage: FC = () => {
  const translationPrefix = "timesheets_page";
  const { isPageLoading, embeddedContent, actions, pageData } = useAdminTimesheetsDetailsPage();
  const { isEmbeddedContentDataFetched, getContentProvider } = embeddedContent;

  const renderEmbeddedContent = () => {
    const EmbeddedContentProvider = getContentProvider;

    if (!isEmbeddedContentDataFetched && !isPageLoading) {
      return (
        <EmptyList>
          <Trans i18nKey={`${translationPrefix}.empty_list_section.message`} />
        </EmptyList>
      );
    }

    return (
      <EmbeddedContentProvider>
        <Timesheet
          employeeId={pageData.loctionState?.employeeId}
          start={pageData.loctionState?.startDate}
          end={pageData.loctionState?.endDate}
          onBack={actions.handleBackClick}
        />
      </EmbeddedContentProvider>
    );
  };

  return (
    <DashboardLayout showTitle={false}>
      <Container>
        {isPageLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          renderEmbeddedContent()
        )}
      </Container>
    </DashboardLayout>
  );
};

export default AdminTimesheetsDetailsPage;

import { FC } from "react";
import { Timesheet } from "@easyteam/web-ui";

import DashboardLayout from "layouts/DashboardLayout";

import { Loader } from "uikit";
import { LoaderContainer } from "uikit/Loader/styles";

import { useEmployeeTimesheetsPage } from "./useEmployeeTimesheetsPage";
import { Container } from "./styles";

const EmployeeTimesheetsPage: FC = () => {
  const { isPageLoading, embeddedContent, currentUser } = useEmployeeTimesheetsPage();
  const { getContentProvider } = embeddedContent;

  const renderEmbeddedContent = () => {
    const EmbeddedContentProvider = getContentProvider;

    return (
      <EmbeddedContentProvider>
        <Timesheet employeeId={currentUser?.user?.userId || ""} onBack={() => {}} />
      </EmbeddedContentProvider>
    );
  };

  return (
    <DashboardLayout showTitle={false}>
      <Container>
        {isPageLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          renderEmbeddedContent()
        )}
      </Container>
    </DashboardLayout>
  );
};

export default EmployeeTimesheetsPage;

import { CustomTheme } from "@easyteam/core-ui";
import { theme } from "styles/theme";

export const easyTeamColors: CustomTheme["tokens"] = {
  colors: {
    primary: {
      main: theme.colors.primary,
      dark: theme.colors.primaryAlt,
      darker: theme.colors.accentMain,
      accent: {
        main: theme.colors.primaryText,
        dark: theme.colors.primaryText,
        darker: theme.colors.primaryText,
      },
    },
    secondary: {
      main: theme.colors.noBorderButtonBg,
      dark: theme.colors.noBorderButtonBg,
      darker: theme.colors.noBorderButtonBg,
      accent: {
        main: theme.colors.accentMain,
        dark: theme.colors.noBorderButtonTextAlt,
        darker: theme.colors.noBorderButtonTextAlt,
      },
    },
    disabled: {
      main: theme.colors.primaryDisabled,
      accent: {
        main: theme.colors.textInactiveAlt2,
      },
    },
    switch: {
      thumb: {
        checked: theme.colors.contentBg,
        unchecked: theme.colors.contentBg,
      },
      track: {
        checked: theme.colors.primary,
        unchecked: theme.colors.primaryDisabled,
      },
    },
    text: {
      main: theme.colors.text,
      secondary: theme.colors.textAlt,
      tertiary: theme.colors.text,
      quaternary: theme.colors.text,
    },
    border: {
      lighter: theme.colors.contentBorderAlt,
      main: theme.colors.contentBorder,
      hover: theme.colors.contentBorder,
      focus: theme.colors.contentBorder,
    },
    background: {
      main: theme.colors.contentBg,
      secondary: "transparent",
      tertiary: "transparent",
      quaternary: "transparent",
    },
    error: {
      main: theme.colors.danger,
    },
    delete: {
      main: theme.colors.dangerAlt3,
      dark: theme.colors.dangerAlt,
      darker: theme.colors.dangerAlt,
      accent: theme.colors.danger,
      hover: theme.colors.dangerAlt,
      hoverAccent: theme.colors.dangerText,
      active: theme.colors.dangerAlt,
      activeAccent: theme.colors.dangerText,
      secondary: theme.colors.dangerAlt,
    },
    table: {
      header: {
        backgroundColor: "background.main",
        foregroundColor: "text.main",
      },
      border: {
        backgroundColor: "transparent",
      },
      row: {
        backgroundColor: "transparent",
        foregroundColor: "text.main",
        _hover: {
          backgroundColor: "border.lighter",
        },
        _active: {
          backgroundColor: "border.lighter",
        },
      },
    },
    button: {
      default: {
        backgroundColor: theme.colors.primary,
        foregroundColor: theme.colors.primaryText,
        _hover: {
          backgroundColor: theme.colors.primaryAlt,
          foregroundColor: theme.colors.primaryText,
        },
        _active: {
          backgroundColor: theme.colors.primaryAlt,
          foregroundColor: theme.colors.primaryText,
        },
      },
      primary: {
        background: {
          main: theme.colors.primary,
          dark: theme.colors.primaryAlt,
          darker: theme.colors.primaryAlt,
        },
        foreground: {
          main: "#ffffff",
          dark: "#ffffff",
          darker: "#ffffff",
        },
      },
      icon: {
        backgroundColor: "transparent",
        foregroundColor: theme.colors.text,
        _hover: {
          backgroundColor: "transparent",
          foregroundColor: theme.colors.textAlt,
        },
        _active: {
          backgroundColor: "transparent",
          foregroundColor: theme.colors.textAlt,
        },
      },
    },
  },
};

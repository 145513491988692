import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

export const Wrapper = styled.div<{ small?: boolean }>`
  max-width: 440px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
  position: relative;

  ${({ small }) =>
    small &&
    `
    gap: 16px 0;
  `}

  ${breakpoint("xs", "xl")`
    width: 100%;
    max-height: max-content;
  `}

  ${breakpoint("xs", "lg")`
    flex-direction: column;
  `}
`;

export const MailAppButton = styled.div<{ small?: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  width: 200px;
  height: 48px;
  border-radius: 32px;
  padding: 12px 16px 12px 16px;
  box-sizing: border-box;
  cursor: pointer;

  & > span {
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.size.body1};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    line-height: 24px;
    margin-left: 12px;
  }

  & > svg {
    width: 20px;
    height: 20px;
  }

  ${({ small }) =>
    small &&
    `
    width: auto;
  `}

  ${breakpoint("xs", "lg")`
    height: 40px;
    padding: 10px 12px 10px 12px;
    font-size: ${({ theme }) => theme.size.body2};
    max-width: 408px;
    width: 100%;
    justify-content: center;
  `}
`;

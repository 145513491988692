import { FC, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EyeIcon } from "assets/svg";
import zxcvbn from "zxcvbn";

import Input from "./Input";
import { BaseInputProps } from "./types";
import {
  MaskedInputWrapper,
  MaskedNumberInputIconWrapper,
  PasswordInputStrengthIndicator,
  PasswordInputStrengthIndicatorContainer,
  PasswordInputStrengthIndicatorLabel,
} from "./styles";

export type Props = {
  name?: string;
  error?: ReactNode;
  label?: ReactNode;
  value: string;
  onChange?: (value: string) => void;
  needHide?: boolean;
  disabled?: boolean;
  placeholder?: string;
  showPasswordStrength?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement> &
  BaseInputProps;

export enum PasswordInputStrengthType {
  LOW = "LOW",
  MODERATE = "MODERATE",
  STRONG = "STRONG",
}

const PasswordInput: FC<Props> = ({
  error,
  label,
  onChange,
  value,
  disabled,
  showPasswordStrength,
  ...rest
}) => {
  const { t } = useTranslation();
  const translationPrefix = "auth_pages.shared.components.password_form";
  const [isHidden, setHidden] = useState<boolean>(true);

  const handleChange = (_value: string) => {
    onChange?.(_value);
  };

  const strengthIndicator = useMemo(() => {
    function serializePasswordScore(score: number): PasswordInputStrengthType {
      if (score <= 1) {
        return PasswordInputStrengthType.LOW;
      } else if (score === 2) {
        return PasswordInputStrengthType.MODERATE;
      } else {
        return PasswordInputStrengthType.STRONG;
      }
    }
    const data = zxcvbn(value);
    const indicatorsArray = Object.keys(PasswordInputStrengthType);
    const indicators = indicatorsArray.map((_, i) => ({
      index: i,
    }));
    const score = serializePasswordScore(data.score);
    const scoreIndex = indicatorsArray.indexOf(score);
    return (
      <PasswordInputStrengthIndicatorContainer>
        <PasswordInputStrengthIndicator steps={indicators} currentStep={scoreIndex} score={score} />
        <PasswordInputStrengthIndicatorLabel score={score}>
          {t(`${translationPrefix}.password_strength_labels.${score}`)}
        </PasswordInputStrengthIndicatorLabel>
      </PasswordInputStrengthIndicatorContainer>
    );
  }, [value]);

  return (
    <MaskedInputWrapper>
      <MaskedNumberInputIconWrapper
        onClick={() => setHidden(!isHidden)}
        data-testid={"password-visibility-btn"}
      >
        <EyeIcon isCrossedOut={isHidden} />
      </MaskedNumberInputIconWrapper>
      <Input
        onChange={(e) => handleChange(e.currentTarget.value)}
        value={value}
        type={isHidden ? "password" : "text"}
        placeholder={t("auth_pages.shared.components.password_form.password_input_placeholder")}
        label={label}
        error={error}
        disabled={disabled}
        errorDataTestId="error-password"
        {...rest}
      />
      {showPasswordStrength && !!value && strengthIndicator}
    </MaskedInputWrapper>
  );
};

export default PasswordInput;

import { FC, ReactNode, useState } from "react";
import { PhoneInput as InternationalPhoneInput } from "react-international-phone";
import { NumberFormatProps } from "react-number-format";
import { ModalAlert } from "assets/svg";

import { renderLabel } from "./helpers";
import { BaseInputProps } from "./types";
import { Container, ErrorContainer, InputWrapper } from "./styles";

import "react-international-phone/style.css";

export type Props = {
  name?: string;
  onChange?: (value: string) => void;
  value?: string;
  label?: ReactNode | string;
  readOnly?: boolean;
  alwaysShowErrorBlock?: boolean;
  optional?: boolean;
  errorDataTestId?: string;
} & NumberFormatProps &
  BaseInputProps;

const PhoneInput: FC<Props> = (props) => {
  const {
    className,
    label,
    error,
    onFocus,
    onBlur,
    readOnly,
    value,
    onChange,
    alwaysShowErrorBlock = true,
    optional = false,
    ...rest
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  const isFloatingLabel = !readOnly && label && (isFocused || value);

  return (
    <Container
      className={`${className} ${isFloatingLabel ? "with-floating-label" : ""} ${
        optional ? "optional" : ""
      } phone-input`}
    >
      <InputWrapper>
        <InternationalPhoneInput
          defaultCountry="US"
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          hideDropdown={true}
          disableCountryGuess={true}
          disableDialCodePrefill={true}
          forceDialCode={true}
          {...rest}
        />
        {renderLabel(label, optional)}

        {/*FIXME::: {optional && (
          <OptionalLabel>
            <Trans i18nKey={"common.input.optional"} />
          </OptionalLabel>
        )} */}
      </InputWrapper>
      {(alwaysShowErrorBlock || error) && (
        <ErrorContainer data-testid={props.errorDataTestId}>
          {error && <ModalAlert />}
          {error}
        </ErrorContainer>
      )}
    </Container>
  );
};

export default PhoneInput;

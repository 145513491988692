import { FC } from "react";

import IconProps from "./IconProps";

const ClockAltSmallIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_15279_656)">
      <path
        d="M3 12.2C3 13.3818 3.23279 14.5522 3.68508 15.6441C4.13738 16.736 4.80031 17.7282 5.63604 18.5639C6.47177 19.3996 7.46392 20.0626 8.55585 20.5149C9.64778 20.9672 10.8181 21.2 12 21.2C13.1819 21.2 14.3522 20.9672 15.4442 20.5149C16.5361 20.0626 17.5282 19.3996 18.364 18.5639C19.1997 17.7282 19.8626 16.736 20.3149 15.6441C20.7672 14.5522 21 13.3818 21 12.2C21 9.813 20.0518 7.52382 18.364 5.83599C16.6761 4.14816 14.3869 3.19995 12 3.19995C9.61305 3.19995 7.32387 4.14816 5.63604 5.83599C3.94821 7.52382 3 9.813 3 12.2Z"
        stroke="#60AEAC"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 7.19995V12.2L15 15.2"
        stroke="#60AEAC"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_15279_656">
        <rect width="24" height="24" fill="white" transform="translate(0 0.199951)" />
      </clipPath>
    </defs>
  </svg>
);

export default ClockAltSmallIcon;

import { FC, ReactNode } from "react";
import { Trans } from "react-i18next";
import { GmailIcon, OutlookIcon, YahooIcon } from "assets/svg";

import { MailAppButton, Wrapper } from "./styles";

interface IMailAppsConfig {
  title: string;
  icon: ReactNode;
  url: string;
}

interface IProps {
  className?: string;
  showAppNameAsTitle?: boolean;
}

const MailAppButtons: FC<IProps> = ({ className, showAppNameAsTitle }) => {
  const translationPrefix = "auth_pages.shared.components.mail_app_buttons";

  const mailAppsConfig: IMailAppsConfig[] = [
    {
      title: `${translationPrefix}.${showAppNameAsTitle ? "google" : "accept_invitation_btn"}`,
      icon: <GmailIcon />,
      url: "https://mail.google.com/",
    },
    {
      title: `${translationPrefix}.${showAppNameAsTitle ? "yahoo" : "accept_invitation_btn"}`,
      icon: <YahooIcon />,
      url: "https://mail.yahoo.com",
    },
    {
      title: `${translationPrefix}.${showAppNameAsTitle ? "outlook" : "accept_invitation_btn"}`,
      icon: <OutlookIcon />,
      url: "https://outlook.office365.com ",
    },
  ];

  return (
    <Wrapper small={showAppNameAsTitle} className={className}>
      {mailAppsConfig.map((it) => (
        <MailAppButton
          small={showAppNameAsTitle}
          key={it.title}
          onClick={() => window.open(it.url)}
          data-testid={it.title}
        >
          {it.icon}
          <span>
            <Trans i18nKey={it.title} />
          </span>
        </MailAppButton>
      ))}
    </Wrapper>
  );
};

export default MailAppButtons;

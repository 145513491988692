import { useEffect } from "react";

export const useBeforeUnload = (shouldPrompt: () => boolean) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (shouldPrompt()) {
        event.preventDefault();
        // NOTE::: For browser compatibility
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [shouldPrompt]);
};

import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { useConnectEasyTeam } from "hooks/useConnectEasyTeam";

export const useEmployeeTimesheetsPage = () => {
  const currentUser = useAppSelector(userMetadataSelector);
  const {
    isLoading: isEmbeddedContentLoading,
    isEmbeddedContentDataFetched,
    getContentProvider,
  } = useConnectEasyTeam({});

  return {
    currentUser,
    isPageLoading: isEmbeddedContentLoading,
    embeddedContent: {
      isEmbeddedContentDataFetched,
      getContentProvider,
    },
  };
};

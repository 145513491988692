import zxcvbn from "zxcvbn";

import { PASSWORD_STRENGTH_MIN_SCORE } from "constants/auth";

export const validatePasswordStrength = (password: string): boolean => {
  if (!password) return false;

  const { score } = zxcvbn(password);

  return score >= PASSWORD_STRENGTH_MIN_SCORE;
};

import { FC, FormEvent } from "react";
import { Trans } from "react-i18next";
import { ArrowIconSmall } from "assets/svg";
import { Formik, FormikProps } from "formik";
import routes from "routes/routes";

import { HELP_CENTER } from "constants/shared";
import AuthLayout from "layouts/AuthLayout";
import {
  PasswordFormSchemasType,
  PasswordFormType,
  passwordFormValidationSchemas,
} from "components/auth";
import PasswordForm from "components/auth/PasswordForm/PasswordForm";

import { Footer, FooterLinkExternal, StyledPrimaryBtn } from "../_shared/components/styles";
import { SendResetPasswordEmailForm } from "./components";
import { ResetPasswordStep } from "./types";
import { useResetPasswordPage } from "./useResetPasswordPage";
import { BackButton, Container, PasswordFormContainer, SecurityDisclaimer } from "./styles";

const ResetPasswordPage: FC = () => {
  const translationPrefix = `auth_pages.shared.reset_password_page`;
  const { metadata, actions } = useResetPasswordPage();
  const disclaimerText = `auth_pages.shared.reset_password_page.${metadata.isRedirectedFromSettings ? "security_disclaimer_settings" : "security_disclaimer"}`;

  const onPasswordFormSubmit = (
    e: FormEvent<HTMLFormElement>,
    formikProps: FormikProps<PasswordFormSchemasType[PasswordFormType.RESET_PASSWORD]>,
  ) => {
    e.preventDefault();
    actions.handleResetPassword(formikProps);
  };

  return (
    <AuthLayout isLoading={metadata.isLoading}>
      <Container>
        {metadata.currentStep === ResetPasswordStep.SEND_EMAIL && (
          <>
            <BackButton to={routes.SIGN_IN} data-testid="back-btn">
              <ArrowIconSmall />
              <Trans i18nKey={`${translationPrefix}.back_btn`} />
            </BackButton>
            <SendResetPasswordEmailForm
              handleSubmit={actions.handleSendResetPasswordEmail}
              initValues={metadata.initFormValues}
            />
            <SecurityDisclaimer>
              <Trans i18nKey={`${disclaimerText}`} />
            </SecurityDisclaimer>
          </>
        )}
        {metadata.currentStep === ResetPasswordStep.RESET_PASSWORD && (
          <Formik<PasswordFormSchemasType[PasswordFormType.RESET_PASSWORD]>
            initialValues={{ newPassword: "", confirmPassword: "" }}
            validateOnChange={metadata.svoc}
            validationSchema={passwordFormValidationSchemas.fields[PasswordFormType.RESET_PASSWORD]}
            onSubmit={() => {}}
          >
            {(formikProps) => {
              return (
                <PasswordFormContainer onSubmit={(e) => onPasswordFormSubmit(e, formikProps)}>
                  <PasswordForm type={PasswordFormType.RESET_PASSWORD} />

                  <StyledPrimaryBtn type="submit" data-testid="reset-password-btn">
                    <Trans i18nKey={`buttons.continue`} />
                  </StyledPrimaryBtn>
                </PasswordFormContainer>
              );
            }}
          </Formik>
        )}

        <Footer>
          <FooterLinkExternal href={HELP_CENTER} target="_blank">
            <Trans i18nKey={`buttons.need_help_btn`} />
          </FooterLinkExternal>
        </Footer>
      </Container>
    </AuthLayout>
  );
};

export default ResetPasswordPage;

import {
  GetEmployeeByIdResponseDto,
  queryEmployeesControllerGetEmployee,
} from "utils/swagger_react_query";

export const getDeferredData = async <T>(
  request: () => Promise<T | undefined | null>,
  condition: (data: T | undefined | null) => boolean,
  intervalTime: number = 2000,
  maxAttempts: number = 5,
): Promise<T> => {
  const data = await request();
  if (data && condition(data)) {
    return data;
  }

  return await new Promise((resolve, reject) => {
    let attempts = maxAttempts;

    const attempt = async () => {
      try {
        const data = await request();
        attempts--;

        if (condition(data) || attempts <= 0) {
          resolve(data as T);
        } else {
          setTimeout(attempt, intervalTime);
        }
      } catch (error) {
        reject(error);
      }
    };

    attempt();
  });
};

export const getDeferredEmployeeData = async (
  id: string,
): Promise<GetEmployeeByIdResponseDto | null | undefined> => {
  const request = async () => {
    const data: GetEmployeeByIdResponseDto = await queryEmployeesControllerGetEmployee({
      employeeId: id || "",
    });
    return data;
  };

  const condition = (data: GetEmployeeByIdResponseDto | null | undefined) => {
    return !!data?.salsaWorkerId;
  };

  return getDeferredData<GetEmployeeByIdResponseDto | null | undefined>(request, condition);
};

import { UserRole } from "types/BETypes";
import {
  SALSA_AUTH_TOKEN_EMPLOYER,
  SALSA_AUTH_TOKEN_REQUEST_DATETIME,
  SALSA_AUTH_TOKEN_WORKER,
} from "constants/salsa";

import { mutationAuthControllerGetSalsaTokens, TokenResponseDto } from "utils/swagger_react_query";

export type ISalsaTokens = Pick<TokenResponseDto, "salsaEmployerToken" | "salsaWorkerToken">;

const _saveSalsaToken = (tokens: ISalsaTokens) => {
  if (tokens.salsaEmployerToken) {
    localStorage.setItem(SALSA_AUTH_TOKEN_EMPLOYER, tokens.salsaEmployerToken);
  }
  if (tokens.salsaWorkerToken) {
    localStorage.setItem(SALSA_AUTH_TOKEN_WORKER, tokens.salsaWorkerToken);
  }
  if (tokens.salsaEmployerToken || tokens.salsaWorkerToken) {
    localStorage.setItem(SALSA_AUTH_TOKEN_REQUEST_DATETIME, Date.now().toString());
  }
};
const _removeSalsaToken = () => {
  localStorage.removeItem(SALSA_AUTH_TOKEN_EMPLOYER);
  localStorage.removeItem(SALSA_AUTH_TOKEN_WORKER);
  localStorage.removeItem(SALSA_AUTH_TOKEN_REQUEST_DATETIME);
};

export const saveSalsaData = (tokens: ISalsaTokens) => {
  if (!Object.keys(tokens).length) throw new Error("Token is required");
  _saveSalsaToken(tokens);
};

export const _getSalsaAuthToken = async () => {
  const tokens = await mutationAuthControllerGetSalsaTokens()();
  saveSalsaData(tokens);
};

export const getSalsaAuthToken = async (
  roles: UserRole[],
  userRole?: UserRole,
  forceRequest?: boolean,
) => {
  let _token: string | null = null;

  const currentTime = Date.now();
  const lastTokenRequestTime = localStorage.getItem(SALSA_AUTH_TOKEN_REQUEST_DATETIME);
  const ttl = Number(process.env.REACT_APP_SALSA_TOKEN_ACTIVITY_MINUTES || 60) * 60 * 1000;
  const isTokenExpired = !lastTokenRequestTime || currentTime - Number(lastTokenRequestTime) >= ttl;
  const hasNoMatchedTokens = !getSalsaTokensMatchedWithRoles(roles);
  const shouldRequestToken = forceRequest || isTokenExpired || hasNoMatchedTokens;

  if (shouldRequestToken) {
    await _getSalsaAuthToken();
  }

  if (
    userRole === UserRole.ADMIN ||
    userRole === UserRole.SUPERADMIN ||
    userRole === UserRole.OWNER
  ) {
    _token = localStorage.getItem(SALSA_AUTH_TOKEN_EMPLOYER);
  } else if (userRole === UserRole.EMPLOYEE) {
    _token = localStorage.getItem(SALSA_AUTH_TOKEN_WORKER);
  }

  return _token;
};

export const getSalsaTokensMatchedWithRoles = (userRoles: UserRole[]) => {
  if (!userRoles.length) return false;

  const employerToken = localStorage.getItem(SALSA_AUTH_TOKEN_EMPLOYER);
  const workerToken = localStorage.getItem(SALSA_AUTH_TOKEN_WORKER);

  const isOneOfAdminRoles = userRoles.some((role) =>
    [UserRole.ADMIN, UserRole.OWNER, UserRole.SUPERADMIN].includes(role),
  );
  const isEmployee = userRoles.includes(UserRole.EMPLOYEE);

  if (isOneOfAdminRoles && isEmployee) {
    return !!(employerToken && workerToken);
  }

  if (isOneOfAdminRoles) {
    return !!employerToken;
  }

  if (isEmployee) {
    return !!workerToken;
  }

  return false;
};

export const clearSalsaData = () => {
  _removeSalsaToken();
};

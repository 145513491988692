import { useConnectEasyTeam } from "hooks/useConnectEasyTeam";

export const useAdminTimesheetSettingsPage = () => {
  const {
    isLoading: isEmbeddedContentLoading,
    isEmbeddedContentDataFetched,
    getContentProvider,
  } = useConnectEasyTeam({});

  return {
    isPageLoading: isEmbeddedContentLoading,
    embeddedContent: {
      isEmbeddedContentDataFetched,
      getContentProvider,
    },
  };
};

import { FC, useState } from "react";
import { Trans } from "react-i18next";
import { LockIcon } from "assets/svg";
import { Formik } from "formik";

import { Loader } from "uikit";
import { LoaderContainer } from "uikit/Loader/styles";

import {
  BackBtn,
  BtnsWrapper,
  BtnsWrapperOuter,
  SubmitBtn,
} from "../../CompanyBankAccountCreationModal/styles";
import { renderErrorMessage } from "../../components/renderElements";
import {
  FormSection,
  HDescription,
  Header,
  HTitle,
  ScrollableContainer,
} from "../../components/styles";
import {
  BusinessAccountCompanyInfoForm,
  BusinessAccountCompanyInfoFormType,
  BusinessAccountCompanyInfoValidationSchema,
} from "./components";
import { IBusinessInformationProps } from "./types";
import { useBusinessInformation } from "./useBusinessInformation";
import { Container, FormContainer } from "./styles";

const BusinessInformation: FC<IBusinessInformationProps> = ({
  className,
  onBackBtnClick,
  onSubmitSuccess,
  ...rest
}) => {
  const translationPrefixOrigin = `components.company_bank_account_components`;
  const translationPrefix = `${translationPrefixOrigin}.admin.business_information`;
  const [svoc, setSvoc] = useState<boolean>(false);
  const { errorMessage, isSubmitting, initValues, handleSubmit, isLoading } =
    useBusinessInformation({
      onSubmitSuccess,
      ...rest,
    });

  const formSectionClassName = `${isSubmitting ? "loading" : ""}`;
  if (isLoading) return <Loader />;

  return (
    <Formik<BusinessAccountCompanyInfoFormType>
      onSubmit={() => {}}
      initialValues={initValues || ({} as BusinessAccountCompanyInfoFormType)}
      validationSchema={BusinessAccountCompanyInfoValidationSchema}
      validateOnChange={svoc}
      enableReinitialize={true}
    >
      {(formikProps) => (
        <Container
          className={className}
          onSubmit={(e) => {
            e.preventDefault();
            setSvoc(true);
            handleSubmit({
              values: formikProps.values,
              validateForm: formikProps.validateForm,
              dirty: formikProps.dirty,
            });
          }}
        >
          <Header>
            <HTitle>
              <Trans i18nKey={`${translationPrefix}.title`} />
            </HTitle>
            <HDescription>
              <Trans i18nKey={`${translationPrefix}.protection_description`} />
              <LockIcon />
            </HDescription>
          </Header>

          {renderErrorMessage(errorMessage)}

          <FormContainer>
            <ScrollableContainer>
              <FormSection className={formSectionClassName}>
                {isSubmitting && (
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                )}
                <BusinessAccountCompanyInfoForm />
              </FormSection>
            </ScrollableContainer>
          </FormContainer>

          <BtnsWrapperOuter>
            <BtnsWrapper>
              <BackBtn
                onClick={onBackBtnClick}
                type={"button"}
                disabled={isSubmitting}
                id={"cbacm-business-info-form-back-button"}
                data-testid={"cbacm-business-info-form-back-button"}
              >
                <Trans i18nKey="buttons.cancel" />
              </BackBtn>

              <SubmitBtn
                type={"submit"}
                id={"cbacm-business-info-form-submit-button"}
                data-testid={"cbacm-business-info-form-submit-button"}
              >
                <Trans i18nKey={`buttons.submit`} />
              </SubmitBtn>
            </BtnsWrapper>
          </BtnsWrapperOuter>
        </Container>
      )}
    </Formik>
  );
};

export default BusinessInformation;

import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

import TextWithTooltip from "../TextWithTooltip/TextWithTooltip";

export const labelMargin = 8;
export const labelMarginLarge = 12;
export const labelMarginLarger = 16;

export const H2Mixin = css`
  font-family: ${({ theme }) => theme.fontFamily.alt};
  font-size: ${({ theme }) => theme.size.title};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.textEmphasized};
  line-height: 1.33;
`;

export const H1 = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: 54px;
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  color: ${({ theme }) => theme.colors.textEmphasized};
  line-height: 1.18;
`;

export const H2 = styled.h2`
  ${H2Mixin};
`;

export const H3 = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.textEmphasized};
  line-height: 1.33;
`;

export const H4 = styled.h4`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.5;
`;

export const Body1Mixin = css`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.size.body1};
  line-height: 1.5;
`;

export const Body2Mixin = css`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.57;
`;

export const Body3Mixin = css`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.67;
`;

export const Body1 = styled.p`
  ${Body1Mixin}
`;

export const Body2 = styled.p`
  ${Body2Mixin}
`;

export const Body3 = styled.p`
  ${Body3Mixin}
`;

export const Emphasized = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const ButtonTextMixin = css`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.5;
`;

export const LinkTextMixin = css`
  font-family: ${({ theme }) => theme.fontFamily.alt};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.42;
  cursor: pointer;

  &,
  &:link,
  &:active,
  &:hover,
  &:visited {
    color: ${({ theme }) => theme.colors.accentMain};
    outline: none;
  }
`;

export const LinkTextSmallMixin = css`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  line-height: 1.57;
  cursor: pointer;

  &,
  &:link,
  &:active,
  &:hover,
  &:visited {
    color: ${({ theme }) => theme.colors.accentSecondary};
  }
`;

export const LabelTextMixin = css`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.69;
`;

export const InputTextMixin = css`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.size.body2};
  line-height: 1.57;
`;

export const InputBoldTextMixin = css`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body1};
  line-height: 1.5;
`;

export const ErrorTextMixin = css`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.danger};
  line-height: 1.3;
`;

export const WarningTextMixin = css`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.warning};
  line-height: 1.3;
`;

export const TooltipMixin = css`
  max-width: 300px;

  h3 {
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: ${({ theme }) => theme.size.body2};
    margin: 0 0 3px 0;
  }
  p {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: ${({ theme }) => theme.size.body2};
    margin: 0;
  }
`;

export const TooltipTitle = styled.p`
  margin-bottom: 14px;
  color: ${({ theme }) => theme.colors.textAlt};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 18px;

  ${breakpoint("xs", "md")`
    margin-bottom: 10px;
  `}
`;

export const TooltipMessage = styled.p`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-bottom: 6px;
  white-space: normal;
`;

export const MultilineOverflowMixin = css`
  overflow: hidden;
  position: relative;
  line-height: 1.15em;
  max-height: 35px;
  margin-right: -1em;
  padding-right: 1em;

  &:before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }
`;

export const FormTitleMixin = css`
  width: 100%;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.alt};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.subtitle};
  color: ${({ theme }) => theme.colors.formTitle};
  margin-top: 12px;
  margin-bottom: 18px;
`;

export const FormTitle = styled.p`
  ${FormTitleMixin}
`;

export const FormSubtitleMixin = css`
  width: 100%;
  text-lign: center;
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.caption};
  margin-bottom: 22px;
`;

export const FormSubtitle = styled.p`
  ${FormSubtitleMixin}
`;

export const FormEmphasisMixin = css`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.caption};
  color: ${({ theme }) => theme.colors.formEmphasis};
  line-height: 24px;
`;

export const FormEmphasis = styled.div`
  ${FormEmphasisMixin}
`;

export const FormSectionTitle = styled(TextWithTooltip)`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.size.title};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.textEmphasized};
  line-height: 1.3;
  margin-bottom: ${labelMarginLarge}px;

  ${breakpoint("xs", "md")`
    font-size: ${({ theme }) => theme.size.subtitle};
  `}

  .__react_component_tooltip {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
`;

export const FormSectionTitleSmall = styled(FormSectionTitle)`
  font-size: ${({ theme }) => theme.size.subtitle};
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormLabel = styled(TextWithTooltip)`
  margin-bottom: ${labelMargin}px;
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.text};
  line-height: 1.5;
`;

export const FormLabelSmall = styled(TextWithTooltip)`
  margin-bottom: ${labelMargin}px;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.text};
  line-height: 1.5;
`;

export const FormLabelLarge = styled(FormLabel)`
  font-size: ${({ theme }) => theme.size.subtitle};
  margin-bottom: ${labelMarginLarger}px;
`;

export const TitleContainer = styled.div``;

export const P = styled(Body3)`
  margin-bottom: 14px;
`;

export const PageTitleWrapper = styled(TitleWrapper)``;

export const PageTitleWrapperMargined = styled(PageTitleWrapper)`
  margin-bottom: 32px;
`;

export const PageTitleLeftContent = styled.div``;
export const PageTitleRightContent = styled.div``;

export const PageTitleMixin = css`
  ${H2Mixin};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.size.titleSemiLargeAlt};

  ${breakpoint("xs", "md")`
    font-size: ${({ theme }) => theme.size.subtitleLarge};
  `}

  ${breakpoint("md", "xlm")`
    font-size: ${({ theme }) => theme.size.titleSemiLarge};
  `}
`;

export const PageTitle = styled(H2)`
  ${PageTitleMixin};
`;

export const PageTitleMargined = styled(PageTitle)`
  margin-bottom: 32px;

  ${breakpoint("xs", "md")`
    margin-bottom: 24px;
  `}

  ${breakpoint("md", "xlm")`
    margin-bottom: 28px;
  `}
`;

export const PageTitleInner = styled.div``;

export const PageTitleAlt = styled(PageTitle)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const PageDescription = styled.p`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.55;
`;

export const PageDescriptionMargined = styled(PageDescription)`
  margin-bottom: 32px;
`;

export const TableLabelMixin = css`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.caption};
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.tableLabel};
`;

export const TableLabel = styled(TextWithTooltip)`
  ${TableLabelMixin}
`;

export const FirstCellMixin = css`
  ${breakpoint("xs")`
    padding-left: ${({ theme }) => theme.contentPadding.xs}px;
  `}
  ${breakpoint("sm")`
    padding-left: ${({ theme }) => theme.contentPadding.sm}px;
  `}
  ${breakpoint("md")`
    padding-left: ${({ theme }) => theme.contentPadding.md}px;
  `}
`;
export const LastCellMixin = css`
  ${breakpoint("xs")`
    padding-right: ${({ theme }) => theme.contentPadding.xs}px;
  `}
  ${breakpoint("sm")`
    padding-right: ${({ theme }) => theme.contentPadding.sm}px;
  `}
  ${breakpoint("md")`
    padding-right: ${({ theme }) => theme.contentPadding.md}px;
  `}
`;

export const CellMixin = css`
  box-sizing: border-box;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.textAlt};
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  &:first-of-type {
    ${FirstCellMixin}
  }
  &:last-of-type {
    ${LastCellMixin}
  }
`;

export const THMixin = css`
  ${CellMixin}
  padding-top: 19px;
  padding-bottom: 19px;
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  ${breakpoint("xs", "md")`
    font-size: ${({ theme }) => theme.size.small};
  `}
`;

export const TDMixin = css`
  ${CellMixin}
`;

export const TH = styled.td`
  ${THMixin}
`;

export const TD = styled.td`
  ${TDMixin}
`;

export const Link = styled(NavLink)`
  ${LinkTextMixin}
`;

export const LinkSmall = styled(NavLink)`
  ${LinkTextSmallMixin}
`;

export const CustomLink = styled.a`
  ${LinkTextMixin}
`;

export const CustomLinkSmall = styled.a`
  ${LinkTextSmallMixin}
`;

export const EmptyCellMixin = css`
  width: 0;
  height: 0;
  max-width: 0;
  min-width: 0;
  visibility: hidden;
  padding: 0 !important;
  margin: 0 !important;

  & > * {
    display: none;
  }
`;

export const TextOverflowMixin = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ErrorText = styled.span`
  ${ErrorTextMixin}
`;

export const WarningText = styled.span`
  ${WarningTextMixin}
`;

export const SectionTitle = styled.div`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.text};
  line-height: 1.3;
  margin-bottom: 24px;

  ${breakpoint("xs", "xlm")`
    font-size: ${({ theme }) => theme.size.body1};
    margin-bottom: 12px;
  `}
`;

export const SectionInnerTitle = styled(SectionTitle)`
  margin-bottom: 12px;

  ${breakpoint("xs", "xlm")`
    font-size: ${({ theme }) => theme.size.body2};
    line-height: 2;
    margin-bottom: 0;
  `}
`;

export const SectionInnerTitleSmall = styled(SectionInnerTitle)`
  font-size: ${({ theme }) => theme.size.body2};
  margin-bottom: 10px;
`;

export const SectionSubtitle = styled(SectionTitle)`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textInactiveAlt2};
  line-height: 1.3;
  margin-bottom: 0;
`;

export const UlTextList = styled.ul`
  list-style-type: disc;
  padding-inline-start: 22px;
`;

export const ListTextItem = styled.li`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.text};
  line-height: 1.57;
`;

//NOTE:::TODO::: Remove pds which are unnecessary for PAID
export const COMPANY_ESIGN_CONSENT_DISCLOSURE = "/assets/pdf/paid_esign_consent_disclosure.pdf";
export const COMPANY_TECHNOLOGY_PLATFORM_AGREEMENT =
  "/assets/pdf/technology_platform_agreement.pdf";
//NOTE:::Paid
export const COMPANY_TERMS_AND_CONDITIONS =
  "/assets/pdf/Paid - Terms and Conditions (Updated Nov 2024).pdf";
export const COMPANY_CONSUMER_ACCOUNT_AGREEMENT =
  "/assets/pdf/Commercial Checking Account Agreement - Keep Financial.pdf";
export const PERSONAL_CONSUMER_ACCOUNT_AGREEMENT =
  "/assets/pdf/Keep - Consumer Checking Account Agreement.pdf";

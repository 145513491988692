import { FC, useState } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { ArrowIconSmall } from "assets/svg";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import { userMetadataSelector } from "store/selectors";

import { EBusinessStructureUserStatus, UserRole } from "types/BETypes";
import { CardForm } from "types/CardShipping";
import { TERMS_AND_CONDITIONS } from "constants/shared";
import { hasEmployeeRights } from "permissions/helpers/shared";
import { getComprehensibleBusinessStructureUserStatus } from "helpers/employee/userStatusMap";
import PermissionContent from "components/PermissionContent";

import { Checkbox, FullSectionLoader } from "uikit";

import { UserResponseDto } from "utils/swagger_react_query";

import {
  BackBtn,
  BtnsWrapper,
  BtnsWrapperOuter,
  SubmitBtn,
} from "../CompanyBankAccountCreationModal/styles";
import { renderErrorMessage } from "../components/renderElements";
import {
  CheckboxTextLink,
  CheckboxWrapper,
  HDescription,
  Header,
  HTitle,
} from "../components/styles";
import { CardholderSelect } from "./components/CardholderSelect";
import {
  CompanyCardShippingDetailsForm,
  CompanyCardShippingDetailsFormType,
  CompanyCardShippingDetailsFormValidationSchema,
} from "./components/CompanyCardShippingDetailsForm";
import SelectCardType from "./components/SelectCardType";
import { userDetailsToShippingDetailsFormInitialValues } from "./helpers";
import { ICompanyCardShippingDetailsProps } from "./types";
import { useCompanyCardShippingDetails } from "./useCompanyCardShippingDetails";
import {
  CardOwnerText,
  CardOwnerTextContainer,
  CardTemplateContainer,
  CardTemplateContainerAdminWithBg,
  CardTemplateIcon,
  CardTemplateSection,
  CardTypeSection,
  Container,
  ContainerInner,
  CTSHDescription,
  CTSHeader,
  CTSHTitle,
  FHDescription,
  FHTitle,
  FormContainer,
  FormContainerInner,
  FormHeader,
  SelectUserContainer,
} from "./styles";

const CompanyCardShippingDetails: FC<ICompanyCardShippingDetailsProps> = ({
  className,
  showCardTemplate,
  hideHeader,
  onBackBtnClick,
  buttonsComponent,
  showCardColderDropdown,
  showCardTypeCheckboxes,
  onSubmit,
  businessOwnersStructure,
  reissueFlow,
  ...rest
}) => {
  const originTranslationPrefix = "components.company_bank_account_components";
  const translationPrefix = `${originTranslationPrefix}.card_shipping_step`;
  const {
    isSubmitting,
    errorMessage,
    getInitValues,
    handleSetCardType,
    handleSetCardholderData,
    handleSubmit,
    selectedCardType,
    selectedCardholderData,
    potentialCardholdersList,
    selectedCardholderExistingCardForms,
    handleSendInviteToCardholder,
    isInviteToCardholderSent,
    isInviteToCardholderSending,
  } = useCompanyCardShippingDetails({ ...rest, reissueFlow, businessOwnersStructure });
  const currentUser = useSelector(userMetadataSelector);
  const [svoc, setSvoc] = useState<boolean>(false);
  const [isCardholderAgreementAccepted, setIsCardholderAgreementAccepted] =
    useState<boolean>(false);

  const isPhysicalCardSelected = selectedCardType?.includes(CardForm.PHYSICAL);
  const isVirtualCardSelected = selectedCardType?.includes(CardForm.VIRTUAL);
  const formSectionClassName = `${isSubmitting ? "loading" : ""}`;
  const comprehensibleBusinessStructureUserStatus = getComprehensibleBusinessStructureUserStatus(
    selectedCardholderData || {},
  );
  const isComprehensibleStatusActive =
    comprehensibleBusinessStructureUserStatus === EBusinessStructureUserStatus.ACTIVE;
  const showCardTypeCheckboxesSection =
    showCardTypeCheckboxes && isComprehensibleStatusActive && !reissueFlow;
  const shouldDisableSubmitBtn = () => {
    if (currentUser?.user?.lastActiveRole === UserRole.EMPLOYEE) {
      return isSubmitting || !isCardholderAgreementAccepted;
    } else {
      if (!isVirtualCardSelected && !isPhysicalCardSelected) return true;
      if (isEmpty(selectedCardholderData)) return true;
      return isSubmitting;
    }
  };
  const showPhysicalCardForm = () => {
    if (reissueFlow) return true;
    if (currentUser?.user?.lastActiveRole === UserRole.EMPLOYEE) return true;

    return isPhysicalCardSelected && isComprehensibleStatusActive;
  };
  const showCardholderAgreementCheckbox = hasEmployeeRights(currentUser);

  const onChangeCardAgreement = () => {
    setIsCardholderAgreementAccepted((prev) => !prev);
  };
  const onChangeCardholder = (
    values: Partial<UserResponseDto>,
    updateFormDataCallback: (values: CompanyCardShippingDetailsFormType) => void,
  ) => {
    handleSetCardholderData(values);
    const serializedFormValues = userDetailsToShippingDetailsFormInitialValues(values);
    updateFormDataCallback(serializedFormValues);
  };

  const onSetCardType = (cardType: CardForm) => {
    handleSetCardType(cardType);
  };

  const renderAdditionalAdminFormHeader = (
    updateFormDataCallback: (values: CompanyCardShippingDetailsFormType) => void,
  ) => {
    return (
      <FormHeader>
        {showCardColderDropdown && potentialCardholdersList && (
          <SelectUserContainer>
            <FHTitle>
              <Trans
                i18nKey={`${translationPrefix}.shipping_details.admin_form_header.user_select.title`}
              />
            </FHTitle>

            <CardholderSelect
              onChange={(e) => onChangeCardholder(e, updateFormDataCallback)}
              potentialCardholdersList={potentialCardholdersList}
              selectedCardholderData={selectedCardholderData}
              onSentInvite={handleSendInviteToCardholder}
              isInviteSent={isInviteToCardholderSent}
              isInviteSending={isInviteToCardholderSending}
            />
          </SelectUserContainer>
        )}

        {showCardTypeCheckboxesSection && (
          <CardTypeSection>
            <SelectCardType
              onSelectedCardForm={onSetCardType}
              selectedCardForms={selectedCardType}
              existingCardFormsList={selectedCardholderExistingCardForms}
            />
          </CardTypeSection>
        )}

        {showPhysicalCardForm() && (
          <>
            <FHTitle>
              <Trans
                i18nKey={`${translationPrefix}.shipping_details.admin_form_header.card_type.title`}
              />
            </FHTitle>
            <FHDescription>
              <Trans
                i18nKey={`${translationPrefix}.shipping_details.admin_form_header.card_type.description`}
              />
            </FHDescription>
          </>
        )}
      </FormHeader>
    );
  };
  const ButtonsComponent = buttonsComponent?.({
    isCardholderAgreementAccepted,
    onBackBtnClick,
    backIsDisabled: isSubmitting,
    submitIsDisabled: shouldDisableSubmitBtn(),
  });

  return (
    <Formik<CompanyCardShippingDetailsFormType>
      onSubmit={() => {}}
      initialValues={getInitValues()}
      validateOnChange={svoc}
      validationSchema={CompanyCardShippingDetailsFormValidationSchema}
    >
      {(formikProps) => (
        <Container
          className={className}
          onSubmit={(e) => {
            const { values, validateForm } = formikProps;
            e.preventDefault();
            setSvoc(true);
            if (onSubmit) {
              onSubmit(formikProps);
            } else {
              handleSubmit?.({ values, validateForm });
            }
          }}
        >
          {!hideHeader && (
            <Header>
              <HTitle>
                <Trans i18nKey={`${translationPrefix}.title`} />
              </HTitle>
              <HDescription>
                <PermissionContent
                  roles={[UserRole.EMPLOYEE]}
                  elseContent={<Trans i18nKey={`${translationPrefix}.description.admin`} />}
                >
                  <Trans i18nKey={`${translationPrefix}.description.employee`} />
                </PermissionContent>
              </HDescription>
            </Header>
          )}

          {renderErrorMessage(errorMessage)}

          <ContainerInner>
            <FormContainer className={formSectionClassName}>
              {!reissueFlow && (
                <PermissionContent
                  roles={[UserRole.EMPLOYEE]}
                  elseContent={renderAdditionalAdminFormHeader(formikProps.setValues)}
                />
              )}
              {isSubmitting && <FullSectionLoader />}
              {showPhysicalCardForm() && (
                <FormContainerInner>
                  <CompanyCardShippingDetailsForm />
                </FormContainerInner>
              )}

              {showCardholderAgreementCheckbox && (
                <CheckboxWrapper>
                  <Checkbox
                    checked={isCardholderAgreementAccepted}
                    onCheck={onChangeCardAgreement}
                    onUncheck={onChangeCardAgreement}
                    text={
                      <Trans
                        i18nKey={`${originTranslationPrefix}.card_shipping_step.shipping_details.cardholder_agreement`}
                        components={{
                          1: (
                            <CheckboxTextLink
                              href={TERMS_AND_CONDITIONS}
                              onClick={(e) => e.stopPropagation()}
                              data-testid="terms-and-conditions-link"
                              target="_blank"
                            />
                          ),
                        }}
                      />
                    }
                    data-testid="company-shipping-details-agreement-checkbox"
                  ></Checkbox>
                </CheckboxWrapper>
              )}
            </FormContainer>

            {showCardTemplate && (
              <CardTemplateSection>
                <PermissionContent
                  roles={[UserRole.EMPLOYEE]}
                  elseContent={
                    <CTSHeader>
                      <CTSHTitle>
                        <Trans i18nKey={`${translationPrefix}.card_template_section_admin.title`} />
                      </CTSHTitle>
                      <CTSHDescription>
                        <Trans
                          i18nKey={`${translationPrefix}.card_template_section_admin.description`}
                        />
                      </CTSHDescription>
                    </CTSHeader>
                  }
                >
                  <CTSHeader>
                    <CTSHTitle>
                      <Trans i18nKey={`${translationPrefix}.card_template_section.title`} />
                    </CTSHTitle>
                    <CTSHDescription>
                      <Trans i18nKey={`${translationPrefix}.card_template_section.description`} />
                    </CTSHDescription>
                  </CTSHeader>
                </PermissionContent>

                <PermissionContent
                  roles={[UserRole.EMPLOYEE]}
                  elseContent={
                    <CardTemplateContainerAdminWithBg>
                      <CardOwnerTextContainer>
                        <CardOwnerText>{selectedCardholderData?.firstName || ""}</CardOwnerText>
                        <CardOwnerText>{selectedCardholderData?.lastName || ""}</CardOwnerText>
                      </CardOwnerTextContainer>
                    </CardTemplateContainerAdminWithBg>
                  }
                >
                  <CardTemplateContainer>
                    <CardTemplateIcon />

                    <CardOwnerTextContainer>
                      <CardOwnerText>{formikProps.initialValues?.firstName || ""}</CardOwnerText>
                      <CardOwnerText>{formikProps.initialValues?.lastName || ""}</CardOwnerText>
                    </CardOwnerTextContainer>
                  </CardTemplateContainer>
                </PermissionContent>
              </CardTemplateSection>
            )}
          </ContainerInner>

          {buttonsComponent ? (
            <BtnsWrapperOuter>{ButtonsComponent}</BtnsWrapperOuter>
          ) : (
            <BtnsWrapperOuter>
              <BtnsWrapper>
                <BackBtn
                  onClick={onBackBtnClick}
                  type={"button"}
                  disabled={isSubmitting}
                  id={"cbacm-shipping-form-cancel-button"}
                  data-testid={"cbacm-shipping-form-cancel-button"}
                >
                  <Trans i18nKey="buttons.cancel" />
                </BackBtn>

                <SubmitBtn
                  type={"submit"}
                  disabled={shouldDisableSubmitBtn()}
                  id={"cbacm-shipping-form-submit-button"}
                  data-testid={"cbacm-shipping-form-submit-button"}
                >
                  <Trans i18nKey={"buttons.submit"} />
                  <ArrowIconSmall />
                </SubmitBtn>
              </BtnsWrapper>
            </BtnsWrapperOuter>
          )}
        </Container>
      )}
    </Formik>
  );
};

export default CompanyCardShippingDetails;

import { FC, FormEvent } from "react";
import { Trans } from "react-i18next";
import { Formik, FormikProps } from "formik";

import { HELP_CENTER } from "constants/shared";
import AuthLayout from "layouts/AuthLayout";
import { PasswordFormSchemasType, PasswordFormType } from "components/auth";
import PasswordForm from "components/auth/PasswordForm/PasswordForm";

import { FullScreenLoader, IndicatorBase } from "uikit";
import { Step } from "uikit/StepIndicator/types";

import { ContactDetails, ContactDetailsFormType } from "../_shared";
import {
  Footer,
  FooterLinkExternal,
  StyledPrimaryBtn,
  TabsContainer,
} from "../_shared/components/styles";
import { adminSignUpStepsArray } from "./constants";
import { AdminSignUpSteps } from "./types";
import { useAdminSignUpPage } from "./useAdminSignUpPage";
import { AdminSignUpSchemaFormType } from "./validationSchema";
import { Container } from "./styles";

const AdminSignUpPage: FC = () => {
  const translationPrefixCommon = `auth_pages`;
  const { metadata, actions } = useAdminSignUpPage();
  const isSignUpCompletedWithoutPassword = metadata.isSetupCompletedWithoutPassword();
  const shouldShowTabs = !isSignUpCompletedWithoutPassword;

  const steps: Step[] = adminSignUpStepsArray.map((item, index) => ({
    index: index,
  }));

  const handleFormSubmit = (
    e: FormEvent<HTMLFormElement>,
    formikProps: FormikProps<ContactDetailsFormType | PasswordFormSchemasType["CREATE_PASSWORD"]>,
  ) => {
    e.preventDefault();
    if (metadata.currentStep === AdminSignUpSteps.SET_PASSWORD) {
      actions.handleSetPassword(
        formikProps as FormikProps<PasswordFormSchemasType["CREATE_PASSWORD"]>,
      );
    }
    if (metadata.currentStep === AdminSignUpSteps.CONTACT_DETAILS) {
      actions.handleSubmitPersonalDetails(formikProps as FormikProps<ContactDetailsFormType>);
    }
  };

  const getPrimaryBtnText = () => {
    if (metadata.currentStep === AdminSignUpSteps.SET_PASSWORD)
      return `${translationPrefixCommon}.shared.components.password_form.button_labels.set_password`;
    return `buttons.submit`;
  };

  if (metadata.pageLoader) return <FullScreenLoader />;
  return (
    <AuthLayout
      isLoading={metadata.isLoading}
      mainSectionTopContent={
        shouldShowTabs && (
          <TabsContainer>
            <IndicatorBase
              steps={steps}
              currentStep={adminSignUpStepsArray.indexOf(metadata.currentStep)}
            />
          </TabsContainer>
        )
      }
    >
      <Formik<AdminSignUpSchemaFormType[typeof metadata.currentStep]>
        validationSchema={metadata.getValidationSchema()}
        initialValues={metadata.getInitFormValues()}
        validateOnChange={metadata.svoc}
        onSubmit={() => {}}
        enableReinitialize
      >
        {(formikProps) => (
          <Container onSubmit={(e) => handleFormSubmit(e, formikProps)}>
            {metadata.currentStep === AdminSignUpSteps.SET_PASSWORD && (
              <PasswordForm type={PasswordFormType.CREATE_PASSWORD} />
            )}
            {metadata.currentStep === AdminSignUpSteps.CONTACT_DETAILS && (
              <ContactDetails
                values={
                  (formikProps as FormikProps<AdminSignUpSchemaFormType["CONTACT_DETAILS"]>).values
                }
                errors={
                  (formikProps as FormikProps<AdminSignUpSchemaFormType["CONTACT_DETAILS"]>).errors
                }
                handleChange={formikProps.handleChange}
                setFieldValue={async (field: string, value: any) => {
                  formikProps.setFieldValue(field, value);
                  return;
                }}
              />
            )}

            <StyledPrimaryBtn type="submit" data-testid="primary-btn">
              <Trans i18nKey={getPrimaryBtnText()} />
            </StyledPrimaryBtn>

            <Footer>
              <FooterLinkExternal href={HELP_CENTER} target="_blank">
                <Trans i18nKey={`buttons.need_help_btn`} />
              </FooterLinkExternal>
            </Footer>
          </Container>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default AdminSignUpPage;

import { FC } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { Trans } from "react-i18next";
import moment from "moment";

import { EMagicLinkFlow } from "types/AuthFlowTypes";
import { HELP_CENTER, RESEND_EMAIL_TIMEOUT } from "constants/shared";
import { getActionTimeout } from "helpers";
import AuthLayout from "layouts/AuthLayout";
import MailAppButtons from "components/MailAppButtons";

import {
  Description,
  ECLabel,
  EmailAppsSection,
  EmailContainer,
  Footer,
  FooterLinkExternal,
  Section,
  StyledEmailWithIcon,
  Title,
} from "../_shared/components/styles";
import { authAdditionalSectionParams } from "../_shared/constants";
import { useCheckEmailPage } from "./useCheckEmailPage";
import { Container, ResendEmailButton, TimeDownContainer, TimeDownText } from "./styles";

const CheckEmailPage: FC = () => {
  const translationPrefix = "auth_pages.shared.check_email_page";

  const { metadata, actions } = useCheckEmailPage();
  const shouldShowAdditionalLayoutSection = metadata.flow === EMagicLinkFlow.SIGN_UP;
  //NOTE:::For auto tests
  const timeout = getActionTimeout(RESEND_EMAIL_TIMEOUT, true);

  const renderer = ({ hours, minutes, seconds, completed }: CountdownRenderProps) => {
    return (
      <TimeDownContainer>
        <TimeDownText>
          <Trans i18nKey={`${translationPrefix}.instructions`} />
        </TimeDownText>

        <ResendEmailButton
          disabled={!completed}
          onClick={() => actions.onResendEmail()}
          data-testid="resend-email-btn"
        >
          <Trans
            i18nKey={`${translationPrefix}.resend_email`}
            values={{
              time: !completed
                ? `(${moment().set({ hours, minutes, seconds }).format("ss")}s)`
                : "",
            }}
          />
        </ResendEmailButton>
      </TimeDownContainer>
    );
  };

  return (
    <AuthLayout
      isLoading={metadata.isLoading}
      additionalSection={
        shouldShowAdditionalLayoutSection ? authAdditionalSectionParams() : undefined
      }
    >
      <Container>
        <Title>
          <Trans i18nKey={`${translationPrefix}.title`} />
        </Title>

        <Description>
          <Trans i18nKey={`${translationPrefix}.description`} />
        </Description>

        <Section>
          <EmailContainer>
            <ECLabel>
              <Trans i18nKey={`${translationPrefix}.email_label`} />
            </ECLabel>
            <StyledEmailWithIcon email={metadata?.email || ""} />
          </EmailContainer>

          <EmailAppsSection>
            <MailAppButtons showAppNameAsTitle />
          </EmailAppsSection>
        </Section>

        {!metadata.isLoading && !!metadata?.email && (
          <Countdown date={timeout} renderer={renderer} autoStart />
        )}

        <Footer>
          <FooterLinkExternal href={HELP_CENTER} target="_blank">
            <Trans i18nKey={`buttons.need_help_btn`} />
          </FooterLinkExternal>
        </Footer>
      </Container>
    </AuthLayout>
  );
};

export default CheckEmailPage;

import { useCallback, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { generatePath, useNavigate } from "react-router";
import { EditIcon } from "assets/svg";
import MailUpIcon from "assets/svg/MailUpIcon";
import { debounce } from "lodash";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { showErrorModal } from "helpers";
import {
  EComprehensibleUserStatuses,
  getComprehensibleStatus,
} from "helpers/employee/userStatusMap";
import ListEmployeeInfo from "components/ListEmployeeInfo";
import { UserPaymentReadinessStatus, UserStatus } from "components/statuses";

import { ContextMenu } from "uikit";
import { EColumnType, IColumn } from "uikit/Table/types";

import {
  mutationEmployeesControllerListEmployeesByFilterLite,
  UserLiteSearchResponseDto,
} from "utils/swagger_react_query";

import { WorkerContextMenuActionType } from "../WorkerActions/types";
import useWorkerActions from "../WorkerActions/useWorkerActions";
import { ETableVariants, IProps } from "./types";
import { EditWorkerIconButtonContainer, ResendInvitationIconButtonContainer } from "./styles";

const useWorkersList = (props: IProps) => {
  const { onListChange, perPage = 20 } = props;

  const navigate = useNavigate();
  const currentUser = useAppSelector(userMetadataSelector);

  const [isTableLoading, setTableLoading] = useState<boolean>(false);

  //NOTE:::PAGE DATA
  const [data, setData] = useState<UserLiteSearchResponseDto[]>([]);

  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>();

  const translationPrefix = `people_page.index`;

  const redirectToWorkerDetails = (data: UserLiteSearchResponseDto) => {
    if (!data) return;
    navigate(
      generatePath(routes.PEOPLE_WORKER_DETAILS, {
        id: data.userId || "",
      }),
    );

    /* TODO:::    Uncomment when tab duplication bug is fixed
    window.open(
    generatePath(routes.PEOPLE_WORKER_DETAILS, {
      workerId: data.user?.userId || "",
    }),
      "_blank",
    ); */
  };

  const fetchEmployeesList = useCallback(
    debounce(async (page?: number, searchValue?: string) => {
      try {
        setTableLoading(true);
        const employeesListRes = await mutationEmployeesControllerListEmployeesByFilterLite({
          page: (page || 1)?.toString(),
          perPage: perPage.toString(),
        })({
          searchString: searchValue || "",
        });

        setData(employeesListRes.users);
        setTotalCount(employeesListRes.total);
      } catch (error) {
        showErrorModal(error);
      } finally {
        setTableLoading(false);
      }
    }, 500),
    [],
  );

  const {
    editedUser,
    isLoading,
    isAddWorkerFormOpen,
    disabledResends,
    getContextMenuOptions,
    setLoading,
    handleOpenCsvImportModal,
    openAddWorkerForm,
    closeAddWorkerForm,
    handleAddWorker,
    handleSaveEditedWorker,
    handleCancelAddWorkerForm,
    renderWorkerActionsElements,
    onPaymentReadinessStatusClick,
  } = useWorkerActions({
    onAddWorker: () => fetchEmployeesList(page, searchValue),
    onEditWorker: () => fetchEmployeesList(page, searchValue),
    onDeleteWorker: () => fetchEmployeesList(page, searchValue),
    onInviteWorker: () => fetchEmployeesList(page, searchValue),
  });

  const renderContextMenuColumn = (row: UserLiteSearchResponseDto) => {
    const menuItems = getContextMenuOptions(row);
    const shouldShowContextMenu =
      row?.status && getComprehensibleStatus(row) === EComprehensibleUserStatuses.PROSPECT;

    const getAction = () => {
      const type = menuItems?.[0]?.value;
      const callback = menuItems.find((item) => item?.value === type)?.onClick;
      switch (type) {
        case WorkerContextMenuActionType.ON_EDIT_WORKER:
          return (
            <EditWorkerIconButtonContainer onClick={callback} data-testid="edit-worker-icon-button">
              <EditIcon />
            </EditWorkerIconButtonContainer>
          );
        case WorkerContextMenuActionType.RESEND_INVITE: {
          const resendItem = menuItems.find(
            (item) => item?.value === WorkerContextMenuActionType.RESEND_INVITE,
          );
          if (resendItem) {
            resendItem.disabled = !!disabledResends.find((item) => item.id === row.userId);
          }

          return (
            <ResendInvitationIconButtonContainer
              onClick={callback}
              aria-disabled={resendItem?.disabled}
              data-testid="resend-invite-icon-button"
            >
              <MailUpIcon />
            </ResendInvitationIconButtonContainer>
          );
        }
        default:
          break;
      }
    };

    if (shouldShowContextMenu) return <ContextMenu options={menuItems} />;

    return getAction();
  };

  const columnsSet = {
    userInfo: {
      key: "userInfo",
      title: <Trans i18nKey={`${translationPrefix}.table.columns.user_info`} />,
      className: "userInfo",
      type: EColumnType.Component,
      component: (row: UserLiteSearchResponseDto) => {
        return (
          <ListEmployeeInfo
            id={row.userId}
            firstName={row.firstName}
            lastName={row.lastName}
            avatar={row.avatarUrl}
            onClick={() => redirectToWorkerDetails(row)}
            data-testid=""
          />
        );
      },
    },
    status: {
      key: "status",
      title: <Trans i18nKey={`${translationPrefix}.table.columns.status`} />,
      className: "status",
      type: EColumnType.Component,
      component: (row: UserLiteSearchResponseDto) => {
        return <UserStatus data={row} withBg={false} />;
      },
    },
    contextMenu: {
      key: "contextMenu",
      title: <Trans i18nKey={`${translationPrefix}.table.columns.contextMenu`} />,
      className: "contextMenu",
      type: EColumnType.Component,
      component: renderContextMenuColumn,
    },
    email: {
      key: "email",
      title: <Trans i18nKey={`${translationPrefix}.table.columns.email`} />,
      className: "email",
      type: EColumnType.Component,
      component: (row: UserLiteSearchResponseDto) => <>{row.email}</>,
    },
    paymentReadinessStatus: {
      key: "paymentReadinessStatus",
      title: <Trans i18nKey={`${translationPrefix}.table.columns.payment_readiness_status`} />,
      className: "paymentReadinessStatus",
      type: EColumnType.Component,
      component: (row: UserLiteSearchResponseDto) => {
        const onClickCallback = onPaymentReadinessStatusClick(row);
        return (
          <UserPaymentReadinessStatus
            data={row}
            onClick={onClickCallback ? () => onClickCallback() : undefined}
            data-testid="user-payment-readiness-status"
          />
        );
      },
    },
    employmentType: {
      key: "employmentType",
      title: <Trans i18nKey={`${translationPrefix}.table.columns.employment_type`} />,
      className: "employmentType",
      type: EColumnType.Component,
      component: (row: UserLiteSearchResponseDto) => (
        <>
          {row.employmentType ? (
            <Trans i18nKey={`common.worker_employment_type_short.${row.employmentType}`} />
          ) : (
            ""
          )}
        </>
      ),
    },
  };

  const getColumns = (type: ETableVariants): IColumn[] => {
    const { userInfo, status, contextMenu, email, employmentType, paymentReadinessStatus } =
      columnsSet;
    switch (type) {
      case ETableVariants.ONBOARDING_LIST:
        return [userInfo, email, employmentType, status, contextMenu];
      case ETableVariants.PEOPLE_LIST:
        return [userInfo, employmentType, status, paymentReadinessStatus, contextMenu];
      default:
        return [];
    }
  };

  const handleSearch = (value: string) => {
    setPage(1);
    setSearchValue(value);
  };

  useEffect(() => {
    if (!currentUser) return;
    fetchEmployeesList(page, searchValue);
  }, [page, searchValue]);

  useEffect(() => {
    setData([]);
  }, []);

  useEffect(() => {
    onListChange?.(data);
  }, [data]);

  return {
    data,
    currentUser,
    editedUser,
    isLoading,
    isTableLoading,
    isAddWorkerFormOpen,
    paginationProps: {
      page,
      perPage,
      searchValue,
      totalCount,
      onSearch: handleSearch,
      onPageChange: setPage,
    },
    setLoading,
    getColumns,
    handleOpenCsvImportModal,
    openAddWorkerForm,
    closeAddWorkerForm,
    handleAddWorker,
    handleSaveEditedWorker,
    handleCancelAddWorkerForm,
    renderWorkerActionsElements,
    fetchEmployeesList,
  };
};

export default useWorkersList;

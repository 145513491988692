import { UserRole } from "types/BETypes";

import { ExtendedUserProfileResponseDto, UserProfileResponseDto } from "utils/swagger_react_query";

export const pageOpenedAnalytics = (userData?: UserProfileResponseDto | null | undefined) => {
  const userDataPayload: Pick<
    ExtendedUserProfileResponseDto,
    "userId" | "firstName" | "lastName" | "companyId" | "lastActiveRole"
  > = {
    userId: userData?.user?.userId || "",
    firstName: userData?.user?.firstName || "",
    lastName: userData?.user?.lastName || "",
    companyId: userData?.user?.companyId || "",
    lastActiveRole: userData?.user?.lastActiveRole as UserRole,
  };
  const payload: Record<string, unknown>[] = [];
  if (userData?.user) {
    payload.push(
      { userData: userDataPayload },
      {
        userId: userData?.user?.userId || "",
      },
    );
  }

  const analytics = (window as any).analytics;
  analytics?.page(...payload);
};

import { CustomFont, CustomTheme } from "@easyteam/core-ui";
import { theme } from "styles/theme";

import { easyTeamColors } from "./colors";

export const easyTeamFonts: CustomFont = {
  regular: theme.fontFamily.default,
  bold: theme.fontFamily.default,
  semiBold: theme.fontFamily.default,
};

export const easyTeamTheme: CustomTheme = {
  tokens: easyTeamColors,
  button: {
    default: {
      fontSize: theme.size.body2,
    },
    primary: {
      fontSize: theme.size.body2,
    },
    secondary: {
      fontSize: theme.size.body2,
      _hover: {
        backgroundColor: "secondary.dark",
        color: "secondary.accent.dark",
      },
      _active: {
        backgroundColor: "secondary.darker",
        color: "secondary.accent.darker",
      },
    },
    destructive: {
      fontSize: theme.size.body2,
      _hover: {
        backgroundColor: "delete.hover",
        color: "delete.hoverAccent",
      },
      _active: {
        backgroundColor: "delete.active",
        color: "delete.activeAccent",
      },
    },
  },
  dateSelector: {
    dateSelectorPill: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "border.main",
    },
    dateSelectorPillIcon: {
      width: "16px",
      height: "16px",
    },
    dateSelectorPillText: {
      fontSize: theme.size.body2,
      fontWeight: theme.fontWeight.regular,
    },
  },
  employeeSelector: {
    employeeSelectorPill: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "border.main",
    },
    employeeSelectorAvatar: {
      width: "24px",
      height: "24px",
      borderRadius: "50%",
    },
    employeeSelectorName: {
      fontSize: theme.size.body2,
      fontWeight: theme.fontWeight.regular,
    },
  },
  table: {
    tableContainer: {
      borderStyle: "solid",
      borderWidth: "1px",
      borderRadius: "8px",
      borderColor: "border.main",
    },
    tableRow: {
      borderWidth: "0 0 1px 0",
      borderColor: "border.main",
      borderStyle: "solid",
      borderRadius: 0,
      padding: "16px 8px",
    },
    tableHeaderValue: {
      fontWeight: theme.fontWeight.semiBold,
    },
    tableRowValue: {
      fontSize: theme.size.body2,
      fontWeight: theme.fontWeight.semiBold,
    },
  },
  employeesList: {
    titleMain: {
      fontSize: theme.size.titleSemiLargeAlt,
      fontWeight: theme.fontWeight.semiBold,
    },
    employeeListAvatar: {
      borderRadius: "50%",
    },
  },
  timesheet: {
    timesheetsActionMenuWrapper: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "border.main",
      borderRadius: "8px",
    },
  },
  screen: {
    title: {
      fontSize: theme.size.titleSemiLargeAlt,
      fontWeight: theme.fontWeight.semiBold,
    },
  },
  shiftForm: {
    shiftSingle: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "border.main",
    },
    dateSelectorWrapper: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "border.main",
      borderRadius: "8px",
    },
    selectorWrapper: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "border.main",
      borderRadius: "8px",
    },
    shiftDuration: {},
    shiftSummaryBoxTitle: {
      fontSize: theme.size.body1,
      fontWeight: theme.fontWeight.semiBold,
    },
    shiftSummaryColumnTitle: {
      fontSize: theme.size.caption,
      fontWeight: theme.fontWeight.regular,
    },
    shiftSummaryColumnValue: {
      fontSize: theme.size.body2,
      fontWeight: theme.fontWeight.regular,
    },
    shiftDeleteButton: {
      backgroundColor: "delete.main",
      color: "delete.accent",
      _hover: {
        backgroundColor: "delete.hover",
        color: "delete.hoverAccent",
      },
      _active: {
        backgroundColor: "delete.active",
        color: "delete.activeAccent",
      },
    },
  },
  settings: {
    teamAvatar: {
      borderRadius: "50%",
    },
    breakWrapper: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "border.main",
      borderRadius: "8px",
    },
    breakSelectorWrapper: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "border.main",
      borderRadius: "8px",
    },
    breakSelectorTitle: {
      fontSize: theme.size.body1,
      fontWeight: theme.fontWeight.semiBold,
    },
    breakSelectorValue: {
      fontSize: theme.size.body2,
    },
    teamName: {
      fontSize: theme.size.body2,
    },
  },
};

export type IResetPasswordPageParams = {
  token?: string;
};

export enum ResetPasswordStep {
  SEND_EMAIL = "SEND_EMAIL",
  RESET_PASSWORD = "RESET_PASSWORD",
}

export interface ResetPasswordPageLocationState {
  email?: string;
  redirectedFromSettings?: boolean;
}

import { FC, FormEvent, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Formik, FormikProps } from "formik";

import { EmailInput } from "pages/Auth/_shared/components/AuthForm/styles";
import {
  Description,
  ECLabel,
  ECText,
  EmailAppsSection,
  EmailContainer,
  FormItem,
  Section,
  StyledEmailWithIcon,
  StyledPrimaryBtn,
  Title,
} from "pages/Auth/_shared/components/styles";
import MailAppButtons from "components/MailAppButtons";

import { FormLabel } from "uikit";

import {
  SendResetPasswordEmailFormSchemaType,
  sendResetPasswordEmailFormValidationSchema,
} from "./validationSchema";
import { Container, FormContainerInner } from "./styles";

interface IProps {
  handleSubmit: (
    formikProps: FormikProps<SendResetPasswordEmailFormSchemaType>,
    onEmailSent: (value: boolean) => void,
  ) => void;
  initValues: SendResetPasswordEmailFormSchemaType;
}

export const SendResetPasswordEmailForm: FC<IProps> = ({ handleSubmit, initValues }) => {
  const { t } = useTranslation();
  const translationPrefix = `auth_pages.shared.reset_password_page.send_email_form`;
  const [svoc, setSvoc] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);

  const onSubmit = (
    e: FormEvent<HTMLFormElement>,
    formikProps: FormikProps<SendResetPasswordEmailFormSchemaType>,
  ) => {
    e.preventDefault();
    setSvoc(true);
    handleSubmit(formikProps, setEmailSent);
  };

  return (
    <Container>
      <Title>
        <Trans i18nKey={`${translationPrefix}.title`} />
      </Title>
      <Description>
        <Trans i18nKey={`${translationPrefix}.description`} />
      </Description>

      <Formik<SendResetPasswordEmailFormSchemaType>
        initialValues={initValues}
        validationSchema={sendResetPasswordEmailFormValidationSchema}
        validateOnChange={svoc}
        onSubmit={() => {}}
        enableReinitialize
      >
        {(formikProps) => {
          const { errors, values, handleChange } = formikProps;
          return emailSent ? (
            <Section>
              <EmailContainer>
                <ECLabel>
                  <Trans i18nKey={`${translationPrefix}.email_sent_message`} />
                </ECLabel>
                <StyledEmailWithIcon email={values?.email || ""} />
                <ECText>
                  <Trans i18nKey={`${translationPrefix}.link_valid_for`} />
                </ECText>
              </EmailContainer>

              <EmailAppsSection>
                <MailAppButtons showAppNameAsTitle />
              </EmailAppsSection>
            </Section>
          ) : (
            <FormContainerInner onSubmit={(e) => onSubmit(e, formikProps)}>
              <FormItem>
                <FormLabel>
                  <Trans i18nKey={`${translationPrefix}.email_field.title`} />
                </FormLabel>
                <EmailInput
                  tabIndex={1}
                  name={`email`}
                  placeholder={t(`common.input.placeholder_base`, {
                    field: t(`${translationPrefix}.email_field.title`),
                  })}
                  value={values.email}
                  onChange={(e) => {
                    e.currentTarget.value = e.currentTarget.value.trim();
                    handleChange(e);
                  }}
                  error={
                    errors?.email && (
                      <Trans
                        i18nKey={errors.email}
                        values={{ field: t(`${translationPrefix}.email_field.title`) }}
                      />
                    )
                  }
                  errorDataTestId="error-test-id"
                />
              </FormItem>

              <StyledPrimaryBtn
                type="submit"
                tabIndex={2}
                data-testid="send-reset-password-email-button"
              >
                <Trans i18nKey={`${translationPrefix}.primary_btn`} />
              </StyledPrimaryBtn>
            </FormContainerInner>
          );
        }}
      </Formik>
    </Container>
  );
};

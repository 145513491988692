import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";
import { get } from "lodash";
import routes from "routes/routes";

import useAuth from "hooks/useAuth";

import { CustomLink, FormLabelSmall, ListTextItem, PasswordInput, UlTextList } from "uikit";

import { PASSWORD_REQUIREMENTS_LIST_NUMBER } from "./constants";
import { IPasswordFormProps, PasswordFormType } from "./types";
import { PasswordFormSchemasType } from "./validationSchema";
import {
  Container,
  Description,
  StyledInputContainer,
  StyledWarningSection,
  Title,
} from "./styles";

const PasswordForm: FC<IPasswordFormProps> = ({
  type,
  fieldPrefix,
  resetPasswordLinkLocationState,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout, isAuthenticated } = useAuth();
  const translationPrefix = `auth_pages.shared.components.password_form`;
  const inputLabelTranslationPrefix = `${translationPrefix}.input_labels`;
  const { values, errors, setFieldValue } =
    useFormikContext<PasswordFormSchemasType[typeof type]>();
  const isCreateOrResetPassword = [
    PasswordFormType.CREATE_PASSWORD,
    PasswordFormType.RESET_PASSWORD,
  ].includes(type);
  const shouldShowTitle = isCreateOrResetPassword;
  const shouldShowDescription = type === PasswordFormType.CREATE_PASSWORD;

  const onForgotPasswordLinkClick = async () => {
    if (isAuthenticated()) {
      await logout(true, false);
    }
    navigate(routes.RESET_PASSWORD, { state: resetPasswordLinkLocationState });
  };

  const getFieldName = (fieldName: string): string =>
    fieldPrefix ? `${fieldPrefix}.${fieldName}` : fieldName;
  const getFieldData = (data: object, fieldName: string): any =>
    get(data, getFieldName(fieldName), "");

  const renderCurrentPasswordInputField = () => {
    if (isCreateOrResetPassword) return;

    const fieldName = getFieldName("password") as keyof PasswordFormSchemasType[typeof type];
    const fieldValue = getFieldData(values, "password");
    const fieldError = getFieldData(errors, "password");
    let label: string = "";

    if (type === PasswordFormType.SIGN_IN) {
      label = `${inputLabelTranslationPrefix}.password`;
    } else if (type === PasswordFormType.CHANGE_PASSWORD) {
      label = `${inputLabelTranslationPrefix}.current_password`;
    }

    return (
      <StyledInputContainer>
        <FormLabelSmall>
          <Trans i18nKey={label} />
        </FormLabelSmall>
        <PasswordInput
          name="current_password"
          autoComplete="current-password"
          value={fieldValue}
          onChange={(v) => setFieldValue(fieldName, v)}
          data-testid="current_password"
          errorDataTestId="current_password_error"
          error={
            fieldError && (
              <Trans
                i18nKey={fieldError}
                values={{ field: t(`${inputLabelTranslationPrefix}.password`) }}
              />
            )
          }
          alwaysShowErrorBlock={false}
        />
        <CustomLink onClick={onForgotPasswordLinkClick} data-testid="forgot_password-_ink">
          <Trans i18nKey={`${translationPrefix}.forgot_password_link.${type}`} />
        </CustomLink>
      </StyledInputContainer>
    );
  };

  const renderNewPasswordField = () => {
    if (type === PasswordFormType.SIGN_IN) return;
    const fieldName = getFieldName("newPassword") as keyof PasswordFormSchemasType[typeof type];
    const fieldValue = getFieldData(values, "newPassword");
    const fieldError = getFieldData(errors, "newPassword");
    const shouldShowPasswordRequirements = fieldError;

    let label: string = "";
    if (type === PasswordFormType.CHANGE_PASSWORD) {
      label = `${inputLabelTranslationPrefix}.new_password`;
    } else if (isCreateOrResetPassword) {
      label = `${inputLabelTranslationPrefix}.enter_password`;
    }
    const passwordRequirementsArray = Array.from(
      { length: PASSWORD_REQUIREMENTS_LIST_NUMBER },
      (_, i) => `${translationPrefix}.password_requirements.${i}`,
    );

    return (
      <>
        <StyledInputContainer>
          {shouldShowPasswordRequirements && (
            <StyledWarningSection showIcon={false} alt>
              <UlTextList>
                {passwordRequirementsArray.map((item, index) => (
                  <ListTextItem key={index.toString()}>
                    <Trans i18nKey={item} />
                  </ListTextItem>
                ))}
              </UlTextList>
            </StyledWarningSection>
          )}
          <FormLabelSmall>
            <Trans i18nKey={label} />
          </FormLabelSmall>
          <PasswordInput
            name="new-password"
            autoComplete="new-password"
            value={fieldValue}
            onChange={(v) => setFieldValue(fieldName, v)}
            data-testid="new_password"
            errorDataTestId="new_password_error"
            error={
              fieldError && (
                <Trans
                  i18nKey={fieldError}
                  values={{ field: t(`${inputLabelTranslationPrefix}.password`) }}
                />
              )
            }
            showPasswordStrength
            alwaysShowErrorBlock={false}
          />
        </StyledInputContainer>
      </>
    );
  };

  const renderConfirmPasswordField = () => {
    if (type === PasswordFormType.SIGN_IN) return;
    const fieldName = getFieldName("confirmPassword") as keyof PasswordFormSchemasType[typeof type];
    const fieldValue = getFieldData(values, "confirmPassword");
    const fieldError = getFieldData(errors, "confirmPassword");

    const label = `${inputLabelTranslationPrefix}.confirm_password`;

    return (
      <StyledInputContainer>
        <FormLabelSmall>
          <Trans i18nKey={label} />
        </FormLabelSmall>
        <PasswordInput
          name="confirm-password"
          autoComplete="new-password"
          value={fieldValue}
          onChange={(v) => setFieldValue(fieldName, v)}
          data-testid="confirm_password"
          errorDataTestId="confirm_password_error"
          error={
            fieldError && (
              <Trans
                i18nKey={fieldError}
                values={{ field: t(`${inputLabelTranslationPrefix}.password`) }}
              />
            )
          }
          alwaysShowErrorBlock={false}
        />
      </StyledInputContainer>
    );
  };

  return (
    <Container>
      {shouldShowTitle && (
        <Title>
          <Trans i18nKey={`${translationPrefix}.title.${type}`} />
        </Title>
      )}
      {shouldShowDescription && (
        <Description>
          <Trans i18nKey={`${translationPrefix}.description.${type}`} />
        </Description>
      )}
      {renderCurrentPasswordInputField()}
      {renderNewPasswordField()}

      {renderConfirmPasswordField()}
    </Container>
  );
};

export default PasswordForm;

import { useLocation, useNavigate } from "react-router-dom";
import routes from "routes/routes";

import { EasyTeamOnEmployeeClickParams } from "types/easyTeam";
import { useConnectEasyTeam } from "hooks/useConnectEasyTeam";

export const useAdminTimesheetsDetailsPage = () => {
  const {
    isLoading: isEmbeddedContentLoading,
    isEmbeddedContentDataFetched,
    getContentProvider,
  } = useConnectEasyTeam({});
  const navigate = useNavigate();
  const loctionState = useLocation().state as EasyTeamOnEmployeeClickParams;

  const handleBackClick = () => {
    navigate(routes.ADMIN_TIMESHEETS);
  };

  return {
    isPageLoading: isEmbeddedContentLoading,
    embeddedContent: {
      isEmbeddedContentDataFetched,
      getContentProvider,
    },
    pageData: {
      loctionState,
    },
    actions: {
      handleBackClick,
    },
  };
};

export const translations = {
  "en-US": {
    //NOTE:::Admin pages
    ADMIN_SETUP: {
      title: "Setup",
    },
    PEOPLE: {
      title: "People",
    },
    PEOPLE_EDIT_WORKER: {
      title: "{{fullName}}'s Profile",
    },
    PEOPLE_WORKER_DETAILS: {
      title: "{{fullName}}'s Details",
    },
    ADMIN_PAY: {
      title: "",
    },
    ADMIN_PROFILE: {
      title: "My Profile",
    },
    ADMIN_SETTINGS: {
      title: "Settings & Preferences",
    },
    ADMIN_BANK_ACCOUNTS: {
      title: "Bank Accounts",
    },
    DOCUMENTS: {
      title: "Documents",
    },
    ADMIN_TEAM_MEMBERS: {
      title: "Team Management",
    },
    PAYROLL_AND_BENEFITS: {
      title: "Payroll and Benefits Setup",
    },
    ADMIN_SETTINGS_MFA_AUTHENTICATION: {
      title: "Two-Factor Authentication",
    },
    ADMIN_TIMESHEETS_DETAILS: {
      title: "Timesheet Details",
    },
    ADMIN_SETTINGS_TIMESHEETS: {
      title: "Timesheet Settings",
    },

    //NOTE:::Employee pages
    EMPLOYEE_SETUP: {
      title: "Setup",
    },
    EMPLOYEE_PAY: {
      title: "Payment History",
    },
    EMPLOYEE_PROFILE: {
      title: "My Profile",
    },
    EMPLOYEE_SETTINGS: {
      title: "Settings & Preferences",
    },
    EMPLOYEE_BANK_ACCOUNTS: {
      title: "Bank Accounts",
    },

    BANKING: {
      title: "Banking",
    },
    BANKING_CARDS_EMPLOYEE: {
      title: "Your Cards",
    },
    BANKING_CARDS_ADMIN: {
      title: "Manage Card",
    },
    EMPLOYEE_BANKING_REWARDS: {
      title: "My Rewards Points",
    },
    BANKING_MONTHLY_STATEMENTS: {
      title: "Monthly Statements",
    },
    BANKING_TRANSFER_FUNDS: {
      title: "Transfer Funds",
    },
    BANKING_BUSINESS_OWNERSHIP_STRUCTURE: {
      title: "Company Structure",
    },
    EMPLOYEE_SETTINGS_PAY_DISTRIBUTION: {
      title: "Pay Distribution",
    },
    EMPLOYEE_SETTINGS_MFA_AUTHENTICATION: {
      title: "Two-Factor Authentication",
    },
    // NOTE:::Shared pages
    SETTINGS_SECURITY: {
      title: "Security Settings",
    },
    SETTINGS_PASSWORD_MANAGEMENT: {
      title: "Password Management",
    },
    TIMESHEETS: {
      title: "Timesheets",
    },
  },
};

export default translations;

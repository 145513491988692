import styled from "styled-components";

import { breakpoint } from "helpers";
import WarningSection from "components/WarningSection";

import { Body2, CustomLink, FormSectionTitle, InputContainer } from "uikit";

export const Container = styled.div``;

export const Title = styled(FormSectionTitle)`
  ${breakpoint("xs", "md")`
    font-size: ${({ theme }) => theme.size.titleSemiLarge};
    margin-bottom: 4px;

  `}
`;

export const StyledInputContainer = styled(InputContainer)`
  margin-bottom: 16px;

  ${CustomLink} {
    display: inline-block;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    margin-top: 4px;
  }
`;

export const Description = styled(Body2)`
  color: ${({ theme }) => theme.colors.textAlt};
  margin-bottom: 12px;

  ${breakpoint("xs", "md")`
    font-size: ${({ theme }) => theme.size.body2};
    margin-bottom: 16px;
  `}
`;

export const StyledWarningSection = styled(WarningSection)`
  padding: 8px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.size.body2};
  margin-bottom: 16px;
`;

import { useState } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CashIcon, CreditCard, ShieldLock, UserCircleIcon } from "assets/svg";
import { find } from "lodash";
import useRouterConfig from "routes/router";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { useBankAccountLink } from "hooks";
import { IProps as NavigationCardProps } from "components/NavigationCardWithDetails";

import { ListAdminsResponseDto } from "utils/swagger_react_query";

export const useEmployeeSettingsPage = () => {
  const translationPrefix = `settings_pages.employee.settings_page`;
  const [isLoading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const { routerConfig } = useRouterConfig();

  const [teamMembersDetails] = useState<ListAdminsResponseDto>();
  const {
    data: { bankAccounts },
  } = useBankAccountLink({ loadingCallback: setLoading });

  const handleMyProfileCardClick = () => navigate(routes.EMPLOYEE_PROFILE);
  const handleBankAccountsCardClick = () => navigate(routes.EMPLOYEE_BANK_ACCOUNTS);
  const handlePayDistributionCardClick = () => navigate(routes.EMPLOYEE_SETTINGS_PAY_DISTRIBUTION);
  const handleSecuritySettingsCardClick = () => navigate(routes.EMPLOYEE_SETTINGS_SECURITY);

  const optionsList: NavigationCardProps[] = [
    {
      id: "my_profile",
      icon: <UserCircleIcon />,
      title: <Trans i18nKey={`${translationPrefix}.options.my_profile.title`} />,
      onClick: handleMyProfileCardClick,
    },
    {
      id: "bank_account",
      icon: <CreditCard />,
      title: <Trans i18nKey={`${translationPrefix}.options.bank_account.title`} />,
      onClick: handleBankAccountsCardClick,
    },
    {
      id: "pay_distribution",
      icon: <CashIcon />,
      title: <Trans i18nKey={`${translationPrefix}.options.pay_distribution.title`} />,
      onClick: handlePayDistributionCardClick,
    },
    {
      id: "two_factor_authentication",
      icon: <ShieldLock />,
      title: <Trans i18nKey={`${translationPrefix}.options.security.title`} />,
      onClick: handleSecuritySettingsCardClick,
    },
  ];
  const filteredOptionsList = optionsList.filter((item) => {
    return (
      !(
        item.onClick === handleMyProfileCardClick &&
        find(routerConfig, (item) => item.path === routes.EMPLOYEE_PROFILE)?.disableCondition?.(
          currentUser,
          currentCompany,
        )
      ) &&
      !(
        item.onClick === handleBankAccountsCardClick &&
        find(
          routerConfig,
          (item) => item.path === routes.EMPLOYEE_BANK_ACCOUNTS,
        )?.disableCondition?.(currentUser, currentCompany)
      ) &&
      !(
        item.onClick === handlePayDistributionCardClick &&
        find(
          routerConfig,
          (item) => item.path === routes.EMPLOYEE_SETTINGS_PAY_DISTRIBUTION,
        )?.disableCondition?.(currentUser, currentCompany)
      ) &&
      !(
        item.onClick === handleSecuritySettingsCardClick &&
        find(
          routerConfig,
          (item) => item.path === routes.EMPLOYEE_SETTINGS_SECURITY,
        )?.disableCondition?.(currentUser, currentCompany)
      )
    );
  });

  return {
    metadata: {
      currentUser,
      currentCompany,
      isLoading,
      translationPrefix,
    },
    pageData: {
      teamMembersDetails,
      bankAccounts,
      optionsList: filteredOptionsList,
    },
  };
};

export default useEmployeeSettingsPage;

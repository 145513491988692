import { FC, ReactNode } from "react";
import { Trans } from "react-i18next";
import { TOptions } from "i18next";
import { useAppSelector } from "store/hooks";
import { rewardPointsSelector, userMetadataSelector } from "store/selectors";

import { hasEmployeeRights } from "permissions/helpers/shared";
import useRouteProperties from "hooks/useRouteProperties";
import AccountMenu from "components/AccountMenu";
import BackButton from "components/BackButton";
import Breadcrumbs from "components/Breadcrumbs";
import MainFooter from "components/MainFooter";
import MainMenu from "components/MainMenu";
import RewardPointsDisplay from "components/RewardPointsDisplay";

import {
  ContentContainer,
  PageTitle,
  PageTitleRightContent,
  PageTitleWrapperMargined,
} from "uikit";

import {
  BreadcrumbsContainer,
  Container,
  Content,
  ContentBlock,
  ContentBlockInner,
  TCContent,
  TCInner,
  TCInnerLeft,
  TCInnerRight,
  TopContent,
  TopContentWrapper,
  TopMain,
} from "./styles";

interface WrapperProps extends Props {
  backBtnText?: string;
  onBackBtnClick?: () => void;
}

interface Props {
  className?: string;
  title?: ReactNode | string;
  showTitle?: boolean;
  titleRightContent?: ReactNode;
  showBreadcrumbs?: boolean;
  metaDataLoading?: boolean;
  translationValues?: TOptions;
  children?: ReactNode | string;
  topContent?: ReactNode | string;
  mobileTopContent?: ReactNode | string;
  contentBg?: string;
  showUserMenu?: boolean;
  showResponsiveMenu?: boolean;
  needRenderMenuForResponsive?: boolean;
  responsivePageName?: ReactNode | string;
}

const DashboardLayout: FC<Props> = ({
  className,
  title,
  showTitle = true,
  titleRightContent,
  showBreadcrumbs = true,
  metaDataLoading = false,
  translationValues,

  children,
  topContent,
  mobileTopContent,
  contentBg,
  showUserMenu = true,
  showResponsiveMenu = true,
  needRenderMenuForResponsive = false,
  responsivePageName,
}) => {
  const currentUser = useAppSelector(userMetadataSelector);
  const rewardPoints = useAppSelector(rewardPointsSelector);
  const { getCurrentRouteConfig, getBreadcrumbsChainForCurrentRoute } = useRouteProperties();
  const currentRouteConfig = getCurrentRouteConfig();
  const breadcrumbs = getBreadcrumbsChainForCurrentRoute(translationValues);
  const userIsEmployee = hasEmployeeRights(currentUser);
  const shouldShowRewardPoints = userIsEmployee && currentUser?.user?.isOnboardingComplete;

  const roleClassName = `role-${currentUser?.user?.lastActiveRole}`;

  const pageTitle =
    title || currentRouteConfig?.title ? (
      <Trans i18nKey={currentRouteConfig?.title} values={translationValues} />
    ) : (
      ""
    );

  const responsivePageTitle = responsivePageName || pageTitle;

  const renderBreadcrumbs = () => {
    const shouldShowBreadcrumbs =
      !!showBreadcrumbs && !metaDataLoading && !!breadcrumbs?.length && breadcrumbs.length > 1;
    return (
      <>
        {!!shouldShowBreadcrumbs && (
          <BreadcrumbsContainer>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </BreadcrumbsContainer>
        )}
      </>
    );
  };

  const renderPageTitle = () => {
    const shouldShowTitle = (!!showTitle || titleRightContent) && !metaDataLoading;
    return (
      <>
        {!!shouldShowTitle && (
          <PageTitleWrapperMargined>
            <PageTitle>{pageTitle}</PageTitle>
            {titleRightContent && (
              <PageTitleRightContent>{titleRightContent}</PageTitleRightContent>
            )}
          </PageTitleWrapperMargined>
        )}
      </>
    );
  };

  return (
    <Container className={`${className} ${roleClassName}`}>
      <TopMain>
        <MainMenu needRenderMenuForResponsive={needRenderMenuForResponsive} />
        <ContentBlock style={{ backgroundImage: contentBg ? `url(${contentBg})` : "none" }}>
          <ContentBlockInner>
            <TopContentWrapper>
              <TopContent>
                <TCInner>
                  <TCInnerLeft>{topContent && <TCContent>{topContent}</TCContent>}</TCInnerLeft>
                  <TCInnerRight>
                    {shouldShowRewardPoints && (
                      <RewardPointsDisplay amount={rewardPoints?.pointsAmount || 0} />
                    )}
                    {showUserMenu && <AccountMenu />}
                  </TCInnerRight>
                </TCInner>
              </TopContent>
            </TopContentWrapper>
            <Content>
              <ContentContainer>
                {renderBreadcrumbs()}
                {renderPageTitle()}

                {children}
              </ContentContainer>
              <MainFooter />
            </Content>
          </ContentBlockInner>
        </ContentBlock>
      </TopMain>
    </Container>
  );
};

const DashboardLayoutWrapper: FC<WrapperProps> = ({ onBackBtnClick, backBtnText, ...rest }) => {
  const renderBackButton = () => (
    <BackButton onClick={() => (onBackBtnClick ? onBackBtnClick() : undefined)}>
      <Trans i18nKey={backBtnText || "common.back"} />
    </BackButton>
  );
  return (
    <DashboardLayout
      {...rest}
      topContent={onBackBtnClick && renderBackButton()}
      /* TODO:::      mobileTopContent={onBackBtnClick && renderBackButton()} //According to new desings, we won't have a back button on top on mobile devices */
    />
  );
};

export default DashboardLayoutWrapper;

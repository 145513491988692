import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";
import GoogleSignInButton from "components/GoogleSignInButton";

import { Input } from "uikit";

export const Container = styled.div``;

export const EmailInput = styled(Input)`
  ${breakpoint("xs", "lg")`
    margin-bottom: 24px;
    min-width: 100%;
    width: 100%;
  `}
`;

export const ButtonsSeparatorText = styled.p`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.textAlt};
  line-height: 22px;
  text-align: center;
  margin: 12px 0;
`;

export const StyledGoogleSignInButton = styled(GoogleSignInButton)``;

import { generatePath } from "react-router";
import { useNavigate } from "react-router-dom";
import routes from "routes/routes";

import { EasyTeamOnEmployeeClick } from "types/easyTeam";
import { useConnectEasyTeam } from "hooks/useConnectEasyTeam";

export const useAdminTimesheetsPage = () => {
  const navigate = useNavigate();
  const {
    isLoading: isEmbeddedContentLoading,
    isEmbeddedContentDataFetched,
    getContentProvider,
  } = useConnectEasyTeam({});

  const handleWorkerRowClick: EasyTeamOnEmployeeClick = (params) => {
    navigate(generatePath(routes.ADMIN_TIMESHEETS_DETAILS, { id: params.employeeId }), {
      state: params,
    });
  };

  const handleCloseWorkerDetailsPane = () => {};

  return {
    isPageLoading: isEmbeddedContentLoading,
    embeddedContent: {
      isEmbeddedContentDataFetched,
      getContentProvider,
    },
    actions: {
      handleWorkerRowClick,
      handleCloseWorkerDetailsPane,
    },
  };
};

import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import { useRouteHelpers } from "routes/helpers";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { EMagicLinkFlow } from "types/AuthFlowTypes";
import { getSuperAdminDetails, showErrorModal } from "helpers/index";
import {
  getIsSetupCompletedWithoutPassword,
  getShouldAdminCompleteSignUp,
  getShouldSetPassword,
} from "helpers/shared/guardRedirect";
import useAuth from "hooks/useAuth";

import {
  CreateAdminProfileDto,
  mutationAdminsControllerCreateAdminProfile,
  mutationUsersControllerAcceptTermsAndConditions,
  mutationUsersControllerCreateOrUpdatePassword,
  UserProfileResponseDto,
} from "utils/swagger_react_query";

import { ContactDetailsFormType, IAuthInvitationSearchParams, useCompleteSignIn } from "../_shared";
import { AdminSignUpSteps } from "./types";
import { AdminSignUpSchemaFormType, adminSignUpValidationSchema } from "./validationSchema";

export const useAdminSignUpPage = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { logout, isAuthenticated, getCurrentUser } = useAuth();
  const [svoc, setSvoc] = useState<boolean>(false);
  const currentUser = useAppSelector(userMetadataSelector);
  const { getDefaultRoute } = useRouteHelpers();

  const isSetupCompletedWithoutPassword = (user?: UserProfileResponseDto | null | undefined) => {
    const res = getIsSetupCompletedWithoutPassword(user ?? currentUser);
    return res;
  };

  const [currentStep, setCurrentStep] = useState<AdminSignUpSteps>(AdminSignUpSteps.SET_PASSWORD);
  const [formInitValues, setFormInitValues] = useState<ContactDetailsFormType>({
    firstName: "",
    lastName: "",
    phone: "",
    jobTitle: "",
  });

  const {
    actions,
    metadata: { isLoading: pageLoader },
  } = useCompleteSignIn({ mode: EMagicLinkFlow.SIGN_UP, autoLogin: false });
  const { setLoading: setPageLoader } = actions;

  useEffect(() => {
    autoLogin(EMagicLinkFlow.SIGN_UP, getAuthParams());
  }, []);

  const getInitFormValues = useCallback<() => AdminSignUpSchemaFormType[typeof currentStep]>(() => {
    if (currentStep === AdminSignUpSteps.SET_PASSWORD) {
      return {
        newPassword: "",
        confirmPassword: "",
      };
    }
    return formInitValues;
  }, [currentStep, formInitValues]);

  const getValidationSchema = useCallback(() => {
    if (currentStep === AdminSignUpSteps.SET_PASSWORD)
      return adminSignUpValidationSchema.fields[AdminSignUpSteps.SET_PASSWORD];

    return adminSignUpValidationSchema.fields[AdminSignUpSteps.CONTACT_DETAILS];
  }, [currentStep]);

  const getAuthParams = (): IAuthInvitationSearchParams => {
    return {
      invitationId: searchParams.get("invitationId") || undefined,
      error: searchParams.get("error") || undefined,
    };
  };

  const determineInitialStep = (user: UserProfileResponseDto | null | undefined) => {
    if (getShouldSetPassword(user)) {
      setCurrentStep(AdminSignUpSteps.SET_PASSWORD);
    } else {
      setCurrentStep(AdminSignUpSteps.CONTACT_DETAILS);
      setFormInitValues({
        firstName: user?.user?.firstName || "",
        lastName: user?.user?.lastName || "",
        phone: user?.user?.phone || "",
        jobTitle: user?.user?.jobTitle || "",
      });
    }
  };

  const autoLogin = async (mode: EMagicLinkFlow, authParams: IAuthInvitationSearchParams) => {
    if (searchParams.size || (searchParams.size && isAuthenticated())) {
      const userDetailsRes = await actions.completeSignIn(
        mode,
        { error: authParams.error, code: authParams.invitationId },
        authParams.invitationId,
      );
      if (!isEmpty(userDetailsRes)) {
        determineInitialStep(userDetailsRes as UserProfileResponseDto);
      }
    } else if (!isAuthenticated()) {
      navigate(routes.SIGN_IN);
    } else {
      const userDetailsRes = await actions.checkUserData(undefined);
      if (!isEmpty(userDetailsRes)) {
        determineInitialStep(userDetailsRes || null);
      }
    }

    setLoading(false);
    setPageLoader(false);
  };

  const handleSetPassword = async (
    formikProps: FormikProps<AdminSignUpSchemaFormType["SET_PASSWORD"]>,
  ) => {
    setSvoc(true);
    const { validateForm, values } = formikProps;
    const errors = await validateForm(values);
    if (!isEmpty(errors)) return;
    try {
      setLoading(true);
      await mutationUsersControllerCreateOrUpdatePassword()({
        newPassword: values.newPassword,
      });
      const userDetails = await getCurrentUser();
      const isSignUpCompleted = !getShouldAdminCompleteSignUp(userDetails);
      if (isSignUpCompleted) {
        navigate(getDefaultRoute(userDetails || null));
      } else {
        setCurrentStep(AdminSignUpSteps.CONTACT_DETAILS);
        setFormInitValues({
          firstName: userDetails?.user?.firstName || "",
          lastName: userDetails?.user?.lastName || "",
          phone: userDetails?.user?.phone || "",
          jobTitle: userDetails?.user?.jobTitle || "",
        });
      }
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
      setSvoc(false);
    }
  };

  const handleSubmitPersonalDetails = async (
    formikProps: FormikProps<AdminSignUpSchemaFormType["CONTACT_DETAILS"]>,
  ) => {
    const { validateForm, values } = formikProps;
    const errors = await validateForm(values);
    if (!isEmpty(errors)) return;
    try {
      setSvoc(true);
      setLoading(true);
      const payload: CreateAdminProfileDto = getSuperAdminDetails(values);
      await mutationAdminsControllerCreateAdminProfile()(payload);
      await mutationUsersControllerAcceptTermsAndConditions()();

      const userInfoRes = await getCurrentUser();
      if (userInfoRes?.user?.userId) {
        navigate(getDefaultRoute(userInfoRes));
      }
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    metadata: {
      pageLoader,
      isLoading,
      currentUser,
      getInitFormValues,
      getValidationSchema,
      svoc,
      currentStep,
      isSetupCompletedWithoutPassword,
    },
    actions: {
      handleSetPassword,
      handleSubmitPersonalDetails,
    },
  };
};

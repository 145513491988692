export const mainMenuTranslations = {
  "en-US": {
    setup: "Setup",
    home: "Home",
    people: "People",
    pay_admin: "Pay",
    pay_employee: "Pay",
    help_center: "Help",
    settings_admin: "Settings",
    settings_employee: "Settings",
    banking_admin: "Banking",
    banking_employee: "Banking",
    timesheets: "Timesheets",
  },
};

export default mainMenuTranslations;

import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { KeyIcon, LockAccessIcon } from "assets/svg";
import { find } from "lodash";
import useRouterConfig from "routes/router";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { hasAdminRights } from "permissions/helpers/shared";
import { IProps as NavigationCardProps } from "components/NavigationCardWithDetails";

export const useSettingsMFAPage = () => {
  const translationPrefix = `settings_pages.shared.security_settings_page`;
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const navigate = useNavigate();
  const isAdmin = hasAdminRights(currentUser);
  const { routerConfig } = useRouterConfig();

  const passwordManagementRoute = isAdmin
    ? routes.ADMIN_SETTINGS_PASSWORD_MANAGEMENT
    : routes.EMPLOYEE_SETTINGS_PASSWORD_MANAGEMENT;
  const MFARoute = isAdmin
    ? routes.ADMIN_SETTINGS_MFA_AUTHENTICATION
    : routes.EMPLOYEE_SETTINGS_MFA_AUTHENTICATION;
  const handlePasswordManagementCardClick = () => {
    navigate(passwordManagementRoute);
  };
  const handleTwoFactorAuthenticationCardClick = () => {
    navigate(MFARoute);
  };

  const cardsList: NavigationCardProps[] = [
    {
      id: "password_management",
      icon: <KeyIcon />,
      title: <Trans i18nKey={`${translationPrefix}.cards_list.password_management.title`} />,
      onClick: handlePasswordManagementCardClick,
    },
    {
      id: "two_factor_authentication",
      icon: <LockAccessIcon />,
      title: <Trans i18nKey={`${translationPrefix}.cards_list.two_factor_authentication.title`} />,
      onClick: handleTwoFactorAuthenticationCardClick,
    },
  ];

  const filteredCardsList = cardsList.filter((item) => {
    return (
      !(
        item.onClick === handlePasswordManagementCardClick &&
        find(routerConfig, (item) => item.path === passwordManagementRoute)?.disableCondition?.(
          currentUser,
          currentCompany,
        )
      ) &&
      !(
        item.onClick === handleTwoFactorAuthenticationCardClick &&
        find(routerConfig, (item) => item.path === MFARoute)?.disableCondition?.(
          currentUser,
          currentCompany,
        )
      )
    );
  });

  return {
    metadata: {
      currentUser,
      currentCompany,
      translationPrefix,
    },
    pageData: {
      cardsList: filteredCardsList,
    },
  };
};

export default useSettingsMFAPage;

import { some } from "lodash";

import { UserRole } from "types/BETypes";

import { UserProfileResponseDto } from "utils/swagger_react_query";

export const enum Pages {
  ADMIN_SETUP = "ADMIN_SETUP",
  EMPLOYEE_SETUP = "EMPLOYEE_SETUP",
  ADMIN_HOME = "ADMIN_HOME",
  EMPLOYEE_HOME = "EMPLOYEE_HOME",
  PEOPLE = "PEOPLE",
  PEOPLE_EDIT_WORKER = "PEOPLE_EDIT_WORKER",
  PEOPLE_WORKER_DETAILS = "PEOPLE_WORKER_DETAILS",
  ADMIN_PAY = "ADMIN_PAY",
  EMPLOYEE_PAY = "EMPLOYEE_PAY",
  ADMIN_SETTINGS = "ADMIN_SETTINGS",
  EMPLOYEE_SETTINGS = "EMPLOYEE_SETTINGS",
  BANKING = "BANKING",
  ADMIN_PROFILE = "ADMIN_PROFILE",
  EMPLOYEE_PROFILE = "EMPLOYEE_PROFILE",
  DOCUMENTS = "DOCUMENTS",
  ADMIN_TEAM_MEMBERS = "ADMIN_TEAM_MEMBERS",
  PAYROLL_AND_BENEFITS = "PAYROLL_AND_BENEFITS",
  ADMIN_BANK_ACCOUNTS = "ADMIN_BANK_ACCOUNTS",
  EMPLOYEE_PAID_BANK_ACCOUNTS = "EMPLOYEE_PAID_BANK_ACCOUNTS",
  EMPLOYEE_BANK_ACCOUNTS = "EMPLOYEE_BANK_ACCOUNTS",
  ADMIN_TIMESHEETS = "ADMIN_TIMESHEETS",
  EMPLOYEE_TIMESHEETS = "EMPLOYEE_TIMESHEETS",
}

type PropertyInterface = Record<Pages, boolean>;

export type Permission = {
  page: PropertyInterface;
  createBonus: boolean;
  editDraftBonus: boolean;
  deleteBonus: boolean;
  acceptBonus: boolean;
  declineBonus: boolean;
  voidBonus: boolean;
  resendBonusInvitation: boolean;
  viewBonusEditedBy: boolean;
  viewOthersBonuses: boolean;
  transferFundsToMyBank: boolean;
  editPerformanceChallenges: boolean;
  editAllPerformanceChallenges: boolean;
};

export type Permissions = {
  [key in keyof typeof UserRole]?: Permission;
};

export interface PermissionProps {
  permissions?: (keyof Permission)[];
  roles?: UserRole[];
}

export const permissions: Permissions = {
  [UserRole.SUPERADMIN]: {
    page: {
      [Pages.ADMIN_SETUP]: true,
      [Pages.EMPLOYEE_SETUP]: false,
      [Pages.ADMIN_HOME]: true,
      [Pages.EMPLOYEE_HOME]: false,
      [Pages.PEOPLE]: true,
      [Pages.PEOPLE_EDIT_WORKER]: true,
      [Pages.PEOPLE_WORKER_DETAILS]: true,
      [Pages.ADMIN_PAY]: true,
      [Pages.EMPLOYEE_PAY]: false,
      [Pages.ADMIN_SETTINGS]: true,
      [Pages.EMPLOYEE_SETTINGS]: false,
      [Pages.BANKING]: true,
      [Pages.ADMIN_PROFILE]: true,
      [Pages.EMPLOYEE_PROFILE]: false,
      [Pages.DOCUMENTS]: true,
      [Pages.ADMIN_TEAM_MEMBERS]: true,
      [Pages.PAYROLL_AND_BENEFITS]: true,
      [Pages.ADMIN_BANK_ACCOUNTS]: true,
      [Pages.EMPLOYEE_PAID_BANK_ACCOUNTS]: false,
      [Pages.EMPLOYEE_BANK_ACCOUNTS]: false,
      [Pages.ADMIN_TIMESHEETS]: true,
      [Pages.EMPLOYEE_TIMESHEETS]: false,
    },
    createBonus: true,
    editDraftBonus: true,
    deleteBonus: true,
    acceptBonus: false,
    declineBonus: false,
    voidBonus: true,
    resendBonusInvitation: true,
    viewBonusEditedBy: true,
    viewOthersBonuses: true,
    transferFundsToMyBank: false,
    editPerformanceChallenges: true,
    editAllPerformanceChallenges: true,
  },
  [UserRole.ADMIN]: {
    page: {
      [Pages.ADMIN_SETUP]: true,
      [Pages.EMPLOYEE_SETUP]: false,
      [Pages.ADMIN_HOME]: true,
      [Pages.EMPLOYEE_HOME]: false,
      [Pages.PEOPLE]: true,
      [Pages.PEOPLE_EDIT_WORKER]: true,
      [Pages.PEOPLE_WORKER_DETAILS]: true,
      [Pages.ADMIN_PAY]: true,
      [Pages.EMPLOYEE_PAY]: false,
      [Pages.ADMIN_SETTINGS]: true,
      [Pages.EMPLOYEE_SETTINGS]: false,
      [Pages.BANKING]: true,
      [Pages.ADMIN_PROFILE]: true,
      [Pages.EMPLOYEE_PROFILE]: false,
      [Pages.DOCUMENTS]: true,
      [Pages.ADMIN_TEAM_MEMBERS]: true,
      [Pages.PAYROLL_AND_BENEFITS]: true,
      [Pages.ADMIN_BANK_ACCOUNTS]: true,
      [Pages.EMPLOYEE_PAID_BANK_ACCOUNTS]: false,
      [Pages.EMPLOYEE_BANK_ACCOUNTS]: false,
      [Pages.ADMIN_TIMESHEETS]: true,
      [Pages.EMPLOYEE_TIMESHEETS]: false,
    },
    createBonus: true,
    editDraftBonus: true,
    deleteBonus: true,
    acceptBonus: false,
    declineBonus: false,
    voidBonus: true,
    resendBonusInvitation: true,
    viewBonusEditedBy: true,
    viewOthersBonuses: true,
    transferFundsToMyBank: false,
    editPerformanceChallenges: true,
    editAllPerformanceChallenges: false,
  },
  [UserRole.OWNER]: {
    page: {
      [Pages.ADMIN_SETUP]: true,
      [Pages.EMPLOYEE_SETUP]: false,
      [Pages.ADMIN_HOME]: true,
      [Pages.EMPLOYEE_HOME]: false,
      [Pages.PEOPLE]: true,
      [Pages.PEOPLE_EDIT_WORKER]: true,
      [Pages.PEOPLE_WORKER_DETAILS]: true,
      [Pages.ADMIN_PAY]: true,
      [Pages.EMPLOYEE_PAY]: false,
      [Pages.ADMIN_SETTINGS]: true,
      [Pages.EMPLOYEE_SETTINGS]: false,
      [Pages.BANKING]: true,
      [Pages.ADMIN_PROFILE]: true,
      [Pages.EMPLOYEE_PROFILE]: false,
      [Pages.DOCUMENTS]: true,
      [Pages.ADMIN_TEAM_MEMBERS]: true,
      [Pages.PAYROLL_AND_BENEFITS]: true,
      [Pages.ADMIN_BANK_ACCOUNTS]: true,
      [Pages.EMPLOYEE_PAID_BANK_ACCOUNTS]: false,
      [Pages.EMPLOYEE_BANK_ACCOUNTS]: false,
      [Pages.ADMIN_TIMESHEETS]: true,
      [Pages.EMPLOYEE_TIMESHEETS]: false,
    },
    createBonus: true,
    editDraftBonus: true,
    deleteBonus: true,
    acceptBonus: false,
    declineBonus: false,
    voidBonus: true,
    resendBonusInvitation: true,
    viewBonusEditedBy: true,
    viewOthersBonuses: true,
    transferFundsToMyBank: false,
    editPerformanceChallenges: true,
    editAllPerformanceChallenges: true,
  },
  [UserRole.EMPLOYEE]: {
    page: {
      [Pages.ADMIN_SETUP]: false,
      [Pages.EMPLOYEE_SETUP]: true,
      [Pages.ADMIN_HOME]: false,
      [Pages.EMPLOYEE_HOME]: true,
      [Pages.PEOPLE]: false,
      [Pages.PEOPLE_EDIT_WORKER]: false,
      [Pages.PEOPLE_WORKER_DETAILS]: false,
      [Pages.ADMIN_PAY]: false,
      [Pages.EMPLOYEE_PAY]: true,
      [Pages.ADMIN_SETTINGS]: false,
      [Pages.EMPLOYEE_SETTINGS]: true,
      [Pages.BANKING]: true,
      [Pages.ADMIN_PROFILE]: false,
      [Pages.EMPLOYEE_PROFILE]: true,
      [Pages.DOCUMENTS]: false,
      [Pages.ADMIN_TEAM_MEMBERS]: false,
      [Pages.PAYROLL_AND_BENEFITS]: false,
      [Pages.ADMIN_BANK_ACCOUNTS]: false,
      [Pages.EMPLOYEE_PAID_BANK_ACCOUNTS]: true,
      [Pages.EMPLOYEE_BANK_ACCOUNTS]: true,
      [Pages.ADMIN_TIMESHEETS]: false,
      [Pages.EMPLOYEE_TIMESHEETS]: true,
    },
    createBonus: false,
    editDraftBonus: false,
    deleteBonus: false,
    acceptBonus: true,
    declineBonus: true,
    voidBonus: false,
    resendBonusInvitation: false,
    viewBonusEditedBy: false,
    viewOthersBonuses: false,
    transferFundsToMyBank: true,
    editPerformanceChallenges: false,
    editAllPerformanceChallenges: false,
  },
};

export const statesWithRestrictedBonusCreation = ["CA", "ID", "SD", "OH"];

export const isBonusCreationRestricted = (state: string) => {
  return statesWithRestrictedBonusCreation.indexOf(state) !== -1;
};

export const isPermitted = (
  user: UserProfileResponseDto | undefined | null,
  permissionsArray: (keyof Permission)[] | undefined,
  rolesArray: UserRole[] | undefined,
): boolean => {
  const lastActiveRole = user?.user?.lastActiveRole;
  if (!lastActiveRole) return false;
  const fitByPermissions =
    !permissionsArray?.length ||
    !!some(permissionsArray, (permission) => permissions?.[lastActiveRole]?.[permission]);
  const fitByRoles = !rolesArray?.length || !!some(rolesArray, (role) => role === lastActiveRole);
  return fitByPermissions && fitByRoles;
};

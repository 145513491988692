import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRouteHelpers } from "routes/helpers";
import routes from "routes/routes";

import { EMagicLinkFlow } from "types/AuthFlowTypes";
import { ErrorConst, MFAFactorType } from "types/BETypes";
import { showErrorModal } from "helpers";
import {
  getShouldAdminCompleteSignUp,
  getShouldEmployeeCompleteSignUp,
  getShouldOwnerCompleteSignUp,
} from "helpers/shared/guardRedirect";
import useAuth from "hooks/useAuth";
import { ISignInMfaPageNavigationParams } from "pages/Auth/SignInMfaPage/types";

import {
  queryAuthControllerListMfaFactors,
  UserProfileResponseDto,
} from "utils/swagger_react_query";

import { IAuthSignInSearchParams } from "../types";

interface IParams {
  mode: EMagicLinkFlow;
  autoLogin?: boolean;
}

export const useCompleteSignIn = ({ mode, autoLogin = true }: IParams) => {
  const isCompletePath = [
    routes.SIGN_UP_COMPLETE,
    routes.SIGN_IN_COMPLETE,
    routes.EMPLOYEE_SIGN_UP,
    routes.ADMIN_SIGN_UP,
  ].includes(location.pathname as routes);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isLoading, setLoading] = useState<boolean>(isCompletePath);
  const { isAuthenticated, login, getCurrentUser, logout, saveUserToStore } = useAuth();
  const { getDefaultRoute } = useRouteHelpers();

  const getAuthParams = (): IAuthSignInSearchParams => {
    return {
      code: searchParams.get("code") || undefined,
      error: searchParams.get("error") || undefined,
      invitationId: searchParams.get("invitationId") || undefined,
      impersonateId: searchParams.get("impersonateId") || undefined,
    };
  };
  const getIsAuthParamsExist = (): boolean =>
    !!Object.values(getAuthParams()).filter((it) => it).length;

  useEffect(() => {
    const authParams = getAuthParams();
    if (isCompletePath && autoLogin) {
      if (authParams?.code || authParams?.error) {
        setLoading(true);
        completeSignIn(mode, getAuthParams());
      } else {
        checkUserData();
      }
    }

    return () => {
      searchParams.delete("code");
      searchParams.delete("error");
      searchParams.delete("invitationId");
      searchParams.delete("impersonateId");
    };
  }, []);

  const checkUserData = async (userData?: UserProfileResponseDto) => {
    const userDetails = userData || (await getCurrentUser(false, false));
    const isUserAuthenticated = isAuthenticated();
    const isAuthParamsExist = getIsAuthParamsExist();

    if (isUserAuthenticated && !isAuthParamsExist) {
      if (!userDetails?.user?.userId) {
        setLoading(false);
        return navigate(routes.SIGN_UP_COMPLETE);
      }
      redirectByRoleAndSalsaOnboardingStatus(userDetails);
      setLoading(false);
      return userDetails;
    } else if (!isUserAuthenticated && !isAuthParamsExist) {
      navigate(routes.SIGN_IN);
    }
  };

  const completeSignIn = async (
    mode: EMagicLinkFlow,
    authParams: IAuthSignInSearchParams,
    invitationId?: string,
  ) => {
    try {
      const authCode = authParams?.code || "";
      if (authParams?.error) throw authParams.error;
      const isAuthParamsExist = getIsAuthParamsExist();
      if (!isAuthParamsExist) {
        await checkUserData();
      }

      const loginResult = await login(
        !invitationId ? authCode : "",
        mode,
        invitationId || undefined,
        authParams.impersonateId || undefined,
        false,
      );

      if (!loginResult.success) throw new Error(loginResult.message);
      const isUserExisted = loginResult.data?.companyUsers?.[0];
      if (!authParams.impersonateId && isUserExisted) {
        const factorsListRes = await queryAuthControllerListMfaFactors();
        const shouldShowMfa = factorsListRes.factors.find(
          (factor) => factor.type === MFAFactorType.SMS && !!factor.isVerified,
        );
        if (shouldShowMfa) {
          return navigate(routes.SIGN_IN_COMPLETE_MFA, {
            replace: true,
            state: {
              code: authCode,
            } as ISignInMfaPageNavigationParams,
          });
        }
      }
      const userInfoRes = await getCurrentUser();
      redirectByRoleAndSalsaOnboardingStatus(userInfoRes);
      return userInfoRes;
    } catch (error: any) {
      setTimeout(() => {
        if ([ErrorConst.ACCESS_DENIED].includes(error)) {
          return showErrorModal(ErrorConst.TOKEN_NOT_VERIFIED || "", () => logout(false, true));
        }

        showErrorModal(error || "", () => logout(false, true));
      });
    } finally {
      setLoading(false);
    }
  };

  const redirectByRoleAndSalsaOnboardingStatus = (
    userInfo: Partial<UserProfileResponseDto> | null | undefined,
  ) => {
    const shouldEmployeeCompleteSignUp = getShouldEmployeeCompleteSignUp(userInfo);
    const shouldAdminCompleteSignUp = getShouldAdminCompleteSignUp(userInfo);
    const shouldOwnerCompleteSignUp = getShouldOwnerCompleteSignUp(
      userInfo,
      !!userInfo?.user?.companyId,
    );
    if (!shouldAdminCompleteSignUp && !shouldEmployeeCompleteSignUp && !shouldOwnerCompleteSignUp) {
      return navigate(getDefaultRoute());
    }
    if (shouldEmployeeCompleteSignUp) {
      //NOTE:::should to redirect employee to complete sign up if he didn't accept terms
      navigate(routes.EMPLOYEE_SIGN_UP);
    } else if (shouldOwnerCompleteSignUp) {
      //NOTE:::if user is an owner and company doesn't exist, redirect to company sign up
      navigate(routes.SIGN_UP_COMPLETE, { replace: true });
    } else if (shouldAdminCompleteSignUp) {
      //NOTE:::should to redirect ADMIN and SUPERADMIN to complete sign up if he didn't fill in the first and last name
      navigate(routes.ADMIN_SIGN_UP, { replace: true });
    }
  };

  return {
    metadata: {
      isLoading,
    },
    actions: {
      checkUserData,
      completeSignIn,
      redirectByRoleAndSalsaOnboardingStatus,
      setLoading,
    },
  };
};

enum routes {
  ROOT = "/",

  //NOTE:::Auth
  SIGN_IN = "/sign-in",
  SIGN_UP = "/sign-up",
  SIGN_IN_COMPLETE = "/sign-in/complete",
  SIGN_UP_COMPLETE = "/sign-up/complete",
  SIGN_IN_COMPLETE_MFA = "/sign-in/complete-mfa",
  RECOVERY_ACCOUNT = "/recovery-account",
  COMPLETE_RECOVERY_ACCOUNT = "/complete-recovery/:internalUserId",
  EMPLOYEE_SIGN_UP = "/sign-up/employee/complete",
  ADMIN_SIGN_UP = "/sign-up/admin/complete",
  CHECK_EMAIL = "/check-email",
  RESET_PASSWORD = "/reset-password",
  RESET_PASSWORD_WITH_TOKEN = "/reset-password/:token",

  //NOTE:::Main Menu
  ADMIN_SETUP = "/setup",
  EMPLOYEE_SETUP = "/setup/employee",
  PEOPLE = "/people",
  //NOTE:::For some reason if you set :workerId here , and another param in another route, say, :cardId in BANKING_CARDS_ADMIN,
  //NOTE:::The last route to be defined in router crashes. Investigate later
  PEOPLE_EDIT_WORKER = "/people/edit-worker/:id",
  PEOPLE_WORKER_DETAILS = "/people/worker-details/:id",
  ADMIN_PAY = "/pay",
  EMPLOYEE_PAY = "/pay/employee",
  ADMIN_SETTINGS = "/settings",
  ADMIN_SETTINGS_MFA_AUTHENTICATION = "/settings/authentication",
  EMPLOYEE_SETTINGS = "/settings/employee",
  EMPLOYEE_SETTINGS_PAY_DISTRIBUTION = "/settings/employee/pay-distribution",
  EMPLOYEE_BANK_ACCOUNTS = "/settings/employee/bank-accounts",
  EMPLOYEE_SETTINGS_MFA_AUTHENTICATION = "/settings/employee/authentication",
  EMPLOYEE_SETTINGS_SECURITY = "/settings/employee/security",
  EMPLOYEE_SETTINGS_PASSWORD_MANAGEMENT = "/settings/employee/password-management",
  BANKING = "/banking",
  BANKING_MONTHLY_STATEMENTS = "/banking/monthly-statements",
  BANKING_CARDS_EMPLOYEE = "/banking/cards",
  BANKING_CARDS_ADMIN = "/banking/manage-card/:id",
  BANKING_TRANSFER_FUNDS = "/banking/transfer-funds",
  BANKING_BUSINESS_OWNERSHIP_STRUCTURE = "/banking/business-ownership-structure",
  EMPLOYEE_BANKING_REWARDS = "/banking/my-rewards",
  ADMIN_SETTINGS_SECURITY = "/settings/security",
  EMPLOYEE_TIMESHEETS = "/timesheets/employee",
  ADMIN_SETTINGS_PASSWORD_MANAGEMENT = "/settings/password-management",
  ADMIN_SETTINGS_TIMESHEETS = "/settings/timesheets",
  ADMIN_TIMESHEETS = "/timesheets",
  ADMIN_TIMESHEETS_DETAILS = "/timesheets/:id",

  ADMIN_PROFILE = "/my-profile",
  ADMIN_BANK_ACCOUNTS = "/settings/bank-accounts",
  EMPLOYEE_PROFILE = "/my-profile/employee",

  DOCUMENTS = "/settings/documents",
  PAYROLL_AND_BENEFITS = "/settings/payroll-and-benefits",
  ADMIN_TEAM_MEMBERS = "/settings/team-members",

  //NOTE:::Service
  PAGE_404 = "/404",
  INSUFFICIENT_PERMISSIONS = "/insufficient-permissions",
  HELP_CENTER = "/help-center",
  SERVICE_AGREEMENT = "/service-agreement",

  //NOTE:::Debug routes
  DEBUG_ICONS_PAGE = "/debug-icons",

  //NOTE:::OLD ROUTES - TO BE REMOVED
  BONUS_TEMPLATES_LIST = "/bonus-templates", //NOTE:::TODO::: Check if we still need these routes
  BONUS_TEMPLATE_DETAILS = "/bonus-templates/:bonusTemplateId",
  COMPANY_INTEGRATIONS_SSO = "/settings/integrations/sso",

  CREATE_UPDATE_EMPLOYEE_GROUP = "/employees/group/:groupId",
  EMPLOYEE_BONUSES_LIST = "/employees/bonuses/:employeeId",
  EMPLOYEE_MAKE_PAYMENT = "/employees/make-payments/:employeeId",
  EMPLOYEE_CONFIGURE_SPECIAL_PAYMENT_PLAN = "/employees/special-payment-plan/:employeeId",
  FUNDING_TRANSACTION = "/funding/transactions/:transactionId",
  CREATE_UPDATE_BONUS_TEMPLATE = "/bonus-templates/create-update-bonus/:employeeId/:id", //NOTE:::New bonus / edit existing one
  CREATE_UPDATE_PERFORMANCE_CHALLENGE = "/bonus-templates/create-update-challenge/:employeeId/:id",
  EMPLOYEE_SPECIFIC_BONUS_TEMPLATE_DETAILS = "/bonus-templates/:bonusTemplateId/employees/:employeeId/:bonusId",
  UPDATE_CHALLENGE_TEMPLATE_PROGRESS = "/bonus-templates/update-challenge-template-progress/:id",
}

export default routes;

import { FC } from "react";
import { Settings } from "@easyteam/web-ui";

import DashboardLayout from "layouts/DashboardLayout";

import { Loader } from "uikit";
import { LoaderContainer } from "uikit/Loader/styles";

import { useAdminTimesheetSettingsPage } from "./useAdminTimesheetSettingsPage";
import { Container } from "./styles";

const AdminTimesheetSettingsPage: FC = () => {
  const { isPageLoading, embeddedContent } = useAdminTimesheetSettingsPage();
  const { getContentProvider } = embeddedContent;

  const EmbeddedContentProvider = getContentProvider;

  return (
    <DashboardLayout>
      <Container>
        {isPageLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <EmbeddedContentProvider>
            <Settings showGlobalTimeTracking={false} showNotificationSettings={false} />
          </EmbeddedContentProvider>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default AdminTimesheetSettingsPage;

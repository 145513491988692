import { FC, ReactNode, useMemo } from "react";
import { Trans } from "react-i18next";
import { OptionProps } from "react-select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { EditIcon, UsersPlusIcon } from "assets/svg";
import { isEmpty } from "lodash";

import { COMPANY_CONSUMER_ACCOUNT_AGREEMENT } from "constants/assets";
import { getFormattedNumber, getName, NumberType } from "helpers";
import {
  renderAsyncUserSearchHighlightedText,
  renderAsyncUserSearchNameWithEmail,
} from "components/AsyncUserSearchInput/renderElements";
import { StyledOption } from "components/AsyncUserSearchInput/styles";
import { BusinessStructureEntityType } from "components/BusinessOwnersStructure/components";
import { convertBusinessStructureInfoItemToCardData } from "components/BusinessOwnersStructure/components/BusinessStructureEntityCard/helpers";
import { PercentageText } from "components/BusinessOwnersStructure/components/styles";
import {
  getAllBusinessStructureEntities,
  getFormattedCompanyNameWithKeyManagerFullName,
} from "components/BusinessOwnersStructure/helpers";
import {
  BusinessStructureEntityDataType,
  IBusinessOwnersStructureDisclosuresType,
  IReviewCertifyStep,
} from "components/BusinessOwnersStructure/types";
import { VerificationErrorsSection } from "components/CompanyBankAccountComponents/components";
import {
  renderCopyButton,
  renderErrorMessage,
  renderFooterButtonsSection,
  renderKycResponseSection,
} from "components/CompanyBankAccountComponents/components/renderElements";
import {
  CheckboxTextLink,
  CheckboxWrapper,
} from "components/CompanyBankAccountComponents/components/styles";

import { Checkbox, FormLabelSmall, FullSectionLoader, Select } from "uikit";

import { UserResponseDto } from "utils/swagger_react_query";

import { getBusinessInfoRows } from "./constants";
import { useReviewCertifyStep } from "./useReviewCertifyStep";
import {
  ContentSection,
  CSRow,
  CSRowCell,
  CSRowCellLabel,
  CSRowCellValue,
  CSRowInner,
  CSTitle,
  InviteButton,
  InviteTeamMemberSection,
  ITSInviteBtn,
  ITSSelectContainer,
  SectionsContainer,
  StyledContent,
  StyledContentWrapper,
  StyledEditButtonWithIcon,
  StyledWarningSection,
  WarningContentInner,
  WCIText,
} from "./styles";

const ReviewCertifyStep: FC<IReviewCertifyStep> = ({
  onBackClick,
  onBusinessInfoEditClick,
  onBusinessOwnersEditClick,
  onKybStatusSectionPrimaryBtnClick,
  onKybSuccess,
}) => {
  const kybResponseStatusTranslationPrefix = `components.company_bank_account_components.shared.kyb_kyc_response_section`;
  const translationPrefix = `components.business_owner_structure.review_certify_step`;
  const sharedTranslationPrefix = `components.business_owner_structure.shared`;
  const fieldLabelsTranslationPrefix = `${sharedTranslationPrefix}.inputs_business`;
  const { metadata, pageData, actions } = useReviewCertifyStep({
    onKybSuccess,
    onKybStatusSectionPrimaryBtnClick,
  });
  const {
    businessOwnersStructure,
    kybErrors,
    kybResponseType,
    certificationDisclosureValues,
    kybSubmissionState,
    selectedTeamMember,
    businessStructureMembers,
  } = pageData;
  const {
    handleCertificationDisclosureValuesChange,
    handleSubmit,
    handleShowWarning,
    handleShowDropdown,
    handleInviteTeamMember,
    handleChangeSelectedTeamMember,
  } = actions;

  const businessStructureEntitiesList = getAllBusinessStructureEntities(businessOwnersStructure);
  const businessInfoRows = getBusinessInfoRows(businessOwnersStructure);

  const globalLoading = metadata.isLoading || metadata.isSubmitting;
  const shouldDisablePrimaryBtn = useMemo(() => {
    if (globalLoading) return true;

    const isInviteTeamMemberFormVisible = Object.values(kybSubmissionState).some(
      (value) => !!value,
    );
    if (isInviteTeamMemberFormVisible) return true;
    const isDisclosureChecked = Object.values(certificationDisclosureValues).every(
      (value) => value === true,
    );
    if (isDisclosureChecked) return false;

    return true;
  }, [certificationDisclosureValues, globalLoading]);

  const selectedTeamMemberFullName = useMemo(() => {
    return getName({
      firstName: selectedTeamMember?.firstName || "",
      lastName: selectedTeamMember?.lastName || "",
    });
  }, [selectedTeamMember]);

  const renderLabelWithValue = (
    label: ReactNode,
    value: ReactNode | undefined,
    className?: string,
  ) => (
    <CSRowCell className={className}>
      <CSRowCellLabel>{label}</CSRowCellLabel>
      <CSRowCellValue>{value || "-"}</CSRowCellValue>
    </CSRowCell>
  );

  const renderCompanyStructureEntityInfo = (entity: BusinessStructureEntityDataType) => {
    const data = convertBusinessStructureInfoItemToCardData(entity);
    const _entityType = `${sharedTranslationPrefix}.entity_type_labels.${data?.entityType}`;
    const _getName = () => {
      if (data?.entityType === BusinessStructureEntityType.BUSINESS)
        return getFormattedCompanyNameWithKeyManagerFullName(
          data.name,
          data.relatedKeyManagers?.name as string,
        );

      return data?.name;
    };

    return (
      <CSRow>
        <CSRowCell className="full-width">
          <CSRowCellLabel>
            <Trans i18nKey={_entityType} />
          </CSRowCellLabel>

          <CSRowInner>
            <CSRowCell className="full-width">
              <CSRowCellValue>{_getName()}</CSRowCellValue>
            </CSRowCell>

            {data?.ownershipPercentage && (
              <CSRowCell className="auto-width">
                <PercentageText>
                  {getFormattedNumber(
                    +Number(data.ownershipPercentage).toFixed(0),
                    NumberType.PERCENTAGE,
                  )}
                </PercentageText>
              </CSRowCell>
            )}
          </CSRowInner>
        </CSRowCell>
      </CSRow>
    );
  };

  const renderSelectOption = (props: OptionProps<Partial<UserResponseDto>, false>) => {
    const { data, innerRef, innerProps } = props;
    const searchValue = props.selectProps.inputValue;

    return (
      <StyledOption
        ref={innerRef}
        {...innerProps}
        data-testid="async-user-search-result-option"
        onClick={(e) => {
          innerProps.onClick?.(e);
        }}
      >
        {renderAsyncUserSearchNameWithEmail(
          getName(data),
          renderAsyncUserSearchHighlightedText(data?.email, searchValue || ""),
        )}
      </StyledOption>
    );
  };

  const customFilterOption = (
    option: FilterOptionOption<Partial<UserResponseDto>>,
    inputValue: string,
  ) => {
    const searchTerm = inputValue.toLowerCase();
    const result =
      option.data?.firstName?.toLowerCase().includes(searchTerm) ||
      option.data?.lastName?.toLowerCase().includes(searchTerm) ||
      option.data?.email?.toLowerCase().includes(searchTerm);

    return !!result;
  };

  return (
    <StyledContentWrapper id={metadata.divWrapperId}>
      {globalLoading && <FullSectionLoader />}
      {renderErrorMessage(metadata.errorMessage, { fullName: selectedTeamMemberFullName })}

      {kybResponseType ? (
        renderKycResponseSection(kybResponseType, kybResponseStatusTranslationPrefix, globalLoading)
      ) : (
        <StyledContent emphasizedContainer>
          <SectionsContainer>
            <ContentSection>
              <CSRow>
                <CSRowCell>
                  <CSTitle>
                    <Trans i18nKey={`${translationPrefix}.business_information_section.title`} />
                  </CSTitle>
                </CSRowCell>

                <StyledEditButtonWithIcon
                  onClick={() => onBusinessInfoEditClick?.()}
                  data-testid="business-info-edit-button"
                >
                  <EditIcon />
                </StyledEditButtonWithIcon>
              </CSRow>

              {businessInfoRows.map((row) => {
                return (
                  <CSRow>
                    {row.map((cell) => {
                      return renderLabelWithValue(cell.label, cell.value, cell.className);
                    })}
                  </CSRow>
                );
              })}
            </ContentSection>

            <ContentSection>
              <CSRow>
                <CSRowCell>
                  <CSTitle>
                    <Trans i18nKey={`${translationPrefix}.company_structure_section.title`} />
                  </CSTitle>
                </CSRowCell>

                <StyledEditButtonWithIcon
                  onClick={() => onBusinessOwnersEditClick?.()}
                  data-testid="business-owners-edit-button"
                >
                  <EditIcon />
                </StyledEditButtonWithIcon>
              </CSRow>

              {businessStructureEntitiesList.map((entity) => {
                return renderCompanyStructureEntityInfo(entity);
              })}
            </ContentSection>
          </SectionsContainer>

          <CheckboxWrapper>
            <Checkbox
              checked={certificationDisclosureValues.CERTIFICATION}
              onCheck={() =>
                handleCertificationDisclosureValuesChange(
                  IBusinessOwnersStructureDisclosuresType.CERTIFICATION,
                )
              }
              onUncheck={() =>
                handleCertificationDisclosureValuesChange(
                  IBusinessOwnersStructureDisclosuresType.CERTIFICATION,
                )
              }
              text={
                <Trans
                  i18nKey={`${translationPrefix}.disclosures_section.certification_statement`}
                />
              }
              data-testid={`${IBusinessOwnersStructureDisclosuresType.CERTIFICATION}_disclosure_checkbox`}
            ></Checkbox>
          </CheckboxWrapper>

          <CheckboxWrapper>
            <Checkbox
              checked={certificationDisclosureValues.TERMS_AND_CONDITIONS}
              onCheck={() =>
                handleCertificationDisclosureValuesChange(
                  IBusinessOwnersStructureDisclosuresType.TERMS_AND_CONDITIONS,
                )
              }
              onUncheck={() =>
                handleCertificationDisclosureValuesChange(
                  IBusinessOwnersStructureDisclosuresType.TERMS_AND_CONDITIONS,
                )
              }
              data-testid={`${IBusinessOwnersStructureDisclosuresType.TERMS_AND_CONDITIONS}_disclosure_checkbox`}
              text={
                <Trans
                  i18nKey={`${translationPrefix}.disclosures_section.terms_conditions`}
                  components={{
                    1: (
                      <CheckboxTextLink
                        href={COMPANY_CONSUMER_ACCOUNT_AGREEMENT}
                        onClick={(e) => e.stopPropagation()}
                        data-testid="terms-and-conditions-link"
                        target="_blank"
                      />
                    ),
                  }}
                />
              }
            ></Checkbox>
          </CheckboxWrapper>

          {kybSubmissionState.showWarning && !kybSubmissionState.showDropdown && (
            <StyledWarningSection>
              <WarningContentInner>
                <WCIText>
                  <Trans
                    i18nKey={`${translationPrefix}.warning_section.message`}
                    components={{ 1: renderCopyButton() }}
                  />
                </WCIText>

                <InviteButton onClick={handleShowDropdown} data-testid="invite-team-member-button">
                  <UsersPlusIcon />
                  <Trans i18nKey={`${translationPrefix}.warning_section.invite_button`} />
                </InviteButton>
              </WarningContentInner>
            </StyledWarningSection>
          )}

          {kybSubmissionState.showDropdown && (
            <InviteTeamMemberSection>
              <ITSSelectContainer>
                <FormLabelSmall>
                  <Trans
                    i18nKey={`${translationPrefix}.select_team_member_section.select_team_member_label`}
                  />
                </FormLabelSmall>

                <Select
                  name={"teamMember"}
                  onChange={(e) =>
                    handleChangeSelectedTeamMember(
                      (e as unknown as Partial<UserResponseDto>) || null,
                    )
                  }
                  options={businessStructureMembers}
                  getOptionValue={(option) => option?.userId || ""}
                  getOptionLabel={(option) =>
                    getName({
                      firstName: option?.firstName || "",
                      lastName: option?.lastName || "",
                    }) || ""
                  }
                  filterOption={customFilterOption}
                  inputMode="search"
                  isSearchable={true}
                  alwaysShowErrorBlock={false}
                  isDisabled={metadata.inviteSent}
                  components={{
                    Option: (props) => renderSelectOption({ ...props } as any),
                  }}
                />
              </ITSSelectContainer>

              <ITSInviteBtn
                disabled={metadata.inviteSent || !selectedTeamMember}
                onClick={() => handleInviteTeamMember()}
                data-testid="invite-team-member-submit"
              >
                <Trans i18nKey={`${translationPrefix}.select_team_member_section.send_invite`} />
              </ITSInviteBtn>
            </InviteTeamMemberSection>
          )}
        </StyledContent>
      )}

      {!isEmpty(kybErrors) && (
        <VerificationErrorsSection
          errors={kybErrors}
          businessStructure={businessOwnersStructure || {}}
        />
      )}

      {renderFooterButtonsSection({
        mainButton: {
          text: <Trans i18nKey={`buttons.${kybResponseType ? "continue" : "submit"}`} />,
          disabled: shouldDisablePrimaryBtn,
          onClick: handleSubmit,
          type: "button",
          dataTestId: "cbacm-review-certify-submit-button",
        },
        secondaryButton: {
          text: <Trans i18nKey="buttons.cancel" />,
          onClick: onBackClick,
          type: "button",
          dataTestId: "cbacm-review-certify-cancel-button",
        },
      })}
    </StyledContentWrapper>
  );
};

export default ReviewCertifyStep;

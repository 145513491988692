import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  [id*="easyteam-employee-timesheet"] {
    & > div > div {
      margin: 0;
    }

    button[aria-label="Back"] {
      display: none;
    }
  }
`;

/* eslint-disable */
import { BonusStatus } from "types/BETypes";

export const colorsNew = {
  grayscale: {
    100: "#1A1A1A",
    200: "#262626",
    300: "#333333",
    400: "#4D4D4D",
    ["500_main"]: "#7A7A7A",
    550: "#8F8F8F",
    600: "#B3B3B3",
    650: "#CCCCCC",
    700: "#E6E6E6",
  },
  neutral: {
    ["500_main"]: "#627293",
    600: "#A3ADC2",
    900: "#F6F7F9",
    950: "#FAFBFC",
  },
  primary: {
    400: "#D74D1F",
    ["500_main"]: "#F96331",
    600: "#FF8860",
    700: "#FFAA8E",
    800: "#FFCDBC",
    900: "#FEEBE5",
    950: "#FFF9F7",
  },
  success: {
    400: "#1DAF4E",
    ["500_main"]: "#24DB62",
    600: "#7CE9A1",
    900: "#E9FBEF",
  },
  warning: {
    400: "#BE8D0E",
    ["500_main"]: "#EDB012",
    600: "#F4D071",
    900: "#FDF7E7",
  },
  error: {
    400: "#B61616",
    ["500_main"]: "#E31C1C",
    600: "#EE7777",
    900: "#FCE8E8",
  },
  shades: {
    black: "#000000",
    white: "#FFFFFF",
  },
  blue: {
    light_blue: "#DFF6FB",
    blue: "#17AACF",
    blue_hover: "#1392B2",
  },
  green: {
    light_green: "#F1F6F5",
    green: "#60AEAC",
  },
  gradient: {
    green: "linear-gradient(179.38deg, #22BBA7 0.11%, #40B380 61.24%)",
    blue: "linear-gradient(359.3deg, #0D99BC 0%, #2399B6 100%)",
  }
}

export const baseColors = {
  //Container Backgrounds
  bg: "#FFFFFF",
  boxBg: "#FAFBFC", //+
  boxBg2: "transparent", //+
  boxBg3: "#CCCCCC", //+
  boxBg4: "#E6E6E6", //+
  boxBg5: "#CED4E0", //+
  boxBg6: "#F5F7FA", //+ //Not in palette, add later
  boxBg7: "#F6F7F9", //+
  boxBg8: "#F0F1F5", //+ //Not in palette, add later

  //Text Colors
  buttonText: "#FFFFFF", //+
  text: "#1A1A1A", //+
  textAlt: "#4D4D4D", //+
  textAlt2: "#262626", //+
  textAlt3: "#333333", //+
  textAlt4: "#627293", //+

  textInactive: "#B3B3B3", //+
  textInactiveAlt: "#8F8F8F", //+
  textInactiveAlt2: "#7A7A7A", //+

  primary: "#F96331", //+
  primaryAlt: "#D74D1F", //+
  primaryAlt2: "#FF8860", //+
  primaryAlt3: "#FFAA8E", //+
  primaryAlt4: "#FFCDBC", //+
  primaryAlt5: "#FEEBE5", //+
  primaryAlt6: "#FFF9F7", //+

  danger: "#E31C1C", //+
  dangerAlt: "#B61616", //+
  dangerAlt2: "#EE7777", //+
  dangerAlt3: "#FCE8E8", //+

  success: "#24DB62", //+
  successAlt: "#1DAF4E", //+
  successAlt2: "#7CE9A1", //+
  successAlt3: "#E9FBEF", //+

  warning: "#EDB012", //+
  warningAlt: "#BE8D0E", //+
  warningAlt2: "#F4D071", //+
  warningAlt3: "#FDF7E7", //+

  disabled: "#B3B3B3", //+

  linkLight: "#DFF6FB", //+
  link: "#17AACF", //+
  linkAlt: "#1392B2", //+

  //Misc
  border: "#CCCCCC", //+
  borderAlt: "#A3ADC2", //+
  //TODO::: Do we need "icon" ? Request avatar with an empty image
  icon: "#2399B6",

  menu1: "#22BBA7",
  menu2: "#40B380",
  menuAlt1: "#0D99BC",
  menuAlt2: "#2399B6",

  //TODO::: Request error layout later
  errorLayout1: "#027473",
  errorLayout2: "#053635",

  transparent: "transparent",
  //darkblue: "#17566B", //Commented, bc I couldn't find it in designs yet
};

//Status Colors

const statusColors = {
  statusDraft: baseColors.disabled,
  statusSuccess: baseColors.successAlt,
  statusDeclined: baseColors.primary,
  statusVestingStopped: baseColors.text,
  statusVoided: baseColors.danger,
  statusPending: baseColors.warning,
  statusVested: baseColors.textAlt,
  statusAccepted: baseColors.menu1,
  statusNotReady: baseColors.danger,
  statusBlank: baseColors.bg,
  statusPendingExit: baseColors.textAlt,

  //User Statuses
  statusEmployed: baseColors.successAlt,
  statusProspect: baseColors.link,
  statusInvited: baseColors.warning,

  //User Payment Readiness Statuses
  userPaymentReadinessStatusReady: baseColors.successAlt,
  userPaymentReadinessStatusSetupPay: baseColors.primary,

  //Plaid verification statuses
  bankStatusVerified: baseColors.success,
  bankStatusFailed: baseColors.danger,
  bankStatusPending: baseColors.warning,

  businessStructureUserStatusActive: baseColors.successAlt,
  businessStructureUserStatusInvited: baseColors.warning,
  businessStructureUserStatusNotInvited: baseColors.textInactiveAlt2,
};

const colors = {
  colorsNew,
  //General
  text: baseColors.text,
  textEmphasized: baseColors.text,
  textEmphasizedAlt: baseColors.link,
  textAlt: baseColors.textAlt,
  textAlt2: baseColors.textAlt2,
  textAlt3: baseColors.textAlt3,
  textAlt4: baseColors.textAlt4,
  textInactiveAlt2: baseColors.textInactiveAlt2,
  disabled: baseColors.disabled,
  title: baseColors.primary,
  titleAlt: baseColors.primaryAlt,
  titleMobile: baseColors.menu1,
  titleDot: baseColors.warning,
  contentBg: baseColors.bg,
  titleText: baseColors.text,
  contentBorder: baseColors.border,
  contentBorderAlt: baseColors.boxBg4,
  contentBorderActive: baseColors.primary,
  contentBoxShadow: `${baseColors.text}40`,
  contentBoxShadowLight: `${baseColors.text}0a`,
  money: baseColors.success,
  sectionContainer: baseColors.bg,
  sectionAltContainer: baseColors.boxBg6,

  sectionContainerAlt: baseColors.boxBg6,
  sectionContainerAlt2: baseColors.boxBg7,
  sectionContainerAlt3: `${baseColors.borderAlt}40`,
  sectionContainerAlt4: `${baseColors.boxBg8}`,
  sectionContainerWarning: `${baseColors.primaryAlt6}`,
  sectionBorderWarning: `${baseColors.primaryAlt4}`,

  //Primary
  primary: baseColors.primary,
  primaryAlt: baseColors.primaryAlt,
  primaryAlt2: baseColors.primaryAlt2,
  primaryAlt3: baseColors.primaryAlt3,
  primaryAlt4: baseColors.primaryAlt4,
  primaryAlt5: baseColors.primaryAlt5,
  primaryAlt6: baseColors.primaryAlt6,
  primaryDisabled: baseColors.boxBg4,
  primaryText: baseColors.buttonText,
  primaryTextAlt: baseColors.buttonText,
  primaryTextDisabled: baseColors.textInactiveAlt2,

  //Danger
  danger: baseColors.danger,
  dangerAlt: baseColors.dangerAlt,
  dangerAlt2: baseColors.dangerAlt2,
  dangerAlt3: baseColors.dangerAlt3,
  dangerDisabled: baseColors.disabled,
  dangerText: baseColors.buttonText,
  dangerTextAlt: baseColors.buttonText,
  dangerTextDisabled: baseColors.buttonText,

  //Success
  success: baseColors.success,
  successAlt: baseColors.successAlt,
  successAlt2: baseColors.successAlt2,
  successAlt3: baseColors.successAlt3,

  //Warning
  warning: baseColors.warning,
  warningAlt: baseColors.warningAlt,
  warningAlt2: baseColors.warningAlt2,
  warningAlt3: baseColors.warningAlt3,

  //Links
  accentMain: baseColors.link,
  accentSecondary: baseColors.primary,
  accentLight: baseColors.linkLight,

  //Buttons
  secondaryButtonBg: baseColors.bg,
  secondaryButtonBgAlt: `${baseColors.primaryAlt4}`,
  secondaryButtonBgDisabled: baseColors.bg,
  secondaryButtonText: baseColors.primary,
  secondaryButtonTextAlt: baseColors.primaryAlt,
  secondaryButtonTextDisabled: baseColors.textInactiveAlt2,

  secondaryButtonAltBg: baseColors.bg,
  secondaryButtonAltBgAlt: `${baseColors.link}35`,
  secondaryButtonAltBgDisabled: baseColors.bg,
  secondaryButtonAltText: baseColors.link,
  secondaryButtonAltTextAlt: baseColors.linkAlt,
  secondaryButtonAltTextDisabled: baseColors.textInactiveAlt2,

  noBorderButtonBg: baseColors.transparent,
  noBorderButtonBgAlt: baseColors.transparent,
  noBorderButtonBgDisabled: baseColors.transparent,
  noBorderButtonText: baseColors.link,
  noBorderButtonTextAlt: baseColors.linkAlt,
  noBorderButtonTextDisabled: baseColors.disabled,

  cancelButtonBg: baseColors.boxBg4,
  cancelButtonBgAlt: baseColors.boxBg4,
  cancelButtonBgDisabled: baseColors.boxBg4,
  cancelButtonText: baseColors.text,
  cancelButtonTextAlt: baseColors.text,
  cancelButtonTextDisabled: baseColors.text,

  //Form
  formTitle: baseColors.primaryAlt,
  formEmphasis: baseColors.textAlt,

  //Input
  inputText: baseColors.text,
  inputBorder: baseColors.borderAlt,
  inputBg: baseColors.bg,
  inputActiveShadow: `${baseColors.text}40`,
  inputDropdownShadow: `${baseColors.text}26`,
  inputDropdownBorder: baseColors.boxBg3,
  inputPlaceholder: baseColors.textInactive,
  inputNumberPlaceholder: baseColors.text,
  inputCurrencyBg: baseColors.boxBg7,
  inputUnitsText: baseColors.text,
  inputDisabledBg: baseColors.boxBg7,
  inputIncrementBg: baseColors.boxBg7,
  inputIncrementText: baseColors.text,
  inputDropdownHover: `${baseColors.boxBg4}50`,

  //Dropdown
  DDArrow: baseColors.text,
  optionBg: baseColors.bg,
  activeOptionBg: baseColors.boxBg,

  //DatePicker
  datePickerHeaderDDBorder: baseColors.border,

  //Switch
  switchHandleBg: baseColors.bg,
  switchBackground: baseColors.primary,
  switchOffBackground: baseColors.boxBg4,
  switchText: baseColors.text,

  //Header
  headerBorder: baseColors.boxBg4,

  //Footer
  footerBg: baseColors.boxBg3,
  footerText: baseColors.buttonText,
  footerTextAlt: baseColors.disabled,
  footerCopyrightText: baseColors.disabled,

  //Modal
  modalTitle: baseColors.text,
  modalMessage: baseColors.textAlt,
  modalSecondaryButtonText: baseColors.link,
  modalSecondaryButtonTextAlt: baseColors.linkAlt,

  //Modal icon backgrounds. Note: Do not use opacity here, only use normal colors
  modalIconBgSuccess: baseColors.success,
  modalIconBgWarning: baseColors.warning,
  modalIconBgConfirm: baseColors.success,
  modalIconBgError: baseColors.danger,
  modalIconBgDelete: baseColors.danger,
  modalIconBgCard: baseColors.primary,
  modalIconBgShare: baseColors.primary,
  modalIconBgRefresh: baseColors.primary,
  modalIconBgSwitch: baseColors.primary,
  modalIconBgPending: baseColors.primary,
  modalIconBgUser: baseColors.primary,
  modalIconFill: baseColors.buttonText,

  //BackButton
  backButtonText: baseColors.text,
  backButtonTextActive: baseColors.icon,

  //Pagination

  paginationButtonBgHover: `${baseColors.boxBg7}`,
  paginationButtonBg: `${baseColors.boxBg8}`,
  paginationButtonBgActive: baseColors.successAlt,
  paginationButtonText: baseColors.textInactiveAlt,
  paginationButtonTextActive: baseColors.buttonText,

  //MainMenu
  mainMenuBg: `linear-gradient(124deg, ${baseColors.menu1} 0%, ${baseColors.menu2} 100%)`,
  mainMenuEmployeeBg: `linear-gradient(360deg, ${baseColors.menuAlt1} 0%, ${baseColors.menuAlt2} 100%)`,
  mainMenuText: baseColors.buttonText,
  mainMenuTextActive: baseColors.menu1,
  mainMenuTextActiveEmployee: baseColors.primary,
  mainMenuButtonBg: baseColors.boxBg2,
  mainMenuButtonBgActive: baseColors.bg,
  mainMenuButtonBgActiveEmployee: baseColors.bg,
  mainMenuCollapseBtnBg: baseColors.boxBg2,
  mainMenuFooterLink: baseColors.buttonText,
  mainMenuFooterText: "#B8DEE5", //TODO::: Add to palette later

  //Error Layout
  errorLayoutBg: `linear-gradient(124deg, ${baseColors.errorLayout1} 0%, ${baseColors.errorLayout2} 100%)`,
  errorLayoutText: baseColors.buttonText,

  // Auh Layout
  authLayoutBg: `linear-gradient(179.38deg, ${baseColors.menu1} 0.11%, ${baseColors.menu2} 61.24%)`,
  employeeAuthLayoutBg: `linear-gradient(359.3deg, ${baseColors.menuAlt1} 0%, ${baseColors.menuAlt2} 100%)`,

  //Avatar
  avatarBg: baseColors.icon,
  avatarText: baseColors.buttonText,
  avatarArrow: baseColors.disabled,
  avatarArrowActive: baseColors.disabled,

  //AccountMenu
  accountMenuCompanyText: baseColors.textAlt,
  accountMenuRoleText: baseColors.text,

  //Widget
  widgetShadow: baseColors.disabled,
  widgetBg: baseColors.bg,
  widgetTitle: baseColors.primaryAlt,
  widgetInnerBorder: baseColors.border,

  //Progress Bar
  progressBarBg: baseColors.boxBg4,
  progressBarLegendLine: baseColors.textInactive,
  progressBarDefaultColor1: baseColors.success,
  progressBarDefaultColor2: baseColors.warning,
  progressBarDefaultColor3: baseColors.link,
  progressBarDefaultColor4: baseColors.warningAlt3,
  progressBarDefaultColor5: baseColors.danger,

  progressBarNewDefaultColor1: baseColors.menu2,
  progressBarNewDefaultColor2: baseColors.boxBg3,

  progressBarDefaultLPadding: baseColors.warning,
  progressBarNewDefaultLPadding: baseColors.menu2,
  progressBarNewBarInactive: baseColors.boxBg3,

  progressBarBarMarkerDefault: baseColors.bg,
  progressBarBarMarkerDefaultInactive: baseColors.textInactive,
  progressBarBarMarkerEmphasized: baseColors.bg,
  progressBarBarMarkerEmphasizedInactive: baseColors.textInactive,
  progressBarBarMarkerLeading: baseColors.warning,
  progressBarBarMarkerLeadingInactive: baseColors.textInactive,
  progressBarBarMarkerLeadingBorder: baseColors.bg,
  progressBarBarMarkerLeadingShadow: `${baseColors.text}40`,
  progressBarBarMarkerBoosterReward: baseColors.warning,
  progressBarBarMarkerBoosterRewardBorder: baseColors.bg,

  progressBarDetailedEdgeMarkerBg: baseColors.boxBg4,
  progressBarDetailedEdgeMarkerStartText: baseColors.success,
  progressBarDetailedEdgeMarkerEndText: baseColors.warning,

  progressBarStatusBg: {
    [BonusStatus.VESTING_STOPPED]: baseColors.text,
  },

  //StatusContainer
  statusContainerDraft: statusColors.statusDraft,
  statusContainerActive: statusColors.statusSuccess,
  statusContainerDeclined: statusColors.statusDeclined,
  statusContainerVoided: statusColors.statusVoided,
  statusContainerPending: statusColors.statusPending,
  statusContainerVested: statusColors.statusVested,
  statusContainerAccepted: statusColors.statusAccepted,
  statusContainerVestingStopped: statusColors.statusVestingStopped,
  statusContainerBlank: statusColors.statusBlank,
  statusContainerPastDue: baseColors.danger,

  statusContainerBorderDraft: statusColors.statusDraft,
  statusContainerBorderActive: statusColors.statusSuccess,
  statusContainerBorderDeclined: statusColors.statusDeclined,
  statusContainerBorderVoided: statusColors.statusVoided,
  statusContainerBorderPending: statusColors.statusPending,
  statusContainerBorderVested: statusColors.statusVested,
  statusContainerBorderAccepted: statusColors.statusAccepted,
  statusContainerBorderBlank: baseColors.border,
  statusContainerBorderPastDue: baseColors.danger,

  statusContainerShadowDraft: baseColors.border,
  statusContainerShadowActive: baseColors.border,
  statusContainerShadowDeclined: baseColors.border,
  statusContainerShadowVoided: baseColors.border,
  statusContainerShadowPending: statusColors.statusPending,
  statusContainerShadowVested: baseColors.border,
  statusContainerShadowAccepted: statusColors.statusAccepted,
  statusContainerShadowVestingStopped: baseColors.border,
  statusContainerShadowBlank: baseColors.border,
  statusContainerShadowPastDue: baseColors.border,

  statusContainerLabelTextDraft: baseColors.buttonText,
  statusContainerLabelTextActive: baseColors.buttonText,
  statusContainerLabelTextDeclined: baseColors.buttonText,
  statusContainerLabelTextVoided: baseColors.buttonText,
  statusContainerLabelTextPending: baseColors.buttonText,
  statusContainerLabelTextVested: baseColors.buttonText,
  statusContainerLabelTextAccepted: baseColors.buttonText,
  statusContainerLabelTextVestingStopped: baseColors.buttonText,
  statusContainerLabelTextBlank: baseColors.textAlt,
  statusContainerLabelTextPastDue: baseColors.buttonText,
  statusContainerLabelTextDefault: baseColors.buttonText,

  statusContainerShadowBlankBorder: baseColors.border,

  //EligibilityStatusContainer
  eligibilityStatusContainerDraft: statusColors.statusDraft,
  eligibilityStatusContainerLive: statusColors.statusSuccess,
  eligibilityStatusContainerInactive: statusColors.statusVestingStopped,
  eligibilityStatusContainerPastDue: baseColors.danger,

  eligibilityStatusContainerLabelTextDraft: baseColors.buttonText,
  eligibilityStatusContainerLabelTextLive: baseColors.buttonText,
  eligibilityStatusContainerLabelTextInactive: baseColors.buttonText,
  eligibilityStatusContainerLabelTextPastDue: baseColors.buttonText,

  //BonusForm
  bonusFormVestEquallyOnEachUnitDescriptionBg: baseColors.boxBg,
  bonusFormVestEquallyOnEachUnitDescriptionColor: baseColors.textAlt,
  bonusFormVestEquallyOnEachUnitDescriptionColorInactive: baseColors.disabled,

  //BonusStatusLabel
  BonusStatusLabelAccepted: baseColors.buttonText,
  BonusStatusLabelPending: statusColors.statusPending,
  BonusStatusLabelActive: statusColors.statusSuccess,
  BonusStatusLabelVested: baseColors.disabled,
  BonusStatusLabelDraft: statusColors.statusDraft,
  BonusStatusLabelVestingStopped: baseColors.textAlt,
  BonusStatusLabelDefault: baseColors.disabled,
  BonusStatusLabelDeclined: statusColors.statusDeclined,
  BonusStatusLabelVoided: statusColors.statusVoided,
  BonusStatusLabelPastDue: baseColors.danger,

  //BonusStatusLabelCountBg
  BonusStatusLabelAcceptedCountBg: baseColors.boxBg,
  BonusStatusLabelPendingCountBg: statusColors.statusPending,
  BonusStatusLabelActiveCountBg: baseColors.primaryAlt,
  BonusStatusLabelVestedCountBg: baseColors.textAlt,
  BonusStatusLabelDraftCountBg: baseColors.disabled,
  BonusStatusLabelDeclinedCountBg: baseColors.textAlt,
  BonusStatusLabelVoidedCountBg: statusColors.statusVoided,
  BonusStatusLabelVestingStoppedCountBg: baseColors.textAlt,

  //Transaction statuses
  transactionStatusSuccess: statusColors.statusSuccess,
  transactionStatusPending: statusColors.statusPending,
  transactionStatusError: statusColors.statusVoided,
  transactionStatusDeposited: statusColors.statusVestingStopped,

  //Bonus Type Select Modal
  bonusTypeSelectModalCardBorder: baseColors.border,
  bonusTypeSelectModalCardActiveBorder: baseColors.border,
  bonusTypeSelectModalTitle: baseColors.textAlt,
  bonusTypeSelectModalText: baseColors.textAlt,
  bonusTypeSelectModalChevronColor: baseColors.primary,
  bonusTypeSelectModalChevronColorActive: baseColors.primaryAlt,
  //Bonus Card Basic
  bcbBorderDraft: statusColors.statusDraft,
  bcbBorderActive: statusColors.statusSuccess,
  bcbBorderDeclined: statusColors.statusDeclined,
  bcbBorderPending: statusColors.statusPending,
  bcbBorderVested: statusColors.statusVested,
  bcbBorderAccepted: statusColors.statusSuccess,
  bcbBorderVoided: statusColors.statusVoided,
  bcbBorderBlank: baseColors.border,

  bcbLabelDraft: statusColors.statusDraft,
  bcbLabelActive: statusColors.statusSuccess,
  bcbLabelDeclined: statusColors.statusDeclined,
  bcbLabelPending: statusColors.statusPending,
  bcbLabelVested: statusColors.statusVested,
  bcbLabelAccepted: statusColors.statusAccepted,
  bcbLabelVoided: statusColors.statusVoided,
  bcbLabelBlank: baseColors.border,

  //AccordionCollapse
  accordionCollapseContainerShadow: baseColors.border,
  accordionCollapseText: baseColors.textAlt,
  accordionCollapseTextDisabled: baseColors.disabled,

  //StepIndicator
  stepIndicatorText: baseColors.text,
  stepIndicatorActiveText: baseColors.text,
  stepIndicatorDefaultBg: baseColors.primaryAlt5,
  stepIndicatorActiveBg: baseColors.primary,
  stepIndicatorSubstepsBg: baseColors.primaryAlt5,
  stepIndicatorSubstepsBorder: baseColors.primaryAlt4,
  stepIndicatorCompletedIconBg: baseColors.primary,

  //EmployeeInfo
  employeeInfoBg: baseColors.boxBg,
  employeeInfoTopBg: baseColors.boxBg4,
  employeeInfoBottomBg: baseColors.boxBg4,
  employeeInfoAvatarBorder: baseColors.bg,

  //BonusCardDetailed
  bonusCardDetailedUnitBonusInfoBg: baseColors.boxBg4,
  bonusCardDetailedUnitBonusInfoBorder: baseColors.boxBg3,
  bonusCardDetailedUnitBonusEmphasizedLabel: baseColors.menu1,
  bonusCardDetailedBottomBg: baseColors.boxBg,
  bonusCardDetailedInnerElementsBorder: baseColors.disabled,
  bonusCardDetailedUnitLastUpdatedDate: baseColors.textAlt,
  bonusCardDetailedUnitDueDateLabel: baseColors.danger,
  bonusCardDetailedChallengesText: baseColors.link,

  //BonusCardDetailedSmall
  bonusCardDetailedSmallBorder: baseColors.border,
  bonusCardDetailedSmallFilledBar: baseColors.successAlt,
  bonusCardDetailedSmallPartBar: baseColors.boxBg3,

  //BonusCardDetailedSmall Challenges
  bonusCardDetailedSmallChallengesTitle: baseColors.link,
  bonusCardDetailedSmallChallengesSum: baseColors.success,
  bonusCardDetailedSmallChallengesSMaxAmount: baseColors.textInactive,
  bonusCardDetailedSmallChallengesUpdatedLabel: baseColors.textAlt,
  bonusCardDetailedSmallChallengesStatusLabel: baseColors.primary,

  //BonusSummary
  bonusSummaryBg: baseColors.boxBg4,
  bonusSummaryColValue: baseColors.textAlt,

  //Separator
  separatorLine: baseColors.disabled,
  separatorText: baseColors.textAlt,

  //Empty List
  // emptyListBg: baseColors.boxBg,
  // emptyListBgMobile: baseColors.boxBg,
  emptyListBg: baseColors.transparent,
  emptyListBgMobile: baseColors.transparent,
  emptyListButtonBg: baseColors.bg,

  //SafeAndSecure
  safeAndSecureBg: baseColors.boxBg,
  safeAndSecureIconBg: baseColors.bg,

  //Checkbox
  checkboxActiveBg: baseColors.primary,
  checkboxActiveBorder: baseColors.primary,
  checkboxInactiveBg: baseColors.bg,
  checkboxInactiveBorder: baseColors.border,

  //Radio Button
  radioButtonBg: baseColors.success,
  radioButtonBgInactive: baseColors.border,
  radioButtonBgAlt: baseColors.bg,
  radioButtonBorderAlt: baseColors.primary,
  radioButtonBgAltInactive: baseColors.borderAlt,

  //Table
  tableLabel: baseColors.textAlt,
  tableLabelAlt: baseColors.textAlt,
  tableFooterBg: baseColors.textAlt4,
  tableFooterBgAlt: baseColors.boxBg3,
  tableBorder: baseColors.border,
  tableBgAlt: baseColors.boxBg,

  tableFooterText: baseColors.buttonText,

  //ServiceAgreement
  serviceAgreementDeclinedStatus: baseColors.danger,

  //BonusCreationRestrictionModal
  bonusCreationRestrictionModalAvatarBorder: baseColors.border,

  //Funding statuses
  fundingStatusPaymentDue: statusColors.statusPending,
  fundingStatusPastDue: baseColors.danger,

  //Spouse Notification
  spouseNotificationBg: baseColors.boxBg,
  spouseNotificationText: baseColors.text,
  spouseNotificationTextEmphasized: baseColors.textAlt,
  spouseNotificationSwitchLabelText: baseColors.textAlt,

  //performanceChallenges
  performanceChallengesNoRecordsBg: baseColors.boxBg,
  performanceChallengesNoRecordsTitleText: baseColors.textAlt,
  performanceChallengesNoRecordsDescriptionText: baseColors.textAlt,
  performanceChallengesNoRecordsCreateButtonBg: baseColors.warning,
  performanceChallengesNoRecordsCreateButtonBgAlt: baseColors.warning,
  performanceChallengesNoRecordsCreateButtonText: baseColors.buttonText,
  performanceChallengesNoRecordsCreateButtonTextShadow: baseColors.warning,

  performanceChallengesCountBg: baseColors.link,
  performanceChallengesCountText: baseColors.buttonText,
  performanceChallengesBg: baseColors.boxBg,
  performanceChallengesLabelBg: baseColors.primaryAlt,
  performanceChallengesLabelText: baseColors.buttonText,
  performanceChallengesDate: baseColors.warning,
  performanceChallengesDateInvalid: baseColors.danger,
  performanceChallengesLabelDetailedText: baseColors.primaryAlt,
  performanceChallengesEditGoalBg: baseColors.buttonText,
  performanceChallengesEditGoalText: baseColors.textAlt,
  performanceChallengesAggregationBg: baseColors.textAlt,
  performanceChallengesAggregationText: baseColors.buttonText,
  performanceChallengesRewardBg: baseColors.buttonText,
  performanceChallengesGoalCancelText: baseColors.disabled,
  performanceChallengesButtonsSeparatorBorder: baseColors.disabled,

  performanceChallengeConditionEmphasized: baseColors.success,
  performanceChallengeConditionListMarker: baseColors.warning,

  performanceChallengesCreatedLabel: baseColors.disabled,
  performanceChallengesCreatedValue: baseColors.text,
  performanceChallengesStatusLabel: baseColors.disabled,

  //Status Label Container Component
  performanceChallengesStatusContainerLabel: baseColors.buttonText,

  //STP
  performanceChallengesStatusContainerActive: statusColors.statusSuccess,
  performanceChallengesStatusContainerAchieved: `linear-gradient(180deg, ${baseColors.menu1} 0%, ${baseColors.menu2} 100%)`,
  performanceChallengesStatusContainerNotAchieved: statusColors.statusDraft,
  performanceChallengesStatusContainerDraft: statusColors.statusDraft,

  //OLD
  performanceChallengesStatusLive: baseColors.primaryAlt,
  performanceChallengesStatusAchieved: baseColors.warning,
  performanceChallengesStatusNotAchieved: baseColors.textAlt,

  performanceChallengesApproversBorder: baseColors.border,
  performanceChallengesApproversName: baseColors.textAlt,
  performanceChallengesApproversText: baseColors.textAlt,
  performanceChallengesApproversTextBg: baseColors.boxBg,

  performanceChallengesApproverPanelBg: baseColors.boxBg,
  performanceChallengesApproverPanelTitle: baseColors.primaryAlt,
  performanceChallengesApproverPanelText: baseColors.textAlt,
  performanceChallengesApproverPanelInactiveStatus: baseColors.textAlt,
  performanceChallengesApproverPanelActiveStatus: baseColors.primaryAlt,

  //ContactInfo
  contactInfoBg: baseColors.boxBg,
  contactInfoName: baseColors.textAlt,
  contactInfoNameBorder: baseColors.disabled,
  contactInfoTitle: baseColors.textAlt,
  contactInfoText: baseColors.textAlt,
  contactInfoFooterBg: baseColors.bg,

  //Employee termination
  employeeTerminationCheckboxSectionBg: baseColors.boxBg,

  // User Status
  userStatusEmployedText: statusColors.statusEmployed,
  userStatusEmployedBg: baseColors.successAlt3,
  userStatusInvitedText: statusColors.statusInvited,
  userStatusInvitedBg: baseColors.warningAlt3,
  userStatusProspectText: statusColors.statusProspect,
  userStatusProspectBg: baseColors.linkLight,
  //OLD
  userStatusNotReadyTextAndBg: statusColors.statusNotReady,
  userStatusPendingExitTextAndBg: statusColors.statusPendingExit,
  userStatusExitedTextAndBg: baseColors.textAlt,
  userStatusInDefaultTextAndBg: statusColors.statusPending,

  userStatusTextColorWithBg: statusColors.statusBlank,

  // User Payment Readiness Status
  userPaymentReadinessStatusReadyLabel: statusColors.userPaymentReadinessStatusReady,
  userPaymentReadinessStatusReadyBg: baseColors.successAlt3,
  userPaymentReadinessStatusSetupPayLabel: statusColors.userPaymentReadinessStatusSetupPay,
  userPaymentReadinessStatusSetupPayBg: "none",
  userPaymentReadinessStatusSetupPayLabelHover: baseColors.primaryAlt,

  //Support contact
  supportContactIconBg: baseColors.primaryAlt,
  supportContactIcon: baseColors.bg,
  supportContactModalDescription: baseColors.disabled,

  //Support Contacts and Agreements In Modal
  modalContactInfoBg: baseColors.boxBg,
  modalAgreementBg: baseColors.boxBg,

  //Tag component
  tagBg: baseColors.boxBg,

  //Balance Due Card
  balanceDueCardBorder: baseColors.danger,
  balanceDueText: baseColors.danger,
  balanceDueLabelColor: baseColors.danger,
  balanceDueSumColor: baseColors.danger,
  balanceDueIconBg: baseColors.danger,

  //Stop Vesting Modals
  stopVestingAcknowledgementBg: baseColors.boxBg,
  //Make a payment page
  amountDueReducedAlertBg: baseColors.boxBg,

  //Balance Due Summary Bar
  balanceDueSummaryBarFooterBg: baseColors.boxBg,

  //Special payment plan
  monthlyPaymentSummaryBg: baseColors.boxBg,
  totalPaymentsSummaryBg: baseColors.boxBg4,
  summaryStepSectionBg: baseColors.boxBg,

  //Performance Details Page
  performanceDetailsPagePastDueBg: `${baseColors.dangerAlt}0a`,
  performanceDetailsPagePastDueColor: `${baseColors.danger}`,
  performanceDetailsPageEmployeeSuccessBg: `${baseColors.boxBg4}`,
  performanceDetailsPageEmployeeFailBg: `${baseColors.warning}15`,
  performanceDetailsProgressBg: baseColors.menu2,
  performanceDetailsProgressBgInactive: baseColors.boxBg3,
  performanceDetailsIfThenLabelBg: `linear-gradient(180deg, ${baseColors.menu1} 0.11%, ${baseColors.menu2} 61.24%)`,
  performanceDetailsIfThenLabelText: baseColors.menu1,
  performanceDetailsSum: baseColors.success,
  performanceDetailsTierSum: baseColors.text,

  //Vesting Schedule
  vestingScheduleEmphasizedLabel: baseColors.success,
  vestingScheduleAmount: baseColors.success,
  vestingScheduleFooterEmphasizedLabel: baseColors.success,
  vestingScheduleFooterBg: baseColors.bg,
  vestingScheduleInactiveRowText: baseColors.textInactive,
  vestingScheduleInactiveRowBg: baseColors.boxBg4,

  //Bonus Template Pages
  bonusTemplateSearchTagBg: baseColors.boxBg4,
  bonusTemplateSearchTagBgAlt: baseColors.bg,
  bonusTemplateSearchTagColor: baseColors.text,
  bonusTemplateSearchTagColorAlt: baseColors.link,

  //Bonus Template Progress Page
  bonusProgressMobileHeaderBg: baseColors.boxBg,
  bonusProgressGoalIconBg: baseColors.transparent,
  bonusProgressGoalIconBorder: baseColors.disabled,
  bonusProgressGoalIconFilledBg: baseColors.success,
  bonusProgressGoalIconFilledBorder: baseColors.success,
  bonusProgressGoalIconDisabledBg: baseColors.transparent,
  bonusProgressGoalIconDisabledBorder: baseColors.disabled,
  bonusProgressGoalIconDisabledFilledBg: baseColors.disabled,
  bonusProgressGoalIconDisabledFilledBorder: baseColors.disabled,

  //Navigation Cards
  navigationCardActiveLabelText: baseColors.buttonText,
  navigationCardActiveLabelBg: baseColors.primary,
  navigationCardAltLabelText: baseColors.primary,
  navigationCardAltLabelBg: `${baseColors.primary}16`,
  navigationCardBg: baseColors.bg,
  navigationCardBorder: baseColors.primary,
  navigationCardLargeBorder: baseColors.primary,

  //Payment methods widget
  paymentMethodsCountText: baseColors.textInactive,
  paymentMethodsLargeContentBg: `#FACEBE50`, //TODO::: Add to palette later,
  paymentMethodsLargeContentTitle: baseColors.text, //TODO::: Add to palette later,
  paymentMethodsLargeContentTitleAlt: baseColors.textInactiveAlt, //TODO::: Add to palette later,
  paymentMethodsLargeFooterBg: baseColors.primaryAlt6, //TODO::: Add to palette later,
  paymentMethodsLargeFooterTextAlt: baseColors.textAlt, //TODO::: Add to palette later,
  paymentMethodsSubcardBg: `${baseColors.bg}7a`,
  paymentMethodsSubcardText: "#662A14", //TODO::: Add to palette later
  paymentMethodsBenefitFalseIconBg: baseColors.text,
  paymentMethodsBenefitFalseIconLine: baseColors.bg,
  paymentMethodsBenefitTrueIconBg: baseColors.primary,
  paymentMethodsBenefitTrueIconLine: baseColors.bg,

  //Info Block
  paymentMethodsSubcardBelowContentInfoBg: baseColors.boxBg4, //TODO::: Add to palette later
  paymentMethodsSubcardBelowContentinfoBlockBg: baseColors.bg,
  paymentMethodsSubcardBelowContentinfoBlockNumberText: baseColors.buttonText,
  paymentMethodsSubcardBelowContentinfoText: "#EDB012", //TODO::: Add to palette later

  //warning Block
  paymentMethodsSubcardBelowContentWarningBg: baseColors.warningAlt3,
  paymentMethodsSubcardBelowContentWarningText: "#EDB012", //TODO::: Add to palette later

  //Error Block
  paymentMethodsSubcardBelowContentErrorBg: "#FFEDED", //TODO::: Add to palette later
  paymentMethodsSubcardBelowContentErrorText: baseColors.danger,
  paymentMethodsSubcardBelowContentErrorTextAlt: baseColors.primary,
  paymentMethodsSubcardBelowContentErrorTextAltHover: `${baseColors.primary}85`,

  //SelectedBank
  selectedBankWidgetBg: baseColors.boxBg4,
  selectedBankWidgetIcon: baseColors.success,

  //Banking
  bankingUtilityButtonBg: `${baseColors.boxBg4}12`,

  //Admin Setup Welcome Component
  adminSetupWelcomeContainerBg: baseColors.boxBg,
  adminSetupWelcomeMessageText: baseColors.textAlt,
  adminSetupWelcomeListItemTitleText: baseColors.text,
  adminSetupWelcomeListItemMessageText: baseColors.textAlt2,
  adminSetupWelcomeListItemIndexBorder: baseColors.warning,
  adminSetupWelcomeListItemIndexText: baseColors.warning,

  //Plaid bank account
  plaidVerificationStatusVerified: statusColors.bankStatusVerified,
  plaidVerificationStatusFailed: statusColors.bankStatusFailed,
  plaidVerificationStatusPending: statusColors.bankStatusPending,

  //Pay Distribution
  payDistributionCardBgHover: baseColors.primaryAlt5,
  payDistributionCardIcon: baseColors.link,
  payDistributionCardIconActive: baseColors.primary,

  //Bank Account Cards
  bankAccountIconedValueCotainerBg: baseColors.boxBg8,
  bankAccountIconedValueCotainerText: baseColors.textAlt4,
  bankAccountEarlyPayEnabledBg: baseColors.successAlt3,
  bankAccountEarlyPayEnabledText: baseColors.successAlt,
  bankAccountEarlyPayDisabledBg: baseColors.warningAlt3,
  bankAccountEarlyPayDisabledText: baseColors.warningAlt,

  //MFA
  mfaSmsSetupBg: baseColors.boxBg7,

  //Breadcrumbs
  breadcrumbsText: baseColors.text,
  breadcrumbsTextDisabled: baseColors.textInactiveAlt,
  breadcrumbsChevron: baseColors.text,

  //VerticalStepsList
  verticalStepsListItem: baseColors.menu1,
  verticalStepsListItemAlt: baseColors.menuAlt1,

  //KYB warning info (e.g on banking page)
  kybWarningInfoTitleIconBg: baseColors.boxBg7,
  kybWarningInfoCopyText: baseColors.textAlt4,

  //BusinessStructureEntityCard
  businessStructureEntityCardPercentageText: `linear-gradient(124deg, ${baseColors.menu1} 0%, ${baseColors.menu2} 100%)`,
  businessStructureEntityCardBgHover: baseColors.textAlt2,

  //AsyncUserSearchInput
  asyncUserSearchInputUserRoleEmployeeText: `linear-gradient(124deg, ${baseColors.menu1} 0%, ${baseColors.menu2} 100%)`,
  asyncUserSearchInputUserRoleAdminText: `linear-gradient(124deg, ${baseColors.menuAlt1} 0%, ${baseColors.menuAlt2} 100%)`,

  //BusinessStructureUserStatus
  businessStructureUserStatusActive: statusColors.businessStructureUserStatusActive,
  businessStructureUserStatusInvited: statusColors.businessStructureUserStatusInvited,
  businessStructureUserStatusNotInvited: statusColors.businessStructureUserStatusNotInvited,

  //RewardsPage
  rewardsPendingPoints: baseColors.textInactive,
  rewardsInfoSeparator: baseColors.boxBg4,
  rewardsInfoRewardPointBorder: baseColors.buttonText,
  rewardsInfoRewardPointBg: baseColors.primary,

  //Reward Points Component (In global header)
  rewardPointsDisplayBorder: baseColors.primaryAlt4,
  rewardPointsDisplayBg: baseColors.primaryAlt6,
  rewardPointsDisplayText: baseColors.primary,
};

export default colors;

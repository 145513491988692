import { Trans } from "react-i18next";

import { PayDistributionRuleType, PayDistributionType } from "types/BETypes";

import { FormType } from "./components/BankAccountsForm/validationSchema";

export const companyDefaultBankAccountBenefitsNumber = 3;
export const getCompanyDefaultBankAccountBenefitsList = (translationPrefix: string) => {
  const icons = [`\u{1F911}`, `\u{1F4B5}`, `\u{1F389}`];
  const list = Array.from({ length: companyDefaultBankAccountBenefitsNumber }, (value, index) => {
    return {
      index: index + 1,
      icon: icons[index],
      text: <Trans i18nKey={`${translationPrefix}.${index}`} />,
    };
  });

  return list;
};

export const getCompanyDefaultBankAccountTemplate = (partnerAccountId?: string): FormType => {
  return {
    isEarlyPayEnabled: true,
    payDistributionRules: [
      {
        id: partnerAccountId || PayDistributionType.PARTNER_ACCOUNT,
        type: PayDistributionType.PARTNER_ACCOUNT,
        ruleType: PayDistributionRuleType.REMAINDER,
      },
    ],
  };
};

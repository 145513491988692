import { FC, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Form, Formik, FormikProps } from "formik";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { CardForm, CardReissueReason } from "types/CardShipping";
import {
  ReissuePhysicalCardReasonOptions,
  ReissueVirtualCardReasonOptions,
} from "constants/bankCard";
import { hasAdminRights } from "permissions/helpers/shared";
import { getFieldError } from "helpers";
import { CompanyCardShippingDetailsFormType } from "components/CompanyBankAccountComponents/CompanyCardShippingDetails";
import { ICardholderShippingDetailsButtonComponent } from "components/CompanyBankAccountComponents/CompanyCardShippingDetails/types";

import { FormLabel, Select } from "uikit";
import { EModalTypes } from "uikit/Modal";
import { MainActionButton, SecondaryActionButton } from "uikit/Modal/styles";

import { ExtendedUserProfileResponseDto } from "utils/swagger_react_query";

import { ReissueCardModalStep } from "../../constants";
import {
  ReissueCardReasonStepFormType,
  ReissueCardReasonStepFormValidationSchema,
} from "./validationSchema";
import {
  ContentWrapper,
  ReasonSection,
  StyledButtonsContainer,
  StyledPaidCardShippingDetails,
  StyledUIModal,
} from "./styles";

interface IProps {
  originTranslationPrefix: string;
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (
    reason: CardReissueReason,
    shippingDetailsFormProps?: FormikProps<CompanyCardShippingDetailsFormType>,
  ) => void;
  skipShippingDetailsStep?: boolean;
  cardForm: CardForm;
  adminCardholderReissueData?: Partial<ExtendedUserProfileResponseDto>;
}

interface IFormData {
  reason: ReissueCardReasonStepFormType["reason"] | undefined;
  shippingDetails: CompanyCardShippingDetailsFormType | undefined;
}

export const ReissuedCardModal: FC<IProps> = ({
  originTranslationPrefix,
  isOpen,
  onClose,
  handleSubmit,
  skipShippingDetailsStep,
  cardForm,
  adminCardholderReissueData,
}) => {
  const { t } = useTranslation();
  const [svoc, setSvoc] = useState<boolean>(false);
  const [step, setStep] = useState<ReissueCardModalStep>(ReissueCardModalStep.REASON);
  const translationPrefix = `${originTranslationPrefix}.reissue_card_modal.${step}`;
  const currentUser = useAppSelector(userMetadataSelector);

  const [formData, setFormData] = useState<IFormData>({
    reason: undefined,
    shippingDetails: undefined,
  });

  const reasonFormikFormRef = useRef<FormikProps<ReissueCardReasonStepFormType> | null>(null);

  const renderShippingDetailsForm = () => {
    return (
      <StyledPaidCardShippingDetails
        defaultSelectedCardType={[cardForm]}
        onSubmit={onSubmitShippingDetailsForm}
        buttonsComponent={renderFormButtons}
        hideHeader={true}
        reissueFlow={true}
        adminCardholderReissueData={adminCardholderReissueData}
      />
    );
  };

  const renderReasonStep = (formikProps: FormikProps<ReissueCardReasonStepFormType>) => {
    const { values, setFieldValue } = formikProps;

    const optionsByCardForm =
      cardForm === CardForm.VIRTUAL
        ? ReissueVirtualCardReasonOptions
        : ReissuePhysicalCardReasonOptions;
    const translatedOption = optionsByCardForm.map((option) => ({
      ...option,
      label: t(option.label) || "",
    }));

    return (
      <ReasonSection>
        <FormLabel>
          <Trans i18nKey={`${translationPrefix}.reason_field.label`} />
        </FormLabel>
        <Select
          name="reason"
          fieldDataTestId="reissue-card-modal-reason-select"
          options={translatedOption}
          value={values.reason}
          onChange={(option) => setFieldValue("reason", option)}
          error={getFieldError("reason.value", formikProps, {
            field: t(`${translationPrefix}.reason_field.label`),
          })}
          placeholder={t(`${translationPrefix}.reason_field.placeholder`)}
          alwaysShowErrorBlock={false}
          errorDataTestId="error-reissue-card-reason"
        />
      </ReasonSection>
    );
  };

  const onSubmitShippingDetailsForm = (
    formikProps: FormikProps<CompanyCardShippingDetailsFormType>,
  ) => {
    setFormData((prev) => ({ ...prev, shippingDetails: formikProps.values }));
    handleSubmit(formData.reason?.value as CardReissueReason, formikProps);
  };

  const onReasonFormSubmit = (values: ReissueCardReasonStepFormType) => {
    if (!skipShippingDetailsStep) {
      setFormData((orev) => ({ ...orev, reason: values.reason }));
      setSvoc(false);
      setStep(ReissueCardModalStep.SHIPPING_DETAILS);
    } else {
      handleSubmit(values.reason.value as CardReissueReason);
    }
  };

  const getReasonFormInitialValues = () => {
    const virtualCardInitValue = {
      ...ReissueVirtualCardReasonOptions[0],
      label: t(ReissueVirtualCardReasonOptions[0].label),
    };

    return {
      reason:
        formData.reason ||
        (cardForm === CardForm.VIRTUAL && virtualCardInitValue) ||
        (undefined as any),
    };
  };

  const renderFormButtons = ({
    isCardholderAgreementAccepted,
  }: ICardholderShippingDetailsButtonComponent) => {
    const shouldDisableMainButton = hasAdminRights(currentUser)
      ? false
      : step === ReissueCardModalStep.SHIPPING_DETAILS && !isCardholderAgreementAccepted;
    return (
      <StyledButtonsContainer>
        <SecondaryActionButton
          onClick={() => {
            step === ReissueCardModalStep.REASON ? onClose() : setStep(ReissueCardModalStep.REASON);
          }}
          type={"button"}
          data-testid={"secondary-action-btn"}
        >
          <Trans
            i18nKey={`buttons.${step === ReissueCardModalStep.REASON ? "no_thanks" : "back"}`}
          />
        </SecondaryActionButton>

        <MainActionButton
          type={"submit"}
          disabled={shouldDisableMainButton}
          data-testid={"main-action-btn"}
        >
          <Trans
            i18nKey={`buttons.${
              !skipShippingDetailsStep && step === ReissueCardModalStep.REASON ? "next" : "submit"
            }`}
          />
        </MainActionButton>
      </StyledButtonsContainer>
    );
  };

  return (
    <StyledUIModal
      isOpen={isOpen}
      onClose={onClose}
      type={EModalTypes.REFRESH}
      title={<Trans i18nKey={`${translationPrefix}.title`} />}
      message={<Trans i18nKey={`${translationPrefix}.message`} />}
      onCloseOnButtons={false}
    >
      <ContentWrapper>
        {step === ReissueCardModalStep.REASON && (
          <Formik<ReissueCardReasonStepFormType>
            innerRef={reasonFormikFormRef}
            validateOnChange={svoc}
            validationSchema={ReissueCardReasonStepFormValidationSchema}
            onSubmit={(values) => onReasonFormSubmit(values)}
            initialValues={getReasonFormInitialValues()}
          >
            {(formikProps) => {
              return (
                <Form>
                  {renderReasonStep(formikProps)}
                  {renderFormButtons({})}
                </Form>
              );
            }}
          </Formik>
        )}

        {step === ReissueCardModalStep.SHIPPING_DETAILS &&
          !skipShippingDetailsStep &&
          renderShippingDetailsForm()}
      </ContentWrapper>
    </StyledUIModal>
  );
};

import { ReactNode, useEffect, useState } from "react";
import { EasyTeamProvider } from "@easyteam/web-ui";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";
import { easyTeamCustomStyles } from "styles/libs/easyTeam";

import { ErrorConst } from "types/BETypes";
import { getName, showErrorModal } from "helpers";

import {
  EasyTeamUserDto,
  mutationAuthControllerGetEasyTeamTokens,
  queryEmployeesControllerListEasyTeamUsers,
} from "utils/swagger_react_query";

export interface IUseConnectEasyTeamParams {
  fetchOnMount?: boolean;
}

export const useConnectEasyTeam = ({ fetchOnMount = true }: IUseConnectEasyTeamParams) => {
  const currentUser = useAppSelector(userMetadataSelector);
  const [token, setToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [employeesList, setEmployeesList] = useState<EasyTeamUserDto[]>([]);
  const isEmbeddedContentDataFetched = !!token && employeesList.length > 0;

  useEffect(() => {
    if (fetchOnMount) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { easyTeamToken } = await mutationAuthControllerGetEasyTeamTokens()();
      if (!easyTeamToken) throw new Error(ErrorConst.INTERNAL_ERROR);
      const employeesListRes = await queryEmployeesControllerListEasyTeamUsers();

      setToken(easyTeamToken);
      setEmployeesList(employeesListRes || []);
    } catch (error) {
      showErrorModal(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getContentProvider = ({ children }: { children: ReactNode }) => {
    if (!isEmbeddedContentDataFetched || isLoading) return;

    return (
      <EasyTeamProvider
        token={token}
        customFont={easyTeamCustomStyles.fonts}
        theme={easyTeamCustomStyles.theme}
        employees={employeesList.map((employee) => ({
          id: employee?.userId || "",
          name: getName(employee),
          payrollId: employee?.salsaWorkerId || "",
        }))}
        basePath={process.env.REACT_APP_EASY_TEAM_BASE_URL}
      >
        {children}
      </EasyTeamProvider>
    );
  };

  return {
    token,
    isLoading,
    isEmbeddedContentDataFetched,
    getContentProvider,
  };
};

import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import routes from "routes/routes";

import { showErrorModal } from "helpers";
import { PasswordFormSchemasType } from "components/auth";

import {
  mutationAuthControllerResetPassword,
  mutationAuthControllerSendResetPasswordEmail,
} from "utils/swagger_react_query";

import { SendResetPasswordEmailFormSchemaType } from "./components";
import {
  IResetPasswordPageParams,
  ResetPasswordPageLocationState,
  ResetPasswordStep,
} from "./types";

export const useResetPasswordPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as ResetPasswordPageLocationState;
  const params = useParams<IResetPasswordPageParams>();
  const [token, setToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<ResetPasswordStep>(
    ResetPasswordStep.RESET_PASSWORD,
  );
  const [svoc, setSvoc] = useState<boolean>(false);
  const initFormValues: SendResetPasswordEmailFormSchemaType = {
    email: locationState?.email || "",
  };
  const isRedirectedFromSettings = !!locationState?.redirectedFromSettings;
  useEffect(() => {
    determineStep(params?.token);

    return () => {
      setToken(null);
    };
  }, []);

  const determineStep = (_token: string | undefined) => {
    if (_token) {
      setCurrentStep(ResetPasswordStep.RESET_PASSWORD);
      setToken(_token);
      cleanUpUrl();
    } else {
      setCurrentStep(ResetPasswordStep.SEND_EMAIL);
    }

    setIsLoading(false);
  };

  const cleanUpUrl = () => {
    navigate(routes.RESET_PASSWORD, { replace: true });
  };

  const handleSendResetPasswordEmail = async (
    formikProps: FormikProps<SendResetPasswordEmailFormSchemaType>,
    onEmailSent: (value: boolean) => void,
  ) => {
    const { values, validateForm } = formikProps;
    const errors = await validateForm(values);
    if (!isEmpty(errors)) return;

    try {
      setIsLoading(true);
      await mutationAuthControllerSendResetPasswordEmail()({ email: values.email });
      onEmailSent(true);
    } catch (error) {
      showErrorModal(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (
    formikProps: FormikProps<PasswordFormSchemasType["RESET_PASSWORD"]>,
  ) => {
    setSvoc(true);
    const { values, validateForm } = formikProps;
    const errors = await validateForm(values);
    if (!isEmpty(errors)) return;
    try {
      setIsLoading(true);
      if (!token) throw new Error();
      await mutationAuthControllerResetPassword()({
        token: token,
        newPassword: values.newPassword,
      });
      navigate(routes.SIGN_IN, { replace: true });
    } catch (error) {
      showErrorModal(error);
    } finally {
      setIsLoading(false);
      setSvoc(false);
    }
  };

  return {
    metadata: {
      isLoading,
      currentStep,
      initFormValues,
      isRedirectedFromSettings,
      svoc,
    },
    actions: {
      handleSendResetPasswordEmail,
      handleResetPassword,
    },
  };
};

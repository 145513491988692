import { useState } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { AppEvents } from "services/events";
import { ErrorConst } from "types/BETypes";
import { hasAdminRights } from "permissions/helpers/shared";
import { showErrorModal } from "helpers";
import { PasswordFormSchemasType } from "components/auth";

import { EModalTypes } from "uikit/Modal";

import { mutationUsersControllerCreateOrUpdatePassword } from "utils/swagger_react_query";

export const usePasswordManagement = () => {
  const translationPrefix = `settings_pages.shared.security_settings_page`;
  const authFormTranslationPrefix = `auth_pages.shared.components.password_form`;
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const navigate = useNavigate();
  const isAdmin = hasAdminRights(currentUser);
  const [svoc, setSvoc] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initFormValues: PasswordFormSchemasType["CHANGE_PASSWORD"] = {
    password: "",
    newPassword: "",
    confirmPassword: "",
  };

  const handleSubmit = async (
    formikProps: FormikProps<PasswordFormSchemasType["CHANGE_PASSWORD"]>,
  ) => {
    setSvoc(true);
    const { validateForm, values, resetForm } = formikProps;
    const errors = await validateForm(values);
    if (!isEmpty(errors)) return;

    try {
      setIsLoading(true);
      await mutationUsersControllerCreateOrUpdatePassword()({
        currentPassword: values.password,
        newPassword: values.newPassword,
      });
      resetForm();
      AppEvents.emit("SetGlobalModal", {
        isOpen: true,
        type: EModalTypes.SUCCESS,
        title: (
          <Trans
            i18nKey={`${authFormTranslationPrefix}.modals.password_changed_successfully.title`}
          />
        ),
        message: (
          <Trans
            i18nKey={`${authFormTranslationPrefix}.modals.password_changed_successfully.message`}
          />
        ),
        mainButton: {
          text: <Trans i18nKey={`buttons.done`} />,
        },
      });
    } catch (error: any) {
      let _error: string = error?.data?.error;
      if (error?.data?.error === ErrorConst.VALIDATION_ERROR) {
        _error = ErrorConst.INVALID_PASSWORD;
      }
      showErrorModal(_error);
    } finally {
      setIsLoading(false);
      setSvoc(false);
    }
  };

  const onBackButtonClick = () => {
    const route = isAdmin ? routes.ADMIN_SETTINGS_SECURITY : routes.EMPLOYEE_SETTINGS_SECURITY;
    navigate(route);
  };

  return {
    metadata: {
      currentUser,
      currentCompany,
      translationPrefix,
      svoc,
      isLoading,
    },
    pageData: {
      initFormValues,
    },
    actions: {
      handleSubmit,
      onBackButtonClick,
    },
  };
};

export default usePasswordManagement;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { pageOpenedAnalytics } from "services/analytics";

const PageViewTracker = () => {
  const location = useLocation();
  const currentUser = useAppSelector(userMetadataSelector);

  useEffect(() => {
    pageOpenedAnalytics(currentUser);
  }, [location.pathname]);

  return null;
};

export default PageViewTracker;

export const timesheetsPages = {
  "en-US": {
    admin: {
      timesheets_page: {
        empty_list_section: {
          message: "No timesheets found",
        },
      },
    },
    employee: {},

    shared: {},
  },
};

export default timesheetsPages;

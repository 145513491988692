export const AUTH_SEARCH_PARAMS_CODE = "code";
export const AUTH_SEARCH_PARAMS_ERROR = "error";
export const AUTH_SEARCH_PARAMS_EMAIL = "email";

export const TOKEN_DATA_KEY = "tokenData";
export const TOKEN_DATA_USER_FIELDS = ["companyId"];

export const LOCALHOST_AUTH_TOKEN_KEY = "token";
export const CURRENT_ROLE_KEY = "currentRole";
export const SESSION_ID_KEY = "sessionId";

export const PASSWORD_STRENGTH_MIN_SCORE = 3;
